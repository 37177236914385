<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyIndustryDashboardData } from "./economy.api.service"

export default {
  name: 'EconomyIndustryDashboard',
  components: {
    CardTitle
  },
  setup() {
    const dataList = ref([ {
      label: 'test',
      value: 1
    } ])
    const dataObj = reactive([])

    const echartsData = ref([])
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const getEchartsOption = () => {
      return {
        series: {
          type: 'sankey',
          layout: 'none',
          emphasis: {
            focus: 'adjacency'
          },
          data: [
            {
              name: 'a'
            },
            {
              name: 'b'
            },
            {
              name: 'a1'
            },
            {
              name: 'a2'
            },
            {
              name: 'b1'
            },
            {
              name: 'c'
            }
          ],
          links: [
            {
              source: 'a',
              target: 'a1',
              value: 5
            },
            {
              source: 'a',
              target: 'a2',
              value: 3
            },
            {
              source: 'b',
              target: 'b1',
              value: 8
            },
            {
              source: 'a',
              target: 'b1',
              value: 3
            },
            {
              source: 'b1',
              target: 'a1',
              value: 1
            },
            {
              source: 'b1',
              target: 'c',
              value: 2
            }
          ]
        }
      };
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyIndustryDashboardData({
        type: value
      }))
      if (err) return
      console.log('产业链概览 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      dataList,
      dataObj,

      init
    }
  }
}
</script>

<template>
  <div class="economy-industry-dashboard">
    <CardTitle title="产业链概览" :select-list="dataList" @select-change="init"/>
    <div class="content" ref="echartsRef">
<!--      <ul class="left-nav">
        <li class="nav-item">非金属矿采选业<span class="unit"></span>家</li>
        <li class="nav-item">农副食品加工业<span class="unit"></span>家</li>
        <li class="nav-item">化工原料和化学制品制造业<span class="unit"></span>家</li>
        <li class="nav-item">非金属矿物制品业<span class="unit"></span>家</li>
        <li class="nav-item">黑色金属冶炼和压延加工业<span class="unit"></span>家</li>
      </ul>
      <ul class="center-nav">
        <li class="nav-item">土砂石矿采<span class="unit"></span>家</li>
        <li class="nav-item">农副食品加工业<span class="unit"></span>家</li>
        <li class="nav-item">石膏、水泥制品及类似制品制造<span class="unit"></span>家</li>
        <li class="nav-item">铁合金冶炼<span class="unit"></span>家</li>
        <li class="nav-item">常用有色金属冶炼<span class="unit"></span>家</li>
        <li class="nav-item">电力生产<span class="unit"></span>家</li>
      </ul>
      <ul class="right-nav">
        <li class="nav-item">黏土及其他土砂石开采<span class="unit"></span>家</li>
        <li class="nav-item">其他未列明非金属矿采业<span class="unit"></span>家</li>
        <li class="nav-item">水泥制造<span class="unit"></span>家</li>
        <li class="nav-item">水泥制品制造<span class="unit"></span>家</li>
      </ul>-->
    </div>
  </div>
</template>

<style scoped lang="less">
.economy-industry-dashboard {
  height: 244px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    position: relative;
    padding: 10px 8px;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .left-nav {
      width: 108px;
    }

    .chat {
      flex: 1;
      background: deepskyblue;
    }

    .right-nav {
      width: 108px;
    }
  }
}
</style>