<script>
import EconomyDashboard from "./EconomyDashboard.vue"
import EconomyIndustryDashboard from "./EconomyIndustryDashboard.vue"
import EconomyEnterpriseDashboard from "./EconomyEnterpriseDashboard.vue"
import EconomyKeyPointsEnterpriseDistributed from "./EconomyKeyPointsEnterpriseDistributed.vue"

export default {
  name: 'EconomyLayoutLeft',
  components: {
    // 宏观概览
    EconomyDashboard,

    // 产业链概览
    EconomyIndustryDashboard,

    // 企业概览
    EconomyEnterpriseDashboard,

    // 重点企业乡镇分布
    EconomyKeyPointsEnterpriseDistributed
  }
}
</script>

<template>
  <div class="economy-layout-left">
    <!--  宏观概览-->
    <EconomyDashboard/>

    <!--  产业链概览-->
    <EconomyIndustryDashboard/>

    <!-- 企业概览-->
    <EconomyEnterpriseDashboard/>

    <!--  重点企业乡镇分布-->
    <EconomyKeyPointsEnterpriseDistributed/>
  </div>
</template>

<style scoped lang="less">
.economy-layout-left {
  width: 488px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 1;
}
</style>