<script>
import LayoutRightWrap from "./EconomyLayoutRight.vue"
import LayoutLeftWrap from "./EconomyLayoutLeft.vue"

export default {
  name: 'EconomyLayout',
  components: {
    LayoutLeftWrap,
    LayoutRightWrap,
  }
}

</script>

<template>
  <div class="economy-main">
    <LayoutLeftWrap/>
    <LayoutRightWrap/>
  </div>
</template>

<style scoped lang="less">
.economy-main {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px 24px;

  .layout-left-wrap,
  .layout-right-wrap {
    width: 488px;
    height: 100%;
  }

  .layout-center-wrap {
    flex: 1;
    height: 100%;
  }
}
</style>