<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyKeyPointsEnterpriseDistributedData } from "./economy.api.service"

export default {
  name: 'EconomyKeyPointsEnterpriseDistributed',
  components: {
    CardTitle
  },
  setup() {
    const dataList = ref([ {
      label: 'test',
      value: 1
    }])

    const echartsData = ref([
      {
        "name": "五渡镇",
        "value": "2007.00"
      },
      {
        "name": "黑竹沟",
        "value": "5385.00"
      },
      {
        "name": "宜坪乡",
        "value": "2717.00"
      },
      {
        "name": "大堡镇",
        "value": "3421.00"
      },
      {
        "name": "毛坪镇",
        "value": "173.00"
      },
      {
        "name": "沙坪镇",
        "value": "321.00"
      }
    ])
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const getEchartsOption = (data, colors) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 0,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '1%',   // 左侧距离
          right: '1%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#B4C0CC',
            fontSize: 14,
            interval: 0
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [
          {
            name: '就业人数',
            data: data.map(item => item.value),
            type: 'bar', // barMaxWidth: 'auto',
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: colors[0]
                }, {
                  offset: 1,
                  color: colors[1]
                } ])
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value), [ '#B47F02', '#E19600AC' ]))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyKeyPointsEnterpriseDistributedData({
        type: value
      }))
      if (err) return
      console.log('重点企业经济发展 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      dataList,

      init
    }
  }
}
</script>

<template>
  <div class="economy-key-points-enterprise-develop">
    <CardTitle title="重点企业乡镇分布" :select-list="dataList" @select-change="init"/>
    <div class="content" ref="echartsRef"></div>
  </div>
</template>

<style scoped lang="less">
.economy-key-points-enterprise-develop {
  height: 170px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);
  }
}
</style>