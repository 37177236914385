<script>
import { defineExpose, markRaw, nextTick, ref, toRaw } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'ForestTypeAreaStatisticsAccumulationDialog',

  setup() {
    const dialogVisible = ref(false)

    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const echartsData = ref([])

    const getEchartsData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 0,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 12
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '5%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        series: [
          {
            name: '面积',
            data: data.map(item => item.value),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#349EFF'
                }, {
                  offset: 1,
                  color: '#1890FF99'
                } ])
              }
            },
            label: {
              show: false
            }
          },
          {
            name: '蓄积',
            data: data.map(item => item.value1),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#F5E74F'
                }, {
                  offset: 1,
                  color: '#FCE9AE'
                } ])
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    }

    const initEcharts = async () => {
      echartsData.value = [
        {
          name: '峨边镇',
          value: '50',
          value1: '20'
        },
        {
          name: '大堡镇',
          value: '70',
          value1: '90'
        },
        {
          name: '黑竹沟镇',
          value: '100',
          value1: '20'
        },
        {
          name: '红旗镇',
          value: '66',
          value1: '99'
        },
        {
          name: '金岩乡',
          value: '50',
          value1: '20'
        },
        {
          name: '勒乌乡',
          value: '70',
          value1: '90'
        },
        {
          name: '毛坪镇',
          value: '100',
          value1: '20'
        },
        {
          name: '平等乡',
          value: '66',
          value1: '99'
        },
        {
          name: '沙坪镇',
          value: '70',
          value1: '90'
        },
        {
          name: '612林场',
          value: '100',
          value1: '20'
        },
        {
          name: '613林场',
          value: '66',
          value1: '99'
        }
      ]

      await nextTick()

      // 各类碳储量分布
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }
      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsData(toRaw(echartsData.value)))


      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }
    /**
     * 打开弹窗
     */
    const open = () => {
      dialogVisible.value = true
      setTimeout(initEcharts, 550)
    }

    defineExpose({
      open
    })

    return {
      GlobalData,

      dialogVisible,
      echartsRef,

      open
    }
  }
}
</script>

<template>
  <div class="dialog__center forest-type-area-statistics-accumulation-dialog" :class="{open: dialogVisible}">

    <div class="header">
      <i class="dot"></i>
      <span class="title">各乡镇林种面积蓄积按龄统计</span>
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>
    <div class="content" ref="echartsRef"></div>
  </div>
</template>

<style scoped lang="less">
.forest-type-area-statistics-accumulation-dialog {
  background: linear-gradient(0deg, rgba(1, 144, 255, 0.08) 0%, rgba(1, 144, 255, 0.08) 100%), rgba(20, 36, 51, 0.90);

  &.open {
    width: 672px;
    height: 362px;
    overflow: visible;
  }

  .content {
    padding: 10px;
  }
}
</style>