<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyElectricityConsumptionTrendsData } from "./economy.api.service"

export default {
  name: 'EconomyElectricityConsumptionTrends',
  components: {
    CardTitle
  },
  setup() {
    const dataList = ref([])
    const dataObj = reactive([])

    const echartsData = ref([
      {
        "name": "1月",
        "value": 35,
        "value1": 30
      },
      {
        "name": "2月",
        "value": 40,
        "value1": 37
      },
      {
        "name": "3月",
        "value": 60,
        "value1": 50
      },
      {
        "name": "4月",
        "value": 80,
        "value1": 61
      },
      {
        "name": "5月",
        "value": 70,
        "value1": 39
      },
      {
        "name": "6月",
        "value": 30,
        "value1": 80
      },
      {
        "name": "7月",
        "value": 42,
        "value1": 59
      },
      {
        "name": "8月",
        "value": 60,
        "value1": 60
      },
      {
        "name": "9月",
        "value": 51,
        "value1": 77
      },
      {
        "name": "10月",
        "value": 32,
        "value1": 25
      },
      {
        "name": "11月",
        "value": 36,
        "value1": 51
      },
      {
        "name": "12月",
        "value": 78,
        "value1": 29
      }
    ])
    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const xArr = [
      {
        name: '企业总用电趋势',
        color: 'rgba(0, 200, 132, 1)',
        colorArea: 'rgba(0, 200, 132, 0.20)',
        field: 'value'
      }, {
        name: '规上企业用电趋势',
        color: 'rgba(255, 186, 47, 1)',
        colorArea: 'rgba(255, 186, 47, .3)',
        field: 'value1'
      }
    ]
    const getEchartsOption = (data, arr) => {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        dataZoom: {
          type: 'inside'
        },
        legend: {
          show: true,
          // clickable:true,
          right: 10,
          top: 15,
          // icon: 'rect',
          textStyle: {
            color: '#fff',
            fontSize: 14
          },
          itemWidth: 16, // 宽
          itemHeight: 6, // 高
          itemGap: 24
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '10%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          type: 'category',
          interval: 0,
          axisLabel: {
            show: true,
            interval: 0,
            textStyle: {
              color: 'rgba(150, 169, 186, 1)',
              fontSize: 12,
              padding: [ 10, 0, 0, 0 ]
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.5)',
              width: 1
            }
          },
          boundaryGap: false,
          data: data.map(i => i.name)
        },

        yAxis: [ {
          name: '单位：万元',
          type: 'value',
          nameTextStyle: {
            fontSize: 12,
            padding: [ 20, 0, 0, 10 ],
            color: 'rgba(150, 169, 186, 1)'
          },
          splitNumber: 4,
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed', //y轴分割线类型
              width: 1,
              color: 'rgba(255, 255, 255, 0.5)'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'rgba(150, 169, 186, 1)',
              fontSize: 12,
              padding: [ 0, 8, 0, 0 ]
            }
          }
        } ],
        series: arr.map(i => {
          return {
            name: i.name,
            type: 'line',
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: i.color // 线条颜色
              },
              borderColor: i.color
            },
            // symbol: 'circle', //设定为实心点
            symbolSize: 0, //设定中心点的大小
            itemStyle: {
              normal: {
                color: i.color, // 原点颜色
                lineStyle: {
                  color: i.color, //折线颜色
                  width: 1 // 0.1的线条是非常细的了
                }
              },
              borderColor: i.color,
              borderWidth: 1
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new window.echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [ {
                      offset: 0,
                      color: i.colorArea
                    },
                      {
                        offset: 1,
                        color: 'rgba(255,189,12,0)'
                      }
                    ],
                    false
                ),
                shadowColor: i.color //阴影颜色
                // shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data.map(j => j[i.field])
          }
        })
      }
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value), xArr))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyElectricityConsumptionTrendsData({
        type: value
      }))
      if (err) return
      console.log('用电趋势 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      dataList,
      dataObj,

      init
    }
  }
}
</script>

<template>
  <div class="economy-electricity-consumption-trends">
    <CardTitle title="用电趋势"  calendar-type="year" show-calendar @date-change="init"/>
    <div class="content" ref="echartsRef"></div>
  </div>
</template>

<style scoped lang="less">
.economy-electricity-consumption-trends {
  height: 226px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);
  }
}
</style>