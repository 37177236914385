<script>
import to from "await-to-js"
import { markRaw, onMounted, reactive, ref, toRaw, nextTick } from "vue"

import CardTitle from "./CardTitleComponent.vue"
import GlobalData from "./GlobalData"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import ForestUnderForestEconomyDialog from "./ForestUnderForestEconomyDialog.vue"
import { getForestUnderForestEconomyData } from "./forest.api.service"

export default {
  name: 'ForestUnderForestEconomy',
  components: {
    CardLeftTopDot,
    CardTitle,
    ForestUnderForestEconomyDialog
  },
  setup() {
    const forestUnderForestEconomyDialogRef = ref()
    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const echartsData = ref([])

    const dataInfo = reactive({
      // 林业总产值
      totalValue: 0,
      totalValueUnit: '',

      // 林下经济总产值
      underValue: 0,
      underValueUnit: '',

      // 从事林下经济的人数
      underPeople: 0,
      underPeopleUnit: ''

    })

    const getTypeEchartsData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 12,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '10%',    // 顶部距离
          bottom: '10%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [ {
          data: data.map(item => item.value),
          type: 'bar', // barMaxWidth: 'auto',
          barWidth: 15,
          itemStyle: {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                offset: 0,
                color: '#F5E74F'
              }, {
                offset: 1,
                color: '#FCE9AE'
              } ])
            }
          },
          label: {
            show: false
          }
        } ]
      }
    }

    const init = async () => {
      const [ err, res ] = await to(getForestUnderForestEconomyData())
      if (err) return
      console.log('林下经济 -----> ', res)
      const {
        forestry_total,
        unit_forestry,

        economy_total,
        unit_economy,

        people_total,
        unit_people,

        statistics_data
      } = res.data

      dataInfo.totalValue = forestry_total
      dataInfo.totalValueUnit = unit_forestry

      dataInfo.underValue = economy_total
      dataInfo.underValueUnit = unit_economy

      dataInfo.underPeople = people_total
      dataInfo.underPeopleUnit = unit_people

      echartsData.value = (Array.isArray(statistics_data.x_data) ? statistics_data.x_data : []).map((item, i) => {
        return {
          name: item,
          value: statistics_data.y_data[i]
        }
      })

      await nextTick()

      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf.value.setOption(getTypeEchartsData(toRaw(echartsData.value)))
      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const openDialog = () => {
      forestUnderForestEconomyDialogRef.value.open()
    }

    onMounted(init)

    return {
      GlobalData,
      echartsRef,
      forestUnderForestEconomyDialogRef,

      dataInfo,
      openDialog
    }
  }
}
</script>

<template>
  <div class="forest-under-forest-economy" @click="openDialog">
    <CardTitle title="林下经济"/>

    <div class="content">
      <CardLeftTopDot/>

      <ul class="data-info">
        <li class="item">
          <sapn class="name">林业总产值</sapn>
          <div class="count">
            <span class="text">{{ dataInfo.totalValue }}</span>
            <span class="unit">{{ dataInfo.totalValueUnit }}</span>
          </div>
        </li>
        <li class="line"></li>
        <li class="item">
          <sapn class="name">林下经济总产值</sapn>
          <div class="count">
            <span class="text">{{ dataInfo.underValue }}</span>
            <span class="unit">{{ dataInfo.underValueUnit }}</span>
          </div>
        </li>
        <li class="line"></li>
        <li class="item">
          <sapn class="name">从事林下经济的人数</sapn>
          <div class="count">
            <span class="text">{{ dataInfo.underPeople }}</span>
            <span class="unit">{{ dataInfo.underPeopleUnit }}</span>
          </div>
        </li>
      </ul>
      <div class="chart" ref="echartsRef"></div>
    </div>

    <ForestUnderForestEconomyDialog ref="forestUnderForestEconomyDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.forest-under-forest-economy {
  height: 245px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .data-info {
      width: 470px;
      height: 64px;
      display: flex;
      margin: 6px 10px 0 10px;
      background-image: url('@{img_url}forest-under-forest-economy-card_bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .line {
        width: 1px;
        height: 53px;
        opacity: 0.4;
        background: linear-gradient(90deg, rgba(113, 184, 238, 0) 0%, #71B8EE 50%, rgba(113, 184, 238, 0) 100%);
      }
    }

    .item {
      flex: 1;
      padding: 9px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .name {
        height: 16px;
        line-height: 16px;
        color: #B9E0FF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .count {
        height: 22px;
        display: flex;
        align-items: baseline;

        .text {
          color: #FFF;
          text-align: center;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
        }

        .unit {
          color: #E5F4FF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

    }
  }

  .chart {
    flex: 1;
  }
}
</style>