<script>
import MeetingOverview from './SOAMeetingOverview.vue'
import MeetingTrends from './SOAMeetingTrends.vue'
import WaitingForDiscussion from './SOAWaitingForDiscussion.vue'

export default {
  name: 'SOALayoutRight',
  components: {
    // 三重一大会议总览
    MeetingOverview,

    // 会议新增趋势情况
    MeetingTrends,

    // 待上会议题
    WaitingForDiscussion
  }
}
</script>

<template>
  <div class="layout-right-wrap">
    <!--    三重一大会议总览-->
    <MeetingOverview/>

    <!--    会议新增趋势情况-->
    <MeetingTrends/>

    <!--    待上会议题-->
    <WaitingForDiscussion/>
  </div>
</template>

<style scoped lang="less">
.layout-right-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>