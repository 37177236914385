<script>
import { ref, computed } from "vue"
import GlobalData from "./GlobalData"
import SwitchTabComponent from "./SwitchTabComponent.vue"

export default {
  name: 'cardHeader',
  components: {
    SwitchTabComponent
  },
  props: {
    title: String,
    showIcon: {
      type: Boolean,
      default: true
    },

    showCalendar: {
      type: Boolean,
      default: false
    },

    calendarType: {
      type: String,
      default: 'year'
    },

    tabList: {
      type: Array,
      default: () => []
    },

    selectList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const getFullYear = new Date().getFullYear()
    const time = ref(getFullYear.toString())
    const selectVal = ref()
    const dateFormat = computed(() => {
      switch (props.calendarType) {
        case 'year':
          return 'YYYY'
        case 'month':
          return 'YYYY-MM'
        case 'date':
          return 'YYYY-MM-DD'
        default:
          return 'YYYY-MM-DD'
      }
    })

    return {
      GlobalData,

      time,
      selectVal,
      dateFormat
    }
  }
}
</script>

<template>
  <div class="card-header">
    <div class="header_content">
      <img v-if="showIcon" :src="GlobalData.getImgUrl('title_icon.png')" class="icon" alt="">
      <em class="title">{{ title }}</em>

      <slot name="headerRightWrap">
        <div class="header-right-wrap">
        <el-select
            v-if="selectList.length > 0"
            v-model="selectVal"
            style="width: 110px"
            placeholder="请选择"
            @change="$emit('select-change', $event)"
        >
          <el-option
              v-for="item in selectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>

        <el-date-picker v-if="showCalendar" v-model="time" :type="calendarType" placeholder="请选择"
                        :value-format="dateFormat" :clearable="false" @change="$emit('date-change', $event)"/>
        <!--        <CalendarComponent v-if="showCalendar" @date-change="$emit('date-change', $event)"/>-->
        <SwitchTabComponent v-if="tabList.length > 0" :list="tabList" @tab-change="$emit('tab-change', $event)"/>
        </div>
      </slot>
    </div>
    <div class="bottom-line"></div>
    <div class="right-bottom-dot"></div>
  </div>
</template>

<style scoped lang="less">
.card-header {
  height: 30px;
  display: flex;
  gap: 3px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  .header_content {
    height: 24px;
    display: flex;
    gap: 4px;
    padding-right: 8px;
    align-items: center;

    & > .icon {
      width: 18px;
      height: 20px;
    }

    .title {
      flex: 1;
      font-size: 20px;
      font-style: italic;
      font-weight: 700;
      line-height: 1;
      height: 20px;
      letter-spacing: 1px;
      background: linear-gradient(3deg, #1D98FC 5.22%, #FFF 50.57%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .header-right-wrap{
      display: flex;
      gap: 6px;
    }
  }

  .bottom-line {
    width: 100%;
    height: 1px;
    margin-bottom: 2px;
    background: linear-gradient(90deg, rgba(110, 182, 255, 0) 0%, rgba(110, 182, 255, 0.78) 17.37%, rgba(110, 182, 255, 0) 34.2%),
    #35436F;
  }

  .right-bottom-dot {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #009AFF;
  }
}
</style>