<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getForestAreaStatisticsData } from "./forest.api.service"

export default {
  name: 'ForestAreaStatistics',
  components: {
    CardTitle,
    CardLeftTopDot
  },
  setup() {
    // 面积
    const areaTypeEchartsRef = ref(null)
    const areaTypeEchartsSelf = ref(null)
    const areaTypeData = ref([])

    // 蓄积
    const accumulationTypeData = ref([])
    const accumulationTypeEchartsRef = ref(null)
    const accumulationTypeEchartsSelf = ref(null)

    const currentTab = ref('area')
    const tabList = ref([
      {
        label: '面积',
        key: 'area'
      },
      {
        label: '蓄积',
        key: 'accumulation'
      }
    ])

    const getTypeAreaTypeData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 12,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '10%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 12
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [ {
          data: data.map(item => item.value),
          type: 'bar', // barMaxWidth: 'auto',
          barWidth: 15,
          itemStyle: {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                offset: 0,
                color: '#1DDAEB'
              }, {
                offset: 1,
                color: '#1897FD'
              } ])
            }
          },
          label: {
            show: false
          }
        } ]
      }
    }

    const initAreaEcharts = () => {
      if (toRaw(areaTypeEchartsSelf.value)) {
        areaTypeEchartsSelf.value.dispose()
      }

      areaTypeEchartsSelf.value = markRaw(window.echarts.init(areaTypeEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      areaTypeEchartsSelf.value.setOption(getTypeAreaTypeData(toRaw(areaTypeData.value)))
      setTimeout(function () {
        window.onresize = function () {
          areaTypeEchartsSelf.value.resize()
        }
      }, 200)
    }

    const initAccumulationEcharts = () => {
      if (toRaw(accumulationTypeEchartsSelf.value)) {
        accumulationTypeEchartsSelf.value.dispose()
      }

      accumulationTypeEchartsSelf.value = markRaw(window.echarts.init(accumulationTypeEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      accumulationTypeEchartsSelf.value.setOption(getTypeAreaTypeData(toRaw(accumulationTypeData.value)))
      setTimeout(function () {
        window.onresize = function () {
          accumulationTypeEchartsSelf.value.resize()
        }
      }, 200)
    }
    const init = async () => {
      const [ err, res ] = await to(getForestAreaStatisticsData())
      if (err) return
      console.log('各类森林面积、蓄积统计 -----> ', res)

      const data = res.data

      areaTypeData.value = (Array.isArray(data.x_area) ? data.x_area : []).map((item, i) => {
        return {
          name: item,
          value: data.y_area[i]
        }
      })
      accumulationTypeData.value = (Array.isArray(data.x_accumulate) ? data.x_accumulate : []).map((item, i) => {
        return {
          name: item,
          value: data.y_accumulate[i]
        }
      })

      await nextTick()
      handleTabChange(currentTab.value)
    }

    const handleTabChange = (key) => {
      currentTab.value = key
      nextTick(() => {
        key === 'area' ? initAreaEcharts() : initAccumulationEcharts()
      })
    }

    onMounted(init)

    return {
      GlobalData,

      areaTypeEchartsRef,
      accumulationTypeEchartsRef,

      tabList,
      currentTab,

      handleTabChange
    }
  }
}
</script>

<template>
  <div class="forest-area-statistics">
    <CardTitle title="各类森林面积、蓄积统计" :tab-list="tabList" @tab-change="handleTabChange"/>

    <div class="content">
      <CardLeftTopDot/>

      <div class="chart" v-if="currentTab === 'area'" ref="areaTypeEchartsRef"></div>
      <div class="chart" v-else ref="accumulationTypeEchartsRef"></div>
    </div>
  </div>
</template>

<style scoped lang="less">
.forest-area-statistics {
  height: 192px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>