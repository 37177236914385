<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw, computed } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyIndustrialEnergyConsumptionData } from "./economy.api.service"

export default {
  name: 'EconomyIndustrialEnergyConsumption',
  components: {
    CardTitle
  },
  setup() {
    const activeType = ref('industryStatistics')
    const typeList = reactive([
      {
        label: '行业统计',
        value: 'industryStatistics'
      },
      {
        label: '供、发电量排行',
        value: 'supplyPowerRanking'
      }
    ])

    const industryStatisticsList = reactive([
      {
        name: '电力',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#2491FF'
      },
      {
        name: '铁合金',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#5AD8A6'
      },
      {
        name: '工业硅',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#6CC8EC'
      },
      {
        name: '稀土',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#FF9000'
      },
      {
        name: '化工',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#FFDF24'
      },
      {
        name: '建材',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#F45D42'
      },
      {
        name: '农产品加工',
        value: 6000,
        unit: '万kW·h',
        percent: 26,
        color: '#B06AF7'
      }
    ])
    const supplyPowerRankingList = reactive([
      {
        name: '电力',
        value: 500,
        unit: '万kW·h',
        percent: 45,
        color: '#2491FF'
      },
      {
        name: '铁合金',
        value: 1000,
        unit: '万kW·h',
        percent: 22,
        color: '#5AD8A6'
      },
      {
        name: '工业硅',
        value: 2000,
        unit: '万kW·h',
        percent: 43,
        color: '#6CC8EC'
      },
      {
        name: '稀土',
        value: 3000,
        unit: '万kW·h',
        percent: 65,
        color: '#FF9000'
      },
      {
        name: '化工',
        value: 4000,
        unit: '万kW·h',
        percent: 11,
        color: '#FFDF24'
      },
      {
        name: '建材',
        value: 5000,
        unit: '万kW·h',
        percent: 2,
        color: '#F45D42'
      },
      {
        name: '农产品加工',
        value: 6000,
        unit: '万kW·h',
        percent: 3,
        color: '#B06AF7'
      }
    ])
    const echartsTypeList = computed(() => {
      if (activeType.value === 'industryStatistics') {
        return toRaw(industryStatisticsList)
      }
      else if (activeType.value === 'supplyPowerRanking') {
        return toRaw(supplyPowerRankingList)
      }
      else {
        return toRaw(industryStatisticsList)
      }
    })

    const echartsData = computed(() => {
      if (activeType.value === 'industryStatistics') {
        return toRaw(industryStatisticsList)
      }
      else if (activeType.value === 'supplyPowerRanking') {
        return toRaw(supplyPowerRankingList)
      }
      else {
        return toRaw(industryStatisticsList)
      }
    })
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const getEchartsOption = (data) => {
      let subtext = 0
      if (data && data.length > 0) {
        data.forEach(item => {
          subtext = subtext + Number(item.value)
        })
      }

      return {
        // backgroundColor: '#364686',
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        color: data.map(item => item.color),
        polar: {
          radius: [ '40%', '50%' ],
          center: [ '50%', '50%' ]
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        title: [
          {
            text: '行业总能耗',
            left: 'center',
            top: '25%',
            textStyle: {
              fontSize: 14,
              color: '#ffffff'
            }
          },
          {
            text: '92.65', // x: '25%',
            x: 'center',
            top: '38%',
            textStyle: {
              color: '#fff',
              fontSize: 28,
              fontWeight: 'bold'
            }
          },
          {
            text: '亿千瓦时',
            x: 'center',
            top: '62%',
            textStyle: {
              fontSize: 14,
              color: '#ffffff'
            }
          }
        ],
        series: [
          {
            type: 'pie',
            radius: [ '85%', '100%' ],
            center: [ '50%', '50%' ],
            data: data,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderColor: '#364684',
                borderWidth: 0,
                borderRadius: 100
              }
            },
            tooltip: {
              position: [ '50%', '50%' ]
            },
            label: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            hoverAnimation: false,
            radius: '80%',
            center: [ '50%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]),
                shadowBlur: 10
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyIndustrialEnergyConsumptionData({
        type: value
      }))
      if (err) return
      console.log('规上工业能耗 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      typeList,
      activeType,
      echartsTypeList,

      init
    }
  }
}
</script>

<template>
  <div class="economy-industrial-energy-consumption">
    <CardTitle title="规上工业能耗" calendar-type="year" show-calendar @date-change="init"/>
    <div class="content">
      <ul class="head">
        <li
            :class="['item', {active: item.value === activeType}]"
            v-for="item in typeList"
            :key="item.value"
            @click="activeType = item.value"
        >{{ item.label }}
        </li>
      </ul>
      <div class="echarts-wrap">
        <div class="echarts" ref="echartsRef"></div>
        <ul class="echarts-info-list">
          <li class="item" v-for="item in echartsTypeList" :key="item">
            <label class="label">
              <i class="dot" :style="{background: item.color}"></i>
              <span class="text">{{ item.name }}</span>
            </label>
            <div class="value-wrap">
              <span class="count" :style="{color: item.color}">{{ item.count }}</span>
              <span class="unit">{{ item.unit }}</span>
              <i class="line"></i>
              <span class="percentage" :style="{color: item.color}">{{ item.percent }}%</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.economy-industrial-energy-consumption {
  height: 244px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 10px 12px;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .head {
      height: 32px;
      display: flex;
      align-items: center;
      gap: 8px;

      .item {
        flex: 1;
        cursor: pointer;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #CCDDF0;

        border: 1px solid rgba(68, 139, 169, 0.10);
        background: rgba(22, 60, 85, 0.10);
        box-shadow: 0px 0px 9px 0px rgba(64, 199, 252, 0.04) inset;

        &.active {
          color: #87DBFF;
          border: 1px solid #448BA9;
          background: rgba(22, 60, 85, 0.90);
          box-shadow: 0px 0px 9px 0px rgba(64, 199, 252, 0.80) inset;
        }
      }
    }

    .echarts-wrap {
      flex: 1;
      display: flex;
      gap: 16px;
      align-items: center;

      .echarts {
        width: 140px;
        height: 140px;
      }

      .echarts-info-list {
        flex: 1;
        padding: 4px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 8px 0;

        .item {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .label {
            gap: 2px;
            height: 14px;
            display: flex;
            align-items: center;

            .dot {
              width: 6px;
              height: 6px;
            }

            .text {
              flex: 1;
              color: #FFF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }

          .value-wrap {
            height: 17px;
            display: flex;
            align-items: baseline;

            .count,
            .percentage {
              font-family: D-DIN;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px; /* 100% */
            }

            .unit {
              color: #FFF;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }

            .line {
              width: 1px;
              height: 16px;
              opacity: 0.4;
              margin: 0 8px;
              background: linear-gradient(90deg, rgba(113, 184, 238, 0.00) 0%, #71B8EE 50%, rgba(113, 184, 238, 0.00) 100%);
            }
          }
        }
      }
    }

  }
}
</style>