<script>
import to from "await-to-js"
import { markRaw, onMounted, ref, reactive, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getSOARiskMonitoringData } from "./soa.api.service"

export default {
  name: 'SOARiskMonitoring',
  components: {
    CardTitle,
    CardLeftTopDot
  },
  setup() {
    const echartsRef1 = ref(null)
    const echartsSelf1 = ref(null)

    const echartsRef2 = ref(null)
    const echartsSelf2 = ref(null)

    const echartsRef3 = ref(null)
    const echartsSelf3 = ref(null)

    const echartsRef4 = ref(null)
    const echartsSelf4 = ref(null)

    const echartsRef5 = ref(null)
    const echartsSelf5 = ref(null)

    const echartsRef6 = ref(null)
    const echartsSelf6 = ref(null)

    // 收入同比下降
    const income_contrast = reactive({
      val: 0,
      ratio: 0
    })

    // 利润同步下降
    const profit_contrast = reactive({
      val: 0,
      ratio: 0
    })

    // 资产负债率大于65%
    const debts_gt_65 = reactive({
      val: 0,
      ratio: 0
    })

    // 两金增幅高于营收增幅
    const gt_revenue = reactive({
      val: 0,
      ratio: 0
    })

    // 带息负债增长
    const debts_inc = reactive({
      val: 0,
      ratio: 0
    })

    // 亏损企业
    const loss_enterprise = reactive({
      val: 0,
      ratio: 0
    })

    const getOption = (num, colorArr) => {
      return {

        angleAxis: {
          startAngle: 100,
          max: 100,
          clockwise: true,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        polar: {
          radius: '170%'
        },
        series: [
          {
            type: 'bar',
            data: [ num ],
            showBackground: true,
            backgroundStyle: {
              color: '#EFF2F5',
              borderColor: '#EFF2F5',
              borderWidth: 1
            },
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 5,
            silent: true,
            itemStyle: {
              normal: {
                opacity: 1,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: colorArr[0]
                    },

                    {
                      offset: 1,
                      color: colorArr[1]
                    }
                  ],
                  global: false
                },
                borderWidth: 3
              }
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            hoverAnimation: false,
            radius: '60%',
            center: [ '50%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]),
                shadowBlur: 10
              }
            },
            data: [ {
              value: 100
            } ]
          }

        ]
      }
    }

    const getStreet = (text, fill) => {
      return {
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              fontSize: 16,
              fontWeight: 900,
              text, // 显示的文字
              fill, // 文字颜色
              textAlign: 'center',
              textBaseline: 'middle'
            }
            // 偏移位置，确保文字居中
            // position: ['-50%', '-50%']
          }
        ]
      }
    }

    const init = async () => {
      const [ err, res ] = await to(getSOARiskMonitoringData())
      if (err) return
      console.log('风险监控 -----> ', res)
      const data = res.data

      // 收入同比下降
      income_contrast.val = data.income_contrast.val
      income_contrast.ratio = data.income_contrast.ratio

      // 利润同步下降
      profit_contrast.val = data.profit_contrast.val
      profit_contrast.ratio = data.profit_contrast.ratio

      // 资产负债率大于65%
      debts_gt_65.val = data.debts_gt_65.val
      debts_gt_65.ratio = data.debts_gt_65.ratio

      // 两金增幅高于营收增幅
      gt_revenue.val = data.gt_revenue.val
      gt_revenue.ratio = data.gt_revenue.ratio

      // 带息负债增长
      debts_inc.val = data.debts_inc.val
      debts_inc.ratio = data.debts_inc.ratio

      // 亏损企业
      loss_enterprise.val = data.loss_enterprise.val
      loss_enterprise.ratio = data.loss_enterprise.ratio

      // 收入同比下降 ------------------------------------------------------------------
      if (toRaw(echartsSelf1.value)) {
        echartsSelf1.value.dispose()
      }

      echartsSelf1.value = markRaw(window.echarts.init(echartsRef1.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf1.value.setOption({
        ...getOption(income_contrast.ratio, [ '#F2C94C', '#D6F4FF' ]),
        ...getStreet(`${ income_contrast.ratio }%`, '#F2C94C')
      })

      // 利润同步下降 ------------------------------------------------------------------
      if (toRaw(echartsSelf2.value)) {
        echartsSelf2.value.dispose()
      }

      echartsSelf2.value = markRaw(window.echarts.init(echartsRef2.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf2.value.setOption({
        ...getOption(profit_contrast.ratio, [ '#63D4C0', '#D6F4FF' ]),
        ...getStreet(`${ profit_contrast.ratio }%`, '#63D3BE')
      })

      // 资产负债率大于65% ------------------------------------------------------------------
      if (toRaw(echartsSelf3.value)) {
        echartsSelf3.value.dispose()
      }

      echartsSelf3.value = markRaw(window.echarts.init(echartsRef3.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf3.value.setOption({
        ...getOption(debts_gt_65.ratio, [ '#50B5F0', '#D6F4FF' ]),
        ...getStreet(`${ debts_gt_65.ratio }%`, '#50B5F0')
      })

      // 两金增幅高于营收增幅 ------------------------------------------------------------------
      if (toRaw(echartsSelf4.value)) {
        echartsSelf4.value.dispose()
      }

      echartsSelf4.value = markRaw(window.echarts.init(echartsRef4.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf4.value.setOption({
        ...getOption(gt_revenue.ratio, [ '#1F7CFF', '#D6F4FF' ]),
        ...getStreet(`${ gt_revenue.ratio }%`, '#1F7CFF')
      })

      // 带息负债增长 ------------------------------------------------------------------
      if (toRaw(echartsSelf5.value)) {
        echartsSelf5.value.dispose()
      }

      echartsSelf5.value = markRaw(window.echarts.init(echartsRef5.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf5.value.setOption({
        ...getOption(debts_inc.ratio, [ '#454FE5', '#D6F4FF' ]),
        ...getStreet(`${ debts_inc.ratio }%`, '#454FE5')
      })

      // 亏损企业 ------------------------------------------------------------------
      if (toRaw(echartsSelf6.value)) {
        echartsSelf6.value.dispose()
      }

      echartsSelf6.value = markRaw(window.echarts.init(echartsRef6.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf6.value.setOption({
        ...getOption(loss_enterprise.ratio, [ '#FF7401', '#D6F4FF' ]),
        ...getStreet(`${ loss_enterprise.ratio }%`, '#FF7401')
      })

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf1.value.resize()
          echartsSelf2.value.resize()
          echartsSelf3.value.resize()
          echartsSelf4.value.resize()
          echartsSelf5.value.resize()
          echartsSelf6.value.resize()
        }
      }, 200)
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef1,
      echartsSelf1,

      echartsRef2,
      echartsSelf2,

      echartsRef3,
      echartsSelf3,

      echartsRef4,
      echartsSelf4,

      echartsRef5,
      echartsSelf5,

      echartsRef6,
      echartsSelf6,

      income_contrast,
      profit_contrast,
      debts_gt_65,
      gt_revenue,
      debts_inc,
      loss_enterprise
    }
  }
}
</script>

<template>
  <div class="risk-monitoring">
    <CardTitle title="风险监测"/>

    <div class="content">
      <CardLeftTopDot/>
      <ul class="list">
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef1"></div>
          <div class="info">
            <p class="name">收入同比下降</p>
            <div class="count">
              <span class="num">{{ income_contrast.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef2"></div>
          <div class="info">
            <p class="name">利润同比下降</p>
            <div class="count">
              <span class="num">{{ profit_contrast.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef3"></div>
          <div class="info">
            <p class="name">资产负债率大于65%</p>
            <div class="count">
              <span class="num">{{ debts_gt_65.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef4"></div>
          <div class="info">
            <p class="name">两金增幅高于营收增幅</p>
            <div class="count">
              <span class="num">{{ gt_revenue.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef5"></div>
          <div class="info">
            <p class="name">带息负债增长</p>
            <div class="count">
              <span class="num">{{ debts_inc.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="echarts-wrap" ref="echartsRef6"></div>
          <div class="info">
            <p class="name">亏损企业</p>
            <div class="count">
              <span class="num">{{ loss_enterprise.val }}</span>
              <span class="uint">家</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="less">
.risk-monitoring {
  height: 280px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .list {
      flex: 1;
      padding: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 每行 3 列，每列宽度相等 */
      grid-template-rows: repeat(2, 1fr); /* 总共 2 行，每行高度相等 */
      gap: 32px 23px; /* 列和行之间的间隔 */

      .item {
        height: 80px;
        display: flex;
        align-items: center;
        gap: 8px;
        background: url('@{img_url}risk-monitoring-item_bg.png') no-repeat;
        background-size: 100%;

        .echarts-wrap {
          width: 80px;
          height: 80px;
        }

        .info {
          flex: 1;
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .name {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 100% */
            background: linear-gradient(180deg, #ECF8FF 0%, #B8CAD4 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .count {
            height: 20px;
            display: flex;
            align-items: baseline;
            gap: 1px;

            .num {
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              background: linear-gradient(180deg, #6BDCFF 0%, #F1FFFC 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .uint {
              color: #F2F6FA;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>