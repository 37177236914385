<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw } from "vue"

import CardTitle from "./CardTitleComponent.vue"
import GlobalData from "./GlobalData"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import ForestProtectionAreaStatsDialog from "./ForestProtectionAreaStatsDialog.vue"
import { getForestProtectionAreaStatsData } from "./forest.api.service"

export default {
  name: 'ForestProtectionAreaStats',
  components: {
    CardLeftTopDot,
    CardTitle,
    ForestProtectionAreaStatsDialog
  },
  setup() {
    const forestProtectionAreaStatsDialogRef = ref()
    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const echartsData = ref([])

    const getData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 0,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '5%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        series: [
          {
            name: '国有',
            data: data.map(item => item.value),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#1EE7E7'
                }, {
                  offset: 1,
                  color: '#36C4E3'
                } ])
              }
            },
            label: {
              show: false
            }
          },
          {
            name: '个人',
            data: data.map(item => item.value1),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#1890FF'
                }, {
                  offset: 1,
                  color: '#36C4E3'
                } ])
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    }

    const init = async () => {
      const [ err, res ] = await to(getForestProtectionAreaStatsData())
      if (err) return
      console.log('林地保护等级面积统计 -----> ', res)

      const {
        x_data,
        y_data
      } = res.data
      // 处理数据
      echartsData.value = (Array.isArray(x_data) ? x_data : []).map((item, i) => {
        return {
          name: item,
          value: y_data.find(item1 => item1.name === '国有').val[i],
          value1: y_data.find(item1 => item1.name === '个人').val[i]
        }
      })

      await nextTick()
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf.value.setOption(getData(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const openDialog = () => {
      forestProtectionAreaStatsDialogRef.value.open()
    }

    onMounted(init)

    return {
      GlobalData,
      echartsRef,
      forestProtectionAreaStatsDialogRef,

      init,
      openDialog
    }
  }
}
</script>

<template>
  <div class="forest-protection-area-stats" @click="openDialog">
    <CardTitle title="林地保护等级面积统计"/>

    <div class="content">
      <CardLeftTopDot/>

      <div class="chart" ref="echartsRef"></div>
    </div>

    <ForestProtectionAreaStatsDialog ref="forestProtectionAreaStatsDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.forest-protection-area-stats {
  height: 192px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>