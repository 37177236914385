<script>
import { defineExpose, markRaw, nextTick, reactive, ref, toRaw } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'ForestLandAreaStatisticsDialog',
  setup() {
    const dialogVisible = ref(false)
    const dialogEchartsRef = ref(null)
    const dialogEchartsSelf = ref(null)
    const echartsData = reactive({
      list: [],
      total: ''
    })

    const getTypeEchartsData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 12,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '10%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 12
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [ {
          data: data.map(item => item.value),
          type: 'bar', // barMaxWidth: 'auto',
          barWidth: 15,
          itemStyle: {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                offset: 0,
                color: '#1DDAEB'
              }, {
                offset: 1,
                color: '#1897FD'
              } ])
            }
          },
          label: {
            show: false
          }
        } ]
      }
    }

    const initEcharts = async () => {
      console.log(dialogEchartsSelf, echartsData)
      echartsData.value = [
        {
          "name": "峨边镇",
          "value": 90
        },
        {
          "name": "大堡镇",
          "value": 40
        },
        {
          "name": "黑竹沟镇",
          "value": 102
        },
        {
          "name": "红旗镇",
          "value": 10
        },
        {
          "name": "金岩乡",
          "value": 30
        },
        {
          "name": "勒乌乡",
          "value": 22
        },
        {
          "name": "毛坪镇",
          "value": 76
        },
        {
          "name": "平等乡",
          "value": 40
        },
        {
          "name": "沙坪镇",
          "value": 102
        },
        {
          "name": "612林场",
          "value": 10
        },
        {
          "name": "613林场",
          "value": 30
        }
      ]
      await nextTick()
      console.log('nextTick')
      if (toRaw(dialogEchartsSelf.value)) {
        dialogEchartsSelf.value.dispose()
      }

      dialogEchartsSelf.value = markRaw(window.echarts.init(dialogEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      dialogEchartsSelf.value.setOption(getTypeEchartsData(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          dialogEchartsSelf.value.resize()
        }
      }, 200)
    }

    /**
     * 打开弹窗
     */
    const open = () => {
      dialogVisible.value = true
      setTimeout(initEcharts, 550)
    }

    defineExpose({
      open
    })

    return {
      GlobalData,
      dialogEchartsRef,

      dialogVisible,
      open
    }
  }
}
</script>

<template>
  <div class="dialog__center forest-land-area-statistics-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">各乡镇土地面积统计</span>
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>
    <div class="content" ref="dialogEchartsRef"></div>
  </div>
</template>

<style scoped lang="less">
.forest-land-area-statistics-dialog {
  &.open {
    width: 672px;
    height: 290px;
  }

  .content {
    padding: 0;
  }
}
</style>