<script>
import to from "await-to-js"
import { onMounted, ref } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import SOAMeetingOverviewDialog from "./SOAMeetingOverviewDialog.vue"
import { getSOAMeetingOverviewData } from "./soa.api.service"

export default {
  name: 'SOAMeetingOverview',
  components: {
    CardTitle,
    CardLeftTopDot,
    SOAMeetingOverviewDialog
  },
  setup() {
    const SOAMeetingOverviewDialogRef = ref()

    // 今日会议
    const today_meeting = ref(0)

    // 明日会议
    const tomorrow_meeting = ref(0)

    // 会议通过率（%）
    const meeting_pass_ratio = ref(0)

    // 会议（次）
    const meeting_num = ref(0)

    // 议题（个）
    const topics_num = ref(0)

    // 重大决策（项）
    const important_decision_num = ref(0)

    // 重要人事任免（项）
    const important_appoint_num = ref(0)

    // 重大项目安排（项）
    const important_project_num = ref(0)

    // 大额资金运作（项）
    const fund_num = ref(0)

    const openDialog = () => {
      SOAMeetingOverviewDialogRef.value.open()
    }

    const init = async () => {
      const [ err, res ] = await to(getSOAMeetingOverviewData())
      if (err) return
      console.log('三重一大会议总览 -----> ', res)

      const data = res.data
      today_meeting.value = data.today_meeting
      tomorrow_meeting.value = data.tomorrow_meeting
      meeting_pass_ratio.value = data.meeting_pass_ratio
      meeting_num.value = data.meeting_num
      topics_num.value = data.topics_num
      important_decision_num.value = data.important_decision_num
      important_appoint_num.value = data.important_appoint_num
      important_project_num.value = data.important_project_num
      fund_num.value = data.fund_num
    }

    onMounted(init)

    return {
      GlobalData,

      SOAMeetingOverviewDialogRef,

      today_meeting,
      tomorrow_meeting,
      meeting_pass_ratio,
      meeting_num,
      topics_num,
      important_decision_num,
      important_appoint_num,
      important_project_num,
      fund_num,

      openDialog
    }
  }
}
</script>

<template>
  <div class="meeting-overview">
    <CardTitle title="三重一大会议总览"/>

    <div class="content">
      <CardLeftTopDot/>

      <ul class="meeting">
        <li class="item">
          <img :src="GlobalData.getImgUrl('meeting-overview_icon1.png')" class="icon" alt="">
          <span class="name">今日会议</span>
          <span class="num">{{ today_meeting }}</span>
        </li>
        <li class="item" >
          <img :src="GlobalData.getImgUrl('meeting-overview_icon2.png')" class="icon" alt="">
          <span class="name">明日会议</span>
          <span class="num">{{ tomorrow_meeting }}</span>
        </li>
      </ul>
      <div class="dashboard">
        <div class="round">
          <p class="name">会议通过率</p>
          <p class="num">{{ meeting_pass_ratio }}%</p>
        </div>
        <ul class="info">
          <li class="item meeting-card" @click="openDialog">
            <span class="name">会议</span>
            <span class="num">{{ meeting_num }}</span>
            <span class="unit">次</span>
          </li>
          <li class="item topic-card" @click="openDialog">
            <span class="name">议题</span>
            <span class="num">{{ topics_num }}</span>
            <span class="unit">次</span>
          </li>
        </ul>
      </div>
      <ul class="statistics">
        <li class="item">
          <span class="name">重大决策</span>
          <div class="count">
            <span class="num">{{ important_decision_num }}</span>
            <span class="unit">项</span>
          </div>
        </li>
        <li class="item">
          <span class="name">重要人事任免</span>
          <div class="count">
            <span class="num">{{ important_appoint_num }}</span>
            <span class="unit">项</span>
          </div>
        </li>
        <li class="item">
          <span class="name">重大项目安排</span>
          <div class="count">
            <span class="num">{{ important_project_num }}</span>
            <span class="unit">项</span>
          </div>
        </li>
        <li class="item">
          <span class="name">大额资金运作</span>
          <div class="count">
            <span class="num">{{ fund_num }}</span>
            <span class="unit">项</span>
          </div>
        </li>
      </ul>
    </div>

    <!--    弹窗 -->
    <SOAMeetingOverviewDialog ref="SOAMeetingOverviewDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.meeting-overview {
  height: 398px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 10px 16px 10px;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .meeting {
      height: 64px;
      padding: 8px;
      display: flex;
      background: url('@{img_url}meeting-overview-meeting_bg.png') no-repeat;
      background-size: 100% 100%;

      .item {
        flex: 1;
        height: 100%;
        display: flex;
        gap: 12px;
        padding: 0 16px;
        align-items: center;

        .icon {
          width: 32px;
          height: 48px;
        }

        .name {
          flex: 1;
          color: #B9E0FF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        .num {
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .dashboard {
      flex: 1;
      display: flex;
      gap: 24px;

      .round {
        width: 120px;
        height: 120px;
        background: url('@{img_url}meeting-overview-dash_bg.png') no-repeat;
        background-size: 100% 100%;
        text-align: center;

        .name {
          color: #DCECFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-top: 22px;
        }

        .num {
          color: #DEF0FF;
          text-align: center;
          text-shadow: 0px 4px 21px rgba(27, 126, 242, 0.64);
          font-size: 28px;
          font-style: normal;
          line-height: normal;
        }
      }

      .info {
        flex: 1;
        height: 108px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .item {
          flex: 1;
          display: flex;
          align-items: center;
          cursor: pointer;

          &.meeting-card {
            background: url('@{img_url}meeting-overview-meeting-card_bg.png') no-repeat;
            background-size: 100% 100%;
          }

          &.topic-card {
            background: url('@{img_url}meeting-overview-topic-card_bg.png') no-repeat;
            background-size: 100% 100%;
          }

          .name {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-left: 92px;
          }

          .num {
            flex: 1;
            leading-trim: both;
            text-edge: cap;
            font-family: DIN;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 100% */
            background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: right;
          }

          .unit {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-right: 18px;
          }
        }
      }
    }

    .statistics {
      height: 103px;
      background: url('@{img_url}meeting-overview-statistics_bg.png') no-repeat;
      background-size: 100% 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 每行 3 列，每列宽度相等 */
      grid-template-rows: repeat(2, 1fr); /* 总共 2 行，每行高度相等 */

      .item {
        display: flex;
        padding: 0 16px;
        align-items: center;
        justify-content: space-between;

        .name {
          color: #E5F6FF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }

        .count {
          height: 24px;
          display: flex;
          gap: 1px;
          align-items: baseline;

          .num {
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .unit {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
          }
        }
      }
    }
  }
}
</style>

