<script>
import to from "await-to-js"
import { onMounted, ref, reactive } from "vue"

import GlobalData from "./GlobalData"
import SOADataDashboardDialog from "./SOADataDashboardDialog.vue"
import { getSOADataDashboardData } from "./soa.api.service"

export default {
  name: 'SOADataDashboard',
  components: {
    SOADataDashboardDialog
  },
  setup() {
    const getFullYear = new Date().getFullYear()
    const time = ref(getFullYear.toString())
    const SOADataDashboardDialogRef = ref()

    // 营业收入
    const operating_income = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 利润总额
    const profit_total = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 净利润
    const net_profit = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 营业成本
    const operating_costs = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 上缴税费
    const pay_tax = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 负债总额
    const debt_total = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 所有者权益
    const owner_equity = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 资产负债率
    const debt_ratio = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    // 国有资产回报率
    const reward_ratio = reactive({
      val: 0,
      contrast_type: 0,
      contrast_val: 0,
      icon: '',
      unit:''
    })

    const openDialog = () => {
      SOADataDashboardDialogRef.value.open()
    }

    const init = async (_year) => {
      const year = (_year || new Date().getFullYear()).toString()
      const [ err, res ] = await to(getSOADataDashboardData(year))
      if (err) return
      console.log('营业数据 -----> ', res)
      const data = res.data

      // 营业收入
      operating_income.val = data.operating_income.val
      operating_income.contrast_val = data.operating_income.contrast_val
      operating_income.contrast_type = data.operating_income.contrast_type
      operating_income.unit = data.operating_income.unit
      operating_income.icon = data.operating_income.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 利润总额
      profit_total.val = data.profit_total.val
      profit_total.contrast_val = data.profit_total.contrast_val
      profit_total.contrast_type = data.profit_total.contrast_type
      profit_total.unit = data.profit_total.unit
      profit_total.icon = data.profit_total.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 净利润
      net_profit.val = data.net_profit.val
      net_profit.contrast_val = data.net_profit.contrast_val
      net_profit.contrast_type = data.net_profit.contrast_type
      net_profit.unit = data.net_profit.unit
      net_profit.icon = data.net_profit.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 营业成本
      operating_costs.val = data.operating_costs.val
      operating_costs.contrast_val = data.operating_costs.contrast_val
      operating_costs.contrast_type = data.operating_costs.contrast_type
      operating_costs.unit = data.operating_costs.unit
      operating_costs.icon = data.operating_costs.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 上缴税费
      pay_tax.val = data.pay_tax.val
      pay_tax.contrast_val = data.pay_tax.contrast_val
      pay_tax.contrast_type = data.pay_tax.contrast_type
      pay_tax.unit = data.pay_tax.unit
      pay_tax.icon = data.pay_tax.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 负债总额
      debt_total.val = data.debt_total.val
      debt_total.contrast_val = data.debt_total.contrast_val
      debt_total.contrast_type = data.debt_total.contrast_type
      debt_total.unit = data.debt_total.unit
      debt_total.icon = data.debt_total.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 所有者权益
      owner_equity.val = data.owner_equity.val
      owner_equity.contrast_val = data.owner_equity.contrast_val
      owner_equity.contrast_type = data.owner_equity.contrast_type
      owner_equity.unit = data.owner_equity.unit
      owner_equity.icon = data.owner_equity.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 资产负债率
      debt_ratio.val = data.debt_ratio.val
      debt_ratio.contrast_val = data.debt_ratio.contrast_val
      debt_ratio.contrast_type = data.debt_ratio.contrast_type
      debt_ratio.unit = data.debt_ratio.unit
      debt_ratio.icon = data.debt_ratio.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'

      // 国有资产回报率
      reward_ratio.val = data.reward_ratio.val
      reward_ratio.contrast_val = data.reward_ratio.contrast_val
      reward_ratio.contrast_type = data.reward_ratio.contrast_type
      reward_ratio.unit = data.reward_ratio.unit
      reward_ratio.icon = data.reward_ratio.contrast_type === 1 ? 'up_icon.png' : 'decline_icon.png'
    }

    onMounted(init)

    return {
      GlobalData,
      SOADataDashboardDialogRef,

      time,
      operating_income,
      profit_total,
      net_profit,
      operating_costs,
      pay_tax,
      debt_total,
      owner_equity,
      debt_ratio,
      reward_ratio,

      init,
      openDialog

    }
  }
}
</script>

<template>
  <div class="data-dashboard">
    <div class="header">
      <el-date-picker
          type="year"
          v-model="time"
          placeholder="请选择"
          value-format="YYYY"
          :clearable="false"
          @change="init"/>
    </div>

    <div class="content">
      <ul class="statistics" @click="openDialog">
        <li class="item">
          <img :src="GlobalData.getImgUrl('data-dashboard-statistics_icon.png')" class="icon" alt="">
          <div class="info">
            <p class="name">营业收入</p>
            <div class="price">
              <span class="num">{{ operating_income.val }}</span>
              <span class="unit">{{ operating_income.unit }}</span>
            </div>
            <div class="proportion">
              <p class="text">同比 {{ operating_income.contrast_val }}%</p>
              <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
            </div>
          </div>
        </li>
        <li class="item">
          <img :src="GlobalData.getImgUrl('data-dashboard-statistics_icon.png')" class="icon" alt="">
          <div class="info">
            <p class="name">利润总额</p>
            <div class="price">
              <span class="num">{{ profit_total.val }}</span>
              <span class="unit">{{ profit_total.unit }}</span>
            </div>
            <div class="proportion">
              <p class="text">同比 {{ profit_total.contrast_val }}%</p>
              <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
            </div>
          </div>
        </li>
        <li class="item">
          <img :src="GlobalData.getImgUrl('data-dashboard-statistics_icon.png')" class="icon" alt="">
          <div class="info">
            <p class="name">净利润</p>
            <div class="price">
              <span class="num">{{ net_profit.val }}</span>
              <span class="unit">{{ net_profit.unit }}</span>
            </div>
            <div class="proportion">
              <p class="text">同比 {{ net_profit.contrast_val }}%</p>
              <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
            </div>
          </div>
        </li>
      </ul>

      <ul class="details">
        <li class="item">
          <div class="price">
            <span class="num">{{ operating_costs.val }}</span>
            <span class="unit">{{ operating_costs.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ operating_costs.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">营业成本</p>
        </li>
        <li class="item">
          <div class="price">
            <span class="num">{{ pay_tax.val }}</span>
            <span class="unit">{{ pay_tax.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ pay_tax.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">上缴税费</p>
        </li>
        <li class="item">
          <div class="price">
            <span class="num">{{ debt_total.val }}</span>
            <span class="unit">{{ debt_total.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ debt_total.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">负债总额</p>
        </li>
        <li class="item">
          <div class="price">
            <span class="num">{{ owner_equity.val }}</span>
            <span class="unit">{{ owner_equity.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ owner_equity.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">所有者权益</p>
        </li>
        <li class="item">
          <div class="price">
            <span class="num">{{ debt_ratio.val }}</span>
            <span class="unit">{{ debt_ratio.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ debt_ratio.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">资产负债率</p>
        </li>
        <li class="item">
          <div class="price">
            <span class="num">{{ reward_ratio.val }}</span>
            <span class="unit">{{ reward_ratio.unit }}</span>
          </div>
          <div class="proportion">
            <p class="text">同比 {{ reward_ratio.contrast_val }}%</p>
            <img v-if="reward_ratio.icon" :src="GlobalData.getImgUrl(reward_ratio.icon)" class="icon" alt="">
          </div>
          <img :src="GlobalData.getImgUrl('data-dashboard-details_icon.png')" class="icon" alt="">
          <p class="name">国有资产回报率</p>
        </li>

      </ul>
    </div>

    <!--    弹窗-->
    <SOADataDashboardDialog ref="SOADataDashboardDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.data-dashboard {
  height: 398px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .header {
    display: flex;
    justify-content: flex-end;
  }

  & > .content {
    flex: 1;
    position: relative;

    .statistics {
      padding: 0 55px;
      height: 90px;
      display: flex;
      gap: 24px;
      cursor: pointer;
      background: url('@{img_url}data-dashboard_header_bg.png') no-repeat;
      background-size: 100% 104px;

      .item {
        flex: 1;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        gap: 4px;

        & > .icon {
          width: 96px;
          height: 58.359px;
        }

        .info {
          flex: 1;
          height: 68px;
          display: flex;
          flex-direction: column;
          gap: 2px;

          .name {
            height: 20px;
            line-height: 1;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            background: linear-gradient(180deg, #FFF 0%, #82C0FF 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .price {
            display: flex;
            align-items: baseline;
            gap: 4px;

            .num {
              color: #F9AE04;
              -webkit-text-stroke-color: #000;
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 100% */
              letter-spacing: 0.56px;
            }

            .unit {
              color: #F2F6FA;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 100% */
            }
          }

          .proportion {
            display: flex;
            align-items: center;
            height: 16px;

            .text {
              color: #F2F6FA;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }

            .icon {
              width: 16px;
              height: 16px;
              margin-right: auto;
            }
          }
        }
      }
    }

    .details {
      height: 230px;
      padding: 24px 22px;
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 每行 3 列，每列宽度相等 */
      grid-template-rows: repeat(2, 1fr); /* 总共 2 行，每行高度相等 */
      gap: 50px 24px; /* 列和行之间的间隔 */
      border: 1px solid rgba(37, 136, 229, 0.3);
      background: linear-gradient(180deg, #0E3368 0%, rgba(20, 59, 114, 0.00) 100%);
      box-shadow: 0px 8px 4.2px 0px rgba(6, 69, 155, 0.12) inset;

      .item {
        height: 90px;
        text-align: center; /* 文字居中 */
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > .icon {
          width: 78px;
          height: 36px;
        }

        .name {
          height: 20px;
          line-height: 1;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          background: linear-gradient(180deg, #FFF 0%, #82C0FF 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .price {
          height: 15px;
          display: flex;
          align-items: baseline;
          gap: 4px;
          margin-bottom: 8px;

          .num {
            text-align: right;
            leading-trim: both;
            text-edge: cap;
            text-shadow: 0px 4px 21px rgba(27, 126, 242, 0.64);
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .unit {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
          }
        }

        .proportion {
          display: flex;
          align-items: center;
          height: 16px;

          .text {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .icon {
            width: 16px;
            height: 16px;
            margin-right: auto;
          }
        }
      }
    }
  }

}
</style>
