/**
 * @name: economy.api.service
 * @author: yuxi
 * @date: 2024-12-07 12:43
 * @description：economy.api.service
 * @update: 2024-12-07 12:43
 */

import apiConfig from "./api.config"
import useIsProdEev from "./isProdEev.hooks"

/**
 * 宏观概览
 */
export const getEconomyDashboardData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/overview'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 产业链概览
 */
export const getEconomyIndustryDashboardData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/land_area_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 企业概览
 * @returns {Promise<any>|*}
 */
export const getEconomyEnterpriseDashboardData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_area_accumulate_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 重点企业乡镇分布
 * @returns {Promise<any>|*}
 */
export const getEconomyKeyPointsEnterpriseDistributedData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/diameter_group_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 重点企业经济发展
 * @returns {Promise<any>|*}
 */
export const getEconomyKeyPointsEnterpriseDevelopData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/bamboo_forest_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 规上工业能耗
 * @returns {Promise<any>|*}
 */
export const getEconomyIndustrialEnergyConsumptionData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_ecology'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 用电趋势
 * @returns {Promise<any>|*}
 */
export const getEconomyElectricityConsumptionTrendsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/woodland_protect_area_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 企业用电异动
 * @returns {Promise<any>|*}
 */
export const getEconomyAbnormalElectricityConsumptionData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_plant'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}
