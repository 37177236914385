<script>
import { onMounted, ref } from "vue"

export default {
  name: 'SwitchTabComponent',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(props, {emit}) {
    const active = ref(0)

    const handleClick = (key) => {
      active.value = key
      emit('tab-change', key)
    }

    onMounted(() => {
      if (props.list.length > 0) {
        active.value = props.list[0].key
      }
    })

    return {
      active,
      handleClick
    }
  }
}
</script>

<template>
  <ul class="switch-tab-component">
    <li
        v-for="item in list"
        :key="item.key"
        :class="['item', (active === item.key ? 'active' : '')]"
        @click.stop="handleClick(item.key)" class="item">{{ item.label }}
    </li>
  </ul>
</template>

<style scoped lang="less">
.switch-tab-component {
  height: 24px;
  display: flex;
  gap: 4px;

  .item {
    cursor: pointer;
    list-style: none;
    display: flex;
    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #B4C0CC;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #0F2839;
    border-top: 1px solid transparent;

    &:hover {
      color: #1FC6FF;
      border-top: 1px solid #1FC6FF;
      background: linear-gradient(180deg, rgba(31, 198, 255, 0.32) 0%, rgba(31, 198, 255, 0.00) 100%);
    }
  }

  .item.active {
    color: #1FC6FF;
    border-top: 1px solid #1FC6FF;
    background: linear-gradient(180deg, rgba(31, 198, 255, 0.32) 0%, rgba(31, 198, 255, 0.00) 100%);
  }

  .text {
    color: #FFF;
    height: 16px;
    line-height: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .bottom-corner-mark_icon {
    width: 6.062px;
    height: 4.5px;
  }
}
</style>