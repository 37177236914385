<script>
import { defineExpose, ref } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'SOAWaitingForDiscussionDialog',
  setup() {
    const dialogVisible = ref(false)


    const open = () => {
      dialogVisible.value = true
    }

    defineExpose({
      open
    })

    return {
      GlobalData,
      dialogVisible,

      open
    }
  }
}
</script>

<template>
  <div class="dialog__center soa-meeting-overview-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">议题详情</span>

      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>

    <div class="content meeting-details">
      <!-- 会议详情 -->
        <h2 class="title">关于“新青年·向未来2024主题会议</h2>
        <ul class="tables">
          <li class="tr">
            <div class="td title">会议类型</div>
            <div class="td value">文字文字文字文字</div>
            <div class="td title">汇报时长</div>
            <div class="td value">45分钟</div>
          </li>
          <li class="tr">
            <div class="td title">请示事项名称</div>
            <div class="td value">这是事项名称</div>
            <div class="td title">请示事项类型</div>
            <div class="td value">这是事项类型</div>
          </li>
          <li class="tr">
            <div class="td title">请示事项描述</div>
            <div class="td value">这是事项这是事项这是事项这是事项这是事项这是事项这是事项这是事项这是事项</div>
          </li>
          <li class="tr">
            <div class="td title">会议附件</div>
            <div class="td value">这是附件名称.jpg <a href=""> 查看</a></div>
          </li>
        </ul>
    </div>
  </div>
</template>

<style scoped lang="less">
.soa-meeting-overview-dialog {
  &.open {
    width: 1172px;
    height: auto;
    min-height: 312px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }


  .title {
    color: #E5F4FF;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 100% */
  }

  .tables {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid #21588A;

    .tr {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 48px;
      border-bottom: 1px solid #21588A;

      &:last-child {
        border-bottom: 0;
      }

      .td {
        display: flex;
        padding: 12px 8px;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .title {
        width: 148px;
        text-align: center;
        background: rgba(37, 166, 255, 0.12);
        border-right: 1px solid #21588A;

      }

      .value {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>