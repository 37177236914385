<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw, reactive } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getSOAAssetClassificationData } from "./soa.api.service"

export default {
  name: 'SOAAssetClassification',
  components: {
    CardTitle,
    CardLeftTopDot
  },
  setup() {
    const currentTab = ref('valuation')
    const tabList = ref([
      {
        label: '账面价值',
        key: 'valuation'
      },
      {
        label: '面积',
        key: 'area'
      }
    ])
    const areaEchartsRef = ref(null)
    const areaEchartsSelf = ref(null)

    const valuationEchartsRef = ref(null)
    const valuationEchartsSelf = ref(null)

    const areaEchartsData = reactive({
      list: [],
      total: '',
      unit: ''
    })
    const valuationEchartsData = reactive({
      list: [],
      total: '',
      unit: ''
    })

    const getTypeEchartsData = (config, label) => {
      const processedData = config.list.map(item => ({
        ...item,
        value: item.val.toString(),  // 将 val 映射为 value
        name: item.name // 将 label 映射为 name
      }))

      return {
        // backgroundColor: '#364686',
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        color: [ '#17F1D3', '#FF782A', '#4977FF', '#6DFF71' ],
        title: [
          {
            text: config.total, // x: '25%',
            x: '17%',
            top: '35%',
            textAlign: 'center',
            textStyle: {
              color: '#fff',
              fontSize: 20,
              fontWeight: '600',
              align: 'center',
              width: '150px',
              height: '150px'
            }
          },
          {
            text: label,
            x: '17%',
            top: '54%',
            textAlign: 'center',
            textStyle: {
              fontSize: 14,
              color: '#ffffff',
              align: 'center',
              width: '150px',
              height: '150px'
            }
          }
        ],
        legend: {
          orient: 'vertical',
          top: 'middle',
          left: '34%',
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 10,
          icon: 'circle',
          textStyle: {
            fontSize: 14,
            color: '#FFFFFF',
            lineHeight: 25
          },
          pageTextStyle: {
            color: '#FFFFFF',
            fontSize: 12
          },
          formatter: (name) => {
            const data_ = processedData.find(i => i.name === name)
            return `${ name }  ${ data_.value }${ config.unit }`
          }
        },
        polar: {
          radius: [ '40%', '50%' ],
          center: [ '50%', '50%' ]
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'pie',
            radius: [ '65%', '75%' ],
            center: [ '18%', '50%' ],
            data: processedData,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderColor: '#364684',
                borderWidth: 0,
                borderRadius: 100
                // shadowColor: 'rgba(0,47,66, 0.5)',
                // shadowBlur: 10
              }
            },
            tooltip: {
              position: [ '50%', '50%' ]
            },
            label: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: '58%',
            hoverAnimation: false,
            center: [ '18%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]), // borderWidth: 1,
                // borderColor: '',
                shadowBlur: 10
                // shadowColor: 'rgba(55,70,130, 1)'
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
    }

    const initAreaEcharts = () => {
      if (toRaw(areaEchartsSelf.value)) {
        areaEchartsSelf.value.dispose()
      }

      areaEchartsSelf.value = markRaw(window.echarts.init(areaEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      areaEchartsSelf.value.setOption(getTypeEchartsData(toRaw(areaEchartsData), '资产总面积'))
      setTimeout(function () {
        window.onresize = function () {
          areaEchartsSelf.value.resize()
        }
      }, 200)
    }

    const initValuationEcharts = () => {
      if (toRaw(valuationEchartsSelf.value)) {
        valuationEchartsSelf.value.dispose()
      }

      valuationEchartsSelf.value = markRaw(window.echarts.init(valuationEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      valuationEchartsSelf.value.setOption(getTypeEchartsData(toRaw(valuationEchartsData), '资产总金额'))
      setTimeout(function () {
        window.onresize = function () {
          valuationEchartsSelf.value.resize()
        }
      }, 200)
    }

    const handleTabChange = (key) => {
      currentTab.value = key
      nextTick(() => {
        key === 'area' ? initAreaEcharts() : initValuationEcharts()
      })
    }

    const init = async () => {
      const [ err, res ] = await to(getSOAAssetClassificationData())
      if (err) return
      console.log('资产分类 -----> ', res)

      const {
        amount_data,
        area_data
      } = res.data

      areaEchartsData.list = area_data?.list || []
      areaEchartsData.total = area_data?.total || 0
      areaEchartsData.unit = area_data?.unit || ''

      valuationEchartsData.list = amount_data?.list || []
      valuationEchartsData.total = amount_data?.total || 0
      valuationEchartsData.unit = amount_data?.unit || ''
      handleTabChange(currentTab.value)
    }

    onMounted(init)

    return {
      GlobalData,
      currentTab,
      areaEchartsRef,
      areaEchartsSelf,
      valuationEchartsRef,
      valuationEchartsSelf,

      tabList,
      handleTabChange
    }
  }
}
</script>
<template>
  <div class="asset-classification">
    <CardTitle title="资产分类" :tab-list="tabList" @tab-change="handleTabChange"/>

    <div class="content">
      <CardLeftTopDot/>

      <!--      面积-->
      <div v-if="currentTab === 'area'" class="chart" ref="areaEchartsRef"></div>

      <!--      账面价值-->
      <div v-else class="chart" ref="valuationEchartsRef"></div>
    </div>
  </div>
</template>

<style scoped lang="less">
.asset-classification {
  height: 230px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
