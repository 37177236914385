<script>
import { defineExpose, markRaw, nextTick, reactive, ref, toRaw } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'ForestAreaStatisticsDialog',
  setup() {
    const dialogVisible = ref(false)
    const dialogEchartsRef = ref(null)
    const dialogEchartsSelf = ref(null)
    const echartsData = reactive({
      list: [],
      total: ''
    })

    const getTypeEchartsData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 0,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 15
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '5%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        series: [
          {
            name: '国有',
            data: data.map(item => item.value),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#349EFF'
                }, {
                  offset: 1,
                  color: '#1890FF99'
                } ])
              }
            },
            label: {
              show: false
            }
          },
          {
            name: '个人',
            data: data.map(item => item.value1),
            type: 'bar',
            // barMaxWidth: 'auto',
            barWidth: 7,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#F5E74F'
                }, {
                  offset: 1,
                  color: '#FCE9AE'
                } ])
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    }

    const initEcharts = async () => {
      console.log(dialogEchartsSelf, echartsData)
      echartsData.value = [
        {
          "name": "峨边镇",
          "value": 90,
          "value1": 44
        },
        {
          "name": "大堡镇",
          "value": 40,
          "value1": 77
        },
        {
          "name": "黑竹沟镇",
          "value": 56,
          "value1": 90
        },
        {
          "name": "红旗镇",
          "value": 45,
          "value1": 90
        },
        {
          "name": "金岩乡",
          "value": 30,
          "value1": 90
        },
        {
          "name": "勒乌乡",
          "value": 22,
          "value1": 90
        },
        {
          "name": "毛坪镇",
          "value": 76,
          "value1": 90
        },
        {
          "name": "平等乡",
          "value": 40,
          "value1": 11
        },
        {
          "name": "沙坪镇",
          "value": 102,
          "value1": 90
        },
        {
          "name": "612林场",
          "value": 10,
          "value1": 22
        },
        {
          "name": "613林场",
          "value": 30,
          "value1": 34
        }
      ]
      await nextTick()
      console.log('nextTick')
      if (toRaw(dialogEchartsSelf.value)) {
        dialogEchartsSelf.value.dispose()
      }

      dialogEchartsSelf.value = markRaw(window.echarts.init(dialogEchartsRef.value, null, {
        renderer: 'canvas'
      }))

      dialogEchartsSelf.value.setOption(getTypeEchartsData(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          dialogEchartsSelf.value.resize()
        }
      }, 200)
    }

    /**
     * 打开弹窗
     */
    const open = () => {
      dialogVisible.value = true
      setTimeout(initEcharts, 550)
    }

    defineExpose({
      open
    })

    return {
      GlobalData,
      dialogEchartsRef,

      dialogVisible,
      open
    }
  }
}
</script>

<template>
  <div class="dialog__center forest-area-statistics-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">各乡镇各类森林面积、蓄积统计</span>
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>
    <div class="content">
      <div class="card-info">
        <div class="card-header">
          <i class="card-dot"></i>
          <span class="card-title">数据总览</span>
        </div>
        <ul class="info-list">
          <li class="item">
            <span class="name">国有总面积</span>
            <div class="value">
              <span class="label">238246.42</span>
              <span class="unit">hm²</span>
            </div>
          </li>
          <li class="item">
            <span class="name">个人总面积</span>
            <div class="value">
              <span class="label">238246.42</span>
              <span class="unit">hm²</span>
            </div>
          </li>
          <li class="item">
            <span class="name">国有总蓄积</span>
            <div class="value">
              <span class="label">238246.42</span>
              <span class="unit">hm²</span>
            </div>
          </li>
          <li class="item">
            <span class="name">个人总蓄积</span>
            <div class="value">
              <span class="label">238246.42</span>
              <span class="unit">hm²</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="card-echarts">
        <div class="card-header">
          <i class="card-dot"></i>
          <span class="card-title">各乡镇土地统计</span>
        </div>
        <div class="chart" ref="dialogEchartsRef"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.forest-area-statistics-dialog {
  &.open {
    width: 672px;
    height: 480px;
  }

  .content {
    padding: 12px;
    display: flex;
    gap: 20px;
    flex-direction: column;

    .card-header {
      height: 32px;
      display: flex;
      padding: 0 5px;
      gap: 5px;
      align-items: center;
      background-image: url('@{img_url}forest-area-statistics-dialog-title_bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .card-dot {
        height: 16px;
        width: 2.281px;
        background: linear-gradient(90deg, #62B6FF -0.68%, #1C8AEA 38.3%, #36A1FF 91.03%);
        box-shadow: 0px 0px 6px 0px rgba(233, 245, 255, 0.43) inset, 1.634px 2.516px 0px 0px rgba(30, 61, 88, 0.33);
      }

      .card-title {
        text-shadow: 0px 0px 12px #1182E4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(0deg, #B1DBFF 20.83%, #EDF7FF 87.5%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .card-info {
      height: 102px;
      display: flex;
      gap: 8px;
      flex-direction: column;

      .info-list {
        flex: 1;
        display: flex;
        gap: 16px;

        .item {
          flex: 1;
          padding: 8px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          background-image: url('@{img_url}forest-area-statistics-dialog-card_bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .name {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .value {
            height: 22px;
            display: flex;
            align-items: baseline;

            .label {
              text-align: center;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px; /* 100% */
              background: linear-gradient(180deg, #FFF 0%, #86C3FF 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

            }

            .unit {
              color: #E5F4FF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }
      }
    }

    .card-echarts {
      flex: 1;
      display: flex;
      gap: 8px;
      flex-direction: column;

      .chart {
        flex: 1;
      }
    }
  }
}
</style>