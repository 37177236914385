<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyEnterpriseDashboardData } from "./economy.api.service"

export default {
  name: 'EconomyEnterpriseDashboard',
  components: {
    CardTitle
  },
  setup() {
    const dataList = ref([ {
      label: 'test',
      value: 1
    } ])
    const dataObj = reactive([])

    const echartsData = ref([
      {
        "name": "五渡镇",
        "value": "2007.00"
      },
      {
        "name": "黑竹沟",
        "value": "5385.00"
      },
      {
        "name": "宜坪乡",
        "value": "2717.00"
      },
      {
        "name": "大堡镇",
        "value": "3421.00"
      },
      {
        "name": "毛坪镇",
        "value": "173.00"
      },
      {
        "name": "沙坪镇",
        "value": "321.00"
      }
    ])
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const getEchartsOption = (data, colors) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 0,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '1%',   // 左侧距离
          right: '1%',  // 右侧距离
          top: '8%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#B4C0CC',
            fontSize: 14,
            interval: 0
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [
          {
            name: '就业人数',
            data: data.map(item => item.value),
            type: 'bar', // barMaxWidth: 'auto',
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: colors[0]
                }, {
                  offset: 1,
                  color: colors[1]
                } ])
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value), [ '#30A5FF', '#42B0FF' ]))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyEnterpriseDashboardData({
        type: value
      }))
      if (err) return
      console.log('企业概览 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      dataList,
      dataObj,

      init
    }
  }
}
</script>

<template>
  <div class="economy-enterprise-dashboard">
    <CardTitle title="企业概览" :select-list="dataList" @select-change="init"/>
    <div class="content">
      <ul class="head">
        <li class="item">
          <img :src="GlobalData.getImgUrl('economy-dashboard-nav_icon.png')" class="icon" alt="icon">
          <div class="info-wrap">
            <span class="label">企业总数</span>
            <div class="value-wrap">
              <span class="count">1,560</span>
              <span class="unit">家</span>
            </div>
          </div>
        </li>
        <li class="item">
          <img :src="GlobalData.getImgUrl('economy-dashboard-nav_icon.png')" class="icon" alt="icon">
          <div class="info-wrap">
            <span class="label">就业人数</span>
            <div class="value-wrap">
              <span class="count">25,450</span>
              <span class="unit">人</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="chart-wrap">
        <h1 class="subtitle">
          <i class="dot"></i>
          <span class="label">用工情况</span>
        </h1>
        <div class="chart" ref="echartsRef"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.economy-enterprise-dashboard {
  height: 251px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    position: relative;
    padding: 10px 12px;
    flex-direction: column;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);
    gap: 8px;

    .head {
      width: 468px;
      height: 48px;
      background: url('@{img_url}economy-enterprise-dashboard-head_bg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;

      .item {
        flex: 1;
        padding: 0 16px;
        display: flex;
        gap: 12px;
        align-items: center;

        .icon {
          width: 24px;
          height: 36px;
        }

        .info-wrap {
          flex: 1;
          display: flex;

          .label {
            flex: 1;
            color: #B9E0FF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }

          .value-wrap {
            display: flex;
            align-items: baseline;
            height: 22px;
            gap: 2px;

            .count {
              text-align: center;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              line-height: 22px;
            }

            .unit {
              color: #E5F4FF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }


      }
    }

    .chart-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;

      .subtitle {
        padding: 0 4px;
        display: flex;
        align-items: center;
        height: 24px;
        gap: 4px;

        .label {
          flex: 1;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          background: linear-gradient(0deg, #B1DDFF 20.83%, #EDF7FF 87.5%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .dot {
          width: 4px;
          height: 4px;
          background: #3DFFFF;
        }
      }

      .chart {
        flex: 1;
      }
    }
  }
}
</style>