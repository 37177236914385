<script>
import to from 'await-to-js'
import { markRaw, onMounted, ref, reactive, toRaw, nextTick } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getSOAEnterpriseDashboardData } from "./soa.api.service"

export default {
  name: 'SOAEnterpriseDashboard',
  components: {
    CardTitle,
    CardLeftTopDot
  },
  setup() {
    const dialogVisible = ref(false)
    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const data = ref([])
    const searchForm = reactive({
      keywords: '',
      type: ''
    })

    const init = async () => {
      const [ err, res ] = await to(getSOAEnterpriseDashboardData())
      if (err) return
      console.log('企业全景 -----> ', res)

      const {
        wholly_owned_num,
        holding_absolute,
        holding_actual,
        holding_join
      } = res.data

      data.value = [
        {
          name: '国有独资企业',
          value: (holding_actual.toString() || 0).toString(),
          colorName: "green"
        },
        {
          name: '国有全资企业',
          value: (wholly_owned_num || 0).toString(),
          colorName: "organ"
        },
        {
          name: '国有参股企业',
          value: (holding_absolute || 0).toString(),
          colorName: "light-blue"
        },
        {
          name: '国有控股企业',
          value: (holding_join || 0).toString(),
          colorName: "blue"
        }
      ]

      await nextTick()
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption())
      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const getEchartsOption = () => {
      let subtext = 0
      if (data.value && data.value.length > 0) {
        data.value.forEach(item => {
          subtext = subtext + Number(item.value)
        })
      }

      return {
        // backgroundColor: '#364686',
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        color: [ '#17FFC7', '#FF963D', '#87CEFF', '#F7E061', '#3877F2' ],
        title: [ {
          text: subtext, // x: '25%',
          x: '47%',
          top: '35%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 28,
            fontWeight: '600',
            align: 'center',
            width: '150px',
            height: '150px'
          }
        }, {
          text: '企业总数',
          x: '47%',
          top: '54%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14,
            color: '#ffffff',
            align: 'center',
            width: '150px',
            height: '150px'
          }
        } ],
        polar: {
          radius: [ '40%', '50%' ],
          center: [ '50%', '50%' ]
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'pie',
            radius: [ '70%', '80%' ],
            center: [ '50%', '50%' ],
            data: toRaw(data.value),
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderColor: '#364684',
                borderWidth: 0,
                borderRadius: 100
              }
            },
            tooltip: {
              position: [ '50%', '50%' ]
            },
            label: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            hoverAnimation: false,
            radius: '60%',
            center: [ '50%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]),
                shadowBlur: 10
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
    }

    const resetForm = () => {
      searchForm.keywords = ''
      searchForm.type = ''
    }

    const search = () => {
      alert('search')
    }

    onMounted(init)

    return {
      GlobalData,
      echartsRef,
      dialogVisible,
      data,
      searchForm,

      search,
      resetForm
    }
  }
}
</script>

<template>
  <div class="enterprise-dashboard">
    <CardTitle title="企业全景"/>

    <div class="content">
      <CardLeftTopDot/>

      <div class="chart" ref="echartsRef"></div>

      <ul class="data-info">
        <li :class="['item', item.colorName]" @click="dialogVisible = true" v-for="item in data" :key="item.label">
          <div class="name">
            <i class="dot green"></i>
            <span class="text">{{ item.name }}</span>
          </div>
          <div class="count">
            <span class="num">{{ item.value }}</span>
            <span class="unit">家</span>
          </div>
        </li>
      </ul>
    </div>

    <!--    弹窗-->
    <div class="dialog__center" :class="{open: dialogVisible}">
      <div class="header">
        <i class="dot"></i>
        <span class="title">企业营收排名</span>
        <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
             alt="close">
      </div>
      <div class="content">
        <div class="head">
          <div class="form-area">
            <input class="common-input" type="text" v-model="searchForm.keywords" placeholder="请输入企业名称进行查找"/>
            <el-select v-model="searchForm.type" placeholder="请选择">
              <el-option value="1212s" label="全部">全部</el-option>
              <el-option value="1212" label="国有实际控股">国有实际控股</el-option>
            </el-select>
          </div>
          <div class="op-area">
            <button class="btn" @click="search">查询</button>
            <button class="btn" @click="resetForm">重置</button>
          </div>
        </div>
        <div class="list-wrap">
          <ul class="list">
            <li class="item" v-for="i in 20" :key="i+'-item-enterprise-dashboard'">
              <div class="head">
                <span class="name">峨边彝族自治县国有资产经营管理有限公司</span>
                <span class="tag">存续</span>
              </div>

              <ul class="info">
                <li class="item">
                  <span class="label">企业类别：</span>
                  <span class="value mr-48">国有全资企业</span>
                  <span class="label">注册资本：</span>
                  <span class="value">100000万元</span>
                </li>
                <li class="item">
                  <span class="label">注册日期：</span>
                  <span class="value mr-48">2007-07-12</span>
                  <span class="label">统一社会信用代码：</span>
                  <span class="value">91511132662791219A</span>
                </li>
                <li class="item">
                  <span class="label">注册地址：</span>
                  <span class="value">四川省 -乐山市-峨边彝族自治县</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.enterprise-dashboard {
  height: 196px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .chart {
      flex: 1;
      height: 100%;
    }

    .data-info {
      width: 304px;
      height: 135px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      margin-right: 20px;

      .item {
        flex: 1;
        padding: 0 12px;
        cursor: pointer;
        list-style: none;
        background: linear-gradient(180deg, rgba(39, 124, 255, 0.12) 0%, rgba(39, 124, 255, 0.12) 112.5%);
        stroke-width: 1px;
        stroke: rgba(2, 181, 255, 0.48);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.green {
          .dot {
            background: #6CFF6F;
          }

          .num {
            color: #6CFF6F;
          }
        }

        &.organ {
          .dot {
            background: #FF8E50;
          }

          .num {
            color: #FF8E50;
          }
        }

        &.blue {
          .dot {
            background: #0886D8;
          }

          .num {
            color: #0886D8;
          }
        }

        &.light-blue {
          .dot {
            background: #87CEFF;
          }

          .num {
            color: #87CEFF;
          }
        }


        .name {
          height: 16px;
          display: flex;
          align-items: center;
          gap: 4px;

          .dot {
            width: 8px;
            height: 8px;
          }

          .text {
            flex: 1;
            color: #ABDAFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .count {
          height: 22px;
          display: flex;
          gap: 1px;
          align-items: baseline;

          .num {
            line-height: 22px;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
          }

          .unit {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }

  .dialog__center {
    &.open {
      width: 1172px;
      height: 646px;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;

      .list-wrap {
        flex: 1;
        overflow-y: auto; /* 允许内容垂直滚动 */
        min-height: 0; /* 确保在弹性布局中可伸缩 */
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto; /* 允许内容垂直滚动 */
        min-height: 0; /* 确保在弹性布局中可伸缩 */

        & > .item {
          height: 122px;
          padding: 12px;
          border-radius: 2px;
          background: url('@{img_url}enterprise-dashboard-dialog-tr_bg.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: space-between;

          .head {
            height: 22px;
            display: flex;
            gap: 8px;
            align-items: center;

            .name {
              height: 22px;
              line-height: 22px;
              color: #E5F4FF;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
            }

            .tag {
              height: 20px;
              line-height: 20px;
              text-align: center;
              color: #76E5F8;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              width: 48px;
              border-radius: 3px;
              border: 1px solid rgba(118, 229, 248, 0.24);
              background: rgba(0, 68, 79, 0.60);
              box-shadow: 0px 0px 6px 0px rgba(82, 211, 232, 0.60) inset;
            }
          }

          .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item {
              list-style: none;
              display: flex;
              align-items: center;

              .label,
              .value {
                width: 148px;
                height: 16px;
                line-height: 16px;
                margin-right: 8px;
                color: #9DD4FF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
              }

              .value {
                flex: 1;
                color: #FFF;
                margin-right: 0;
              }

              .mr-48 {
                margin-right: 48px
              }
            }
          }
        }
      }
    }
  }
}
</style>
