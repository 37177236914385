<script>
import ForestDashboard from "./ForestDashboard"
import ForestLandAreaStatistics from "./ForestLandAreaStatistics"
import ForestAreaStatistics from "./ForestAreaStatistics"
import ForestTypeAreaStatistics from "./ForestTypeAreaStatistics"
import ForestTimberAgeAndSizeStats from "./ForestTimberAgeAndSizeStats"

export default {
  name: 'ForestLayoutLeft',
  components: {
    // 林业总览
    ForestDashboard,

    // 各类土地面积统计
    ForestLandAreaStatistics,

    // 各类森林面积、蓄积统计
    ForestAreaStatistics,

    // 林种面积蓄积统计
    ForestTypeAreaStatistics,

    // 用材林龄级径级组统计
    ForestTimberAgeAndSizeStats
  }
}
</script>

<template>
  <div class="forest-layout-left">
    <!--    林业总览-->
    <ForestDashboard/>

    <!--    各类土地面积统计-->
    <ForestLandAreaStatistics/>

    <!--    各类森林面积、蓄积统计-->
    <ForestAreaStatistics/>

    <!--    林种面积蓄积统计-->
    <ForestTypeAreaStatistics/>

    <!--    用材林龄级径级组统计-->
    <ForestTimberAgeAndSizeStats/>
  </div>
</template>

<style scoped lang="less">
.forest-layout-left {
  width: 488px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 1;
}
</style>