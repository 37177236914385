<script>
import { defineExpose, markRaw, nextTick, ref, toRaw } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'ForestTypeAreaStatisticsAreaDialog',

  setup() {
    const dialogVisible = ref(false)

    const echartsRef = ref(null)
    const echartsSelf = ref(null)
    const echartsData = ref([])

    const getEchartsData = (data) => {
      return {
        legend: {
          selectedMode: true,
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: 'axis', // 显示轴线相关提示
          axisPointer: {
            type: 'shadow' // 鼠标悬停时的指示类型
          }
        },
        grid: {
          left: 40,
          right: 0,
          top: 60,
          bottom: 30
        },

        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#c0c3cd', // 数值标尺颜色
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#3D647F', // 分隔线颜色
              type: 'dashed'
            }
          },
          axisLine: {
            show: true, // 左侧轴线显示
            lineStyle: {
              color: '#c0c3cd'
            }
          },
          axisTick: {
            show: true
          }
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 12
          },
          type: 'category',
          data: [ '峨边镇', '大堡镇', '黑竹沟镇', '红旗镇', '金岩乡', '勒乌乡', '毛坪镇', '平等乡', '沙坪镇', '612林场', '613林场' ]
        },
        series: data
      }
    }

    const initEcharts = async () => {

      const response = {
        x_data: [ '峨边镇', '大堡镇', '黑竹沟镇', '红旗镇', '金岩乡', '勒乌乡', '毛坪镇', '平等乡', '沙坪镇', '612林场', '613林场' ],
        y_label_data:[
          '药用林',
          '林化工业原料林',
          '食用原料林',
          '果树林',
          '薪炭林',
          '一般用林',
          '自然保护林',
          '风景材',
          '水士保持林',
          '其他防护林',
          '其他经济林'
        ],
        // y_value_data长度和坐标与x_data保持一致
        y_value_data:[
            // y_value_data[index] 长度和坐标与y_label_data保持一致
          [ 100, 302, 301, 334, 390, 330, 320, 100, 302, 301, 190 ],
          [ 150, 212, 201, 154, 190, 330, 410, 320, 100, 302, 301, 190 ],
          [ 820, 832, 901, 934, 1290, 1330, 1320, 320, 100, 302, 301, 190 ],
          [ 100, 302, 301, 334, 390, 330, 320, 320, 100, 302, 301, 190 ],
          [ 220, 182, 191, 234, 290, 330, 310, 320, 100, 302, 301, 190 ],
          [ 320, 132, 101, 134, 90, 230, 210, 320, 100, 302, 301, 190 ],
          [ 820, 832, 901, 934, 1290, 1330, 1320, 320, 100, 302, 301, 190 ],
          [ 320, 132, 101, 134, 90, 230, 210, 320, 100, 302, 301, 190 ],
          [ 220, 182, 191, 234, 290, 330, 310, 320, 100, 302, 301, 190 ],
          [ 150, 212, 201, 154, 190, 330, 410, 320, 100, 302, 301, 190 ],
          [ 220, 182, 191, 234, 290, 330, 310, 320, 100, 302, 301, 190 ]
        ]
      }


      const totalData = []
      for (let i = 0; i < response.y_value_data[0].length; ++i) {
        let sum = 0
        for (let j = 0; j < response.y_value_data.length; ++j) {
          sum += response.y_value_data[j][i]
        }
        totalData.push(sum)
      }

      echartsData.value = response.y_label_data.map((name, sid) => {
        return {
          name,
          type: 'bar',
          stack: 'total',
          barWidth: 12,
          label: {
            show: false,
            formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
          },
          data: response.y_value_data[sid]
        }
      })


      await nextTick()

      // 各类碳储量分布
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }
      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsData(toRaw(echartsData.value)))


      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }
    /**
     * 打开弹窗
     */
    const open = () => {
      dialogVisible.value = true
      setTimeout(initEcharts, 550)
    }

    defineExpose({
      open
    })

    return {
      GlobalData,

      dialogVisible,
      echartsRef,

      open
    }
  }
}
</script>

<template>
  <div class="dialog__center forest-type-area-statistics-area-dialog" :class="{open: dialogVisible}">

    <div class="header">
      <i class="dot"></i>
      <span class="title">各乡镇林种面积统计</span>
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>
    <div class="content" ref="echartsRef"></div>
  </div>
</template>

<style scoped lang="less">
.forest-type-area-statistics-area-dialog {
  background: linear-gradient(0deg, rgba(1, 144, 255, 0.08) 0%, rgba(1, 144, 255, 0.08) 100%), rgba(20, 36, 51, 0.90);

  &.open {
    width: 672px;
    height: 362px;
    overflow: visible;
  }

  .content {
    padding: 10px;
  }
}
</style>