/**
 * @name: pageType
 * @author: yuxi
 * @date: 2024-11-10 17:01
 * @description：pageType
 * @update: 2024-11-10 17:01
 */

export default {

    // 经济概览
    economicOverview: 'economicOverview',

    // 国资监管
    SOASupervision: 'SOASupervision',

    // 林业经济
    forestryEconomy: 'forestryEconomy',

    // 重大项目
    majorProjects: 'majorProjects'
}