/**
 * @name: api.config
 * @author: yuxi
 * @date: 2024-11-23 17:01
 * @description：api.congfig
 * @update: 2024-11-23 17:01
 */

export default  {
    baseURL: 'https://ebian.yhsofts.cn',
    token: 'ff9e60d9ff063f7bd835a77542145e20'
}