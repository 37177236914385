<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw, watch } from "vue"

import CardTitle from "./CardTitleComponent.vue"
import GlobalData from "./GlobalData"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import SwitchTabComponent from "./SwitchTabComponent.vue"
import ForestTimberAgeAndSizeStatsDialog from "./ForestTimberAgeAndSizeStatsDialog.vue"
import { getForestTimberAgeAndSizeStatsData } from "./forest.api.service"

export default {
  name: 'ForestTimberAgeAndSizeStats',
  components: {
    CardLeftTopDot,
    CardTitle,
    SwitchTabComponent,
    ForestTimberAgeAndSizeStatsDialog
  },
  setup() {
    const forestTimberAgeAndSizeStatsDialogRef = ref()

    // 按龄级统计
    const currentAgeTab = ref('area')
    const ageTabList = ref([
      {
        label: '面积',
        key: 'area'
      },
      {
        label: '蓄积',
        key: 'accumulation'
      }
    ])

    // 面积
    const ageAreaData = ref([])
    const ageAreaEchartsRef = ref(null)
    const ageAreaEchartsSelf = ref(null)
    // 蓄积
    const ageAccumulationData = ref([])
    const ageAccumulationEchartsRef = ref(null)
    const ageAccumulationEchartsSelf = ref(null)


    // 按径级组统计
    const currentDiameterTab = ref('area')
    const diameterTabList = ref([
      {
        label: '面积',
        key: 'area'
      },
      {
        label: '蓄积',
        key: 'accumulation'
      }
    ])
    // 面积
    const diameterAreaData = ref([])
    const diameterAreaEchartsRef = ref(null)
    const diameterAreaEchartsSelf = ref(null)
    // 蓄积
    const diameterAccumulationData = ref([])
    const diameterAccumulationEchartsRef = ref(null)
    const diameterAccumulationEchartsSelf = ref(null)

    const ageTabChange = (key) => {
      currentAgeTab.value = key
      nextTick(initAgeEcharts)
    }

    const diameterTabChange = (key) => {
      currentDiameterTab.value = key
      nextTick(initDiameterEcharts)
    }

    const initAgeEcharts = () => {
      console.log('initAgeEcharts')
      // 按龄级统计 - 面积
      if (currentAgeTab.value === 'area') {
        if (toRaw(ageAreaEchartsSelf.value)) {
          ageAreaEchartsSelf.value.dispose()
        }

        ageAreaEchartsSelf.value = markRaw(window.echarts.init(ageAreaEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        ageAreaEchartsSelf.value.setOption(getTypeEchartsData(toRaw(ageAreaData.value), [ '#27E5A1', '#36E3DE' ]))
      }

      // 按龄级统计 - 蓄积
      if (currentAgeTab.value === 'accumulation') {
        if (toRaw(ageAccumulationEchartsSelf.value)) {
          ageAccumulationEchartsSelf.value.dispose()
        }

        ageAccumulationEchartsSelf.value = markRaw(window.echarts.init(ageAccumulationEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        ageAccumulationEchartsSelf.value.setOption(getTypeEchartsData(toRaw(ageAccumulationData.value), [ '#27E5A1', '#36E3DE' ]))
      }

      setTimeout(function () {
        window.onresize = function () {
          toRaw(ageAreaEchartsSelf.value) && ageAreaEchartsSelf.value.resize()
          toRaw(ageAccumulationEchartsSelf.value) && ageAccumulationEchartsSelf.value.resize()
        }
      }, 200)
    }

    const initDiameterEcharts = () => {
      // 按径级组统计 - 面积
      if (currentDiameterTab.value === 'area') {
        if (toRaw(diameterAreaEchartsSelf.value)) {
          diameterAreaEchartsSelf.value.dispose()
        }

        diameterAreaEchartsSelf.value = markRaw(window.echarts.init(diameterAreaEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        diameterAreaEchartsSelf.value.setOption(getTypeEchartsData(toRaw(diameterAreaData.value), [ '#30A5FF', '#42B0FF' ]))
      }

      // 按径级组统计 - 蓄积
      if (currentDiameterTab.value === 'accumulation') {
        if (toRaw(diameterAccumulationEchartsSelf.value)) {
          diameterAccumulationEchartsSelf.value.dispose()
        }

        diameterAccumulationEchartsSelf.value = markRaw(window.echarts.init(diameterAccumulationEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        diameterAccumulationEchartsSelf.value.setOption(getTypeEchartsData(toRaw(diameterAccumulationData.value), [ '#30A5FF', '#42B0FF' ]))
      }

      setTimeout(function () {
        window.onresize = function () {
          toRaw(diameterAreaEchartsSelf.value) && diameterAreaEchartsSelf.value.resize()
          toRaw(diameterAccumulationEchartsSelf.value) && diameterAccumulationEchartsSelf.value.resize()
        }
      }, 200)
    }

    const getTypeEchartsData = (data, colors) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 12,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '10%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#B4C0CC',
            fontSize: 14,
            interval: 0
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [ {
          data: data.map(item => item.value),
          type: 'bar', // barMaxWidth: 'auto',
          barWidth: 15,
          itemStyle: {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                offset: 0,
                color: colors[0]
              }, {
                offset: 1,
                color: colors[1]
              } ])
            }
          },
          label: {
            show: false
          }
        } ]
      }
    }

    const init = async () => {
      const [ err, res ] = await to(getForestTimberAgeAndSizeStatsData())
      if (err) return
      console.log('用材林龄级径级组统计 -----> ', res)

      const {
        age,
        diameter
      } = res.data

      // 按龄级统计 - 面积
      ageAreaData.value = (Array.isArray(age.x_data) ? age.x_data : []).map((item, i) => {
        return {
          name: item,
          value: age.y_data.area[i]
        }
      })
      // 按龄级统计 - 蓄积
      ageAccumulationData.value = (Array.isArray(age.x_data) ? age.x_data : []).map((item, i) => {
        return {
          name: item,
          value: age.y_data.accumulate[i]
        }
      })

      // 按径级组统计 - 面积
      diameterAreaData.value = (Array.isArray(diameter.x_data) ? diameter.x_data : []).map((item, i) => {
        return {
          name: item,
          value: diameter.y_data.area[i]
        }
      })
      // 按径级组统计 - 蓄积
      diameterAccumulationData.value = (Array.isArray(diameter.x_data) ? diameter.x_data : []).map((item, i) => {
        return {
          name: item,
          value: diameter.y_data.num[i]
        }
      })

      await nextTick()
      ageTabChange(currentAgeTab.value)
      diameterTabChange(currentDiameterTab.value)
    }

    const openDialog = () => {
      forestTimberAgeAndSizeStatsDialogRef.value.open()
    }

    watch(() => currentAgeTab.value, async () => {
      await nextTick()
      initAgeEcharts()
    })

    watch(() => currentDiameterTab.value, async () => {
      await nextTick()
      initDiameterEcharts()
    })

    onMounted(init)

    return {
      GlobalData,
      forestTimberAgeAndSizeStatsDialogRef,

      currentAgeTab,
      ageTabList,
      ageAreaEchartsRef,
      ageAccumulationEchartsRef,

      currentDiameterTab,
      diameterTabList,
      diameterAreaEchartsRef,
      diameterAccumulationEchartsRef,

      ageTabChange,
      diameterTabChange,
      openDialog
    }
  }
}
</script>

<template>
  <div class="forest-timber-age-and-size-stats">
    <CardTitle title="用材林龄级径级组统计"/>

    <ul class="content">
      <CardLeftTopDot/>

      <li class="item" @click="openDialog">
        <div class="header">
          <i class="dot"></i>
          <span class="title">按龄级统计</span>
          <SwitchTabComponent :list="ageTabList" @tab-change="ageTabChange"/>
        </div>
        <div class="chart" v-if="currentAgeTab === 'area'" ref="ageAreaEchartsRef"></div>
        <div class="chart" v-else ref="ageAccumulationEchartsRef"></div>
      </li>
      <li class="item">
        <div class="header">
          <i class="dot"></i>
          <span class="title">按径级组统计</span>
          <SwitchTabComponent :list="diameterTabList" @tab-change="diameterTabChange"/>
        </div>
        <div class="chart" v-if="currentDiameterTab === 'area'" ref="diameterAreaEchartsRef"></div>
        <div class="chart" v-else ref="diameterAccumulationEchartsRef"></div>
      </li>
    </ul>

    <ForestTimberAgeAndSizeStatsDialog ref="forestTimberAgeAndSizeStatsDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.forest-timber-age-and-size-stats {
  height: 220px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    display: flex;
    gap: 8px;
    position: relative;

    .item {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(38, 64, 95, 0.86);
      background: rgba(159, 191, 255, 0.03);
      box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

      .header {
        display: flex;
        align-items: center;
        gap: 4px;

        .dot {
          width: 4px;
          height: 4px;
          margin: 0 4px;
          background-color: #1FC6FF;
        }

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          background: linear-gradient(0deg, #B1DDFF 20.83%, #EDF7FF 87.5%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-right: auto;
        }
      }

      .chart {
        flex: 1;
      }
    }
  }
}
</style>