<script>
import to from "await-to-js"
import { onMounted, reactive } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyAbnormalElectricityConsumptionData } from "./economy.api.service"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"

export default {
  name: 'EconomyAbnormalElectricityConsumption',
  components: {
    CardLeftTopDot,
    CardTitle
  },
  setup() {
    const dataList = reactive([
      {
        "topics_title": "峨边彝族自治县交通建设有限公司关于变更经营范围的请示",
        "department_name": null,
        "ctime": "2024-11-17"
      },
      {
        "topics_title": "峨边彝族自治县交通建设有限公司关于增加经营范围的请示",
        "department_name": null,
        "ctime": "2024-11-17"
      }
    ])

    const init = async (value) => {
      const [ err, res ] = await to(getEconomyAbnormalElectricityConsumptionData({
        type: value
      }))
      if (err) return
      console.log('企业用电异动 -----> ', res)

    }

    onMounted(init)

    return {
      GlobalData,
      dataList,
      init
    }
  }
}
</script>

<template>
  <div class="economy-abnormal-electricity-consumption">
    <CardTitle title="企业用电异动"/>
    <div class="content">
      <CardLeftTopDot/>
      <div class="list waiting">
        <ul class="head">
          <li class="th-item index">序号</li>
          <li class="th-item topic-name">企业名称</li>
          <li class="th-item group-name">本月用电量</li>
          <li class="th-item submit-time">环比</li>
        </ul>
        <div class="table-wrap">
          <ul class="tables">
            <li class="tables-tr" v-for="(item, i) in dataList"
                :key="i+'-waiting-for-discussion'">
              <div class="td-item index">{{ i + 1 }}</div>
              <div class="td-item topic-name">xx企业</div>
              <div class="td-item group-name">100kwh</div>
              <div class="td-item submit-time">
                <img :src="GlobalData.getImgUrl(i / 2 ? 'decline_icon.png' : 'up_icon.png')" class="icon" alt="">
                <span class="text">12%</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.economy-abnormal-electricity-consumption {
  height: 244px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;

      .head {
        height: 40px;
        padding: 0 12px;
        display: flex;
        gap: 16px;
        align-items: center;
        background-image: url('@{img_url}waiting-for-discussion-head_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .th-item,
      .td-item {
        color: #829FBA;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;

        &.index {
          width: 32px;
        }

        &.topic-name {
          flex: 1;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }

        &.group-name,
        &.submit-time {
          width: 96px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }

        &.submit-time {
          display: flex;
          gap: 2px;
          align-items: center;

          .text {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .icon {
            width: 16px;
            height: 16px;
          }
        }
      }

      .table-wrap {
        flex: 1;
        overflow-y: auto; /* 允许内容垂直滚动 */
        min-height: 0; /* 确保在弹性布局中可伸缩 */
      }

      .tables {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 8px;

        .tables-tr {
          cursor: pointer;
          height: 40px;
          display: flex;
          gap: 16px;
          padding: 0 16px;
          align-items: center;
          list-style: none;
          background-image: url('@{img_url}waiting-for-discussion-tr_bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;

          &:hover {
            background-image: url('@{img_url}waiting-for-discussion-tr_bg__active.png');
          }

        }
      }

    }
  }
}
</style>