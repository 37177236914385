/**
 * @name: GlobalData
 * @author: yuxi
 * @date: 2024-09-29 03:37
 * @description：GlobalData
 * @update: 2024-09-29 03:37
 */

export default {
    $bus: null,
    pageType: null,
    getImgUrl(fileName) {
        // 动态加载图片，Webpack 会正确处理
        return require(`@/assets/images/${ fileName }`)
    }
}
