<script>
import { defineExpose, markRaw, nextTick, ref, toRaw } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'ForestCarbonStorageDialog',
  setup() {
    const dialogVisible = ref(false)

    // 各类碳储量分布
    const distributedEchartsRef = ref(null)
    const distributedEchartsSelf = ref(null)
    const distributedEchartsData = ref([])

    // 地上生物质碳库
    const aboveEchartsRef = ref(null)
    const aboveEchartsSelf = ref(null)
    const aboveEchartsData = ref([])

    // 地下生物质碳库
    const underEchartsRef = ref(null)
    const underEchartsSelf = ref(null)
    const underEchartsData = ref([])

    const getDistributedEchartsData = (data) => {
      // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
      const getParametricEquation = (startRatio, endRatio, isSelected, isHovered, k, h) => {
        // 计算
        let midRatio = (startRatio + endRatio) / 2
        let startRadian = startRatio * Math.PI * 2
        let endRadian = endRatio * Math.PI * 2
        let midRadian = midRatio * Math.PI * 2
        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
          isSelected = true
        }
        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3
        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0
        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1
        // 返回曲面参数方程
        return {
          u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32
          },
          v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20
          },
          x: function (u, v) {
            if (u < startRadian) {
              return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            }
            if (u > endRadian) {
              return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
          },
          y: function (u, v) {
            if (u < startRadian) {
              return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            }
            if (u > endRadian) {
              return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
          },
          z: function (u, v) {
            if (u < -Math.PI * 0.5) {
              return Math.sin(u)
            }
            if (u > Math.PI * 2.5) {
              return Math.sin(u) * h * 0.1
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1
          }
        }
      }

      const getPie3D = (pieData) => {
        //internalDiameterRatio:透明的空心占比
        let series = []
        let sumValue = 0
        let startValue = 0
        let endValue = 0
        let k = 1
        pieData.sort((a, b) => {
          return b.value - a.value
        })
        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value
          let seriesItem = {
            name: typeof pieData[i].name === 'undefined' ? `series${ i }` : pieData[i].name,
            type: 'surface',
            parametric: true,
            wireframe: {
              show: false
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k: k
            },
            radius: '50%',
            center: [ '20%', '10%' ]
          }

          if (typeof pieData[i].itemStyle != 'undefined') {
            let itemStyle = {}
            typeof pieData[i].itemStyle.color != 'undefined' ? (itemStyle.color = pieData[i].itemStyle.color) : null
            typeof pieData[i].itemStyle.opacity != 'undefined' ?
                (itemStyle.opacity = pieData[i].itemStyle.opacity) :
                null
            seriesItem.itemStyle = itemStyle
          }
          series.push(seriesItem)
        }

        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value
          series[i].pieData.startRatio = startValue / sumValue
          series[i].pieData.endRatio = endValue / sumValue
          series[i].parametricEquation = getParametricEquation(
              series[i].pieData.startRatio,
              series[i].pieData.endRatio,
              false,
              false,
              k,
              series[i].pieData.value
          )
          startValue = endValue
        }
        let boxHeight = getHeight3D(series, 3)
        let option = {
          labelLine: {
            show: false
          },
          label: {
            show: true,
            position: 'outside',
            rich: {
              c0: {
                fontSize: 14,
                color: '#01E8E0'
              },
              c1: {
                fontSize: 20,
                color: '#FFDF75',
                fontWeight: '500'
              },
              n: {
                fontSize: 14,
                color: '#fff',
                lineHeight: 24
              }
            },
            formatter: item => {
              return `{n|${ item.name }}\n{c${ item.dataIndex }|${ item.value } }{n|座}`
            }
          },
          tooltip: {
            // trigger: 'item',
            axisPointer: {
              label: {
                show: true,
                backgroundColor: '#fff',
                color: '#556677',
                borderColor: 'rgba(0,0,0,.5)',
                shadowColor: 'rgba(0,0,0,.5)',
                shadowOffsetY: 0
              },
              lineStyle: {
                width: 0
              }
            },
            backgroundColor: 'rgba(0, 0, 0, .5)',
            textStyle: {
              color: '#FFF',
              fontSize: 14,
              lineHeight: 30
            },
            padding: [ 10, 10 ],
            extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)',
            formatter: (params) => {
              if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                let bfb = (
                    (option.series[params.seriesIndex].pieData.endRatio -
                        option.series[params.seriesIndex].pieData.startRatio) *
                    100
                ).toFixed(2)
                return (
                    `${ params.seriesName }<br/>` +
                    `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ params.color };"></span>` +
                    `${ bfb }%`
                )
              }
            }
          },
          xAxis3D: {
            min: -1,
            max: 1
          },
          yAxis3D: {
            min: -1,
            max: 1
          },
          zAxis3D: {
            min: -2,
            max: 2
          },
          grid3D: {
            show: false,
            boxHeight: boxHeight, //圆环的高度
            left: 0,
            top: -45, //3d饼图的位置
            viewControl: {
              //3d效果可以放大、旋转等，请自己去查看官方配置
              alpha: 45, //角度
              distance: 240, //调整视角到主体的距离，类似调整zoom
              rotateSensitivity: 0, //设置为0无法旋转
              zoomSensitivity: 0, //设置为0无法缩放
              panSensitivity: 0, //设置为0无法平移
              autoRotate: false //自动旋转
            }
          },
          series: series
        }
        return option
      }

      //获取3d丙图的最高扇区的高度
      const getHeight3D = (series, height) => {
        series.sort((a, b) => {
          return b.pieData.value - a.pieData.value
        })
        return (height * 80) / series[0].pieData.value
      }

      const option = getPie3D(data, 0.8)
      option.series.push({
        name: 'pie2d',
        type: 'pie',
        label: {
          show: false
        },
        labelLine: {
          show: false
        },
        startAngle: -15, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: [ '50%', '50%' ],
        center: [ '50%', '53%' ], //指示线的位置
        emphasis: {
          disable: false, //是否关闭扇区高亮效果
          scale: false, //扇区是否缩放
          scaleSize: 0 //放大的尺寸，这里为了保证不放大扇区设置的，可要可不要
        },
        data: data
      })

      return option
    }

    const getAboveAndUnderEchartsData = (data) => {
      const processedData = data.map(item => ({
        ...item,
        value: item.value,  // 将 val 映射为 value
        name: item.name // 将 label 映射为 name
      }))

      return {
        // backgroundColor: '#364686',
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        color: [ '#1FC6FF', '#59FFFF', '#F5E74F', '#FF386B', '#1FC6FF', '#DEA700', '#01BB6D', '#FF75CE' ],
        title: [
          {
            text: '总量',
            left: '48%',
            top: '32%',
            textAlign: 'center',
            textStyle: {
              fontSize: 12,
              color: '#ffffff',
              align: 'center',
            }
          },
          {
            text: 30495543,
            left: '48%',
            top: '45%',
            textAlign: 'center',
            textStyle: {
              color: '#fff',
              fontSize: 16,
              fontWeight: '600',
              align: 'center',
            }
          },
          {
            text: 'tC',
            left: '48%',
            top: '57%',
            textAlign: 'center',
            textStyle: {
              fontSize: 12,
              color: '#ffffff',
              align: 'center',
            }
          }
        ],
        polar: {
          radius: [ '40%', '50%' ],
          center: [ '50%', '50%' ]
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'pie',
            radius: [ '65%', '75%' ],
            center: [ 'center', '50%' ],
            data: processedData,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderColor: '#364684',
                borderWidth: 0,
                borderRadius: 100
                // shadowColor: 'rgba(0,47,66, 0.5)',
                // shadowBlur: 10
              }
            },
            tooltip: {
              position: [ '50%', '50%' ]
            },
            label: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: '58%',
            hoverAnimation: false,
            center: [ 'center', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]), // borderWidth: 1,
                // borderColor: '',
                shadowBlur: 10
                // shadowColor: 'rgba(55,70,130, 1)'
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
      }

    const initEcharts = async () => {
      // 各类碳储量分布
      distributedEchartsData.value = [
        {
          name: '地上生物质碳库',
          value: 3,
          itemStyle: {
            color: '#2491FF'
          },
          startRatio: 0,
          endRatio: 0.7
        },
        {
          name: '竹林生态碳储量',
          value: 1,
          itemStyle: {
            color: '#6CC8EC'
          },
          startRatio: 0.7,
          endRatio: 1
        },
        {
          name: '枯落物碳库',
          value: 1,
          itemStyle: {
            color: '#FFDF24'
          },
          startRatio: 0.7,
          endRatio: 1
        },
        {
          name: '土壤碳库',
          value: 10,
          itemStyle: {
            color: '#B06AF7'
          },
          startRatio: 0.7,
          endRatio: 1
        },
        {
          name: '地下生物质碳库',
          value: 1,
          itemStyle: {
            color: '#FF9000'
          },
          startRatio: 0.7,
          endRatio: 1
        },
        {
          name: '森林生态碳储量',
          value: 1,
          itemStyle: {
            color: '#5AD8A6'
          },
          startRatio: 0.7,
          endRatio: 1
        },
        {
          name: '枯死木碳库',
          value: 1,
          itemStyle: {
            color: '#F45D42'
          },
          startRatio: 0.7,
          endRatio: 1
        }
      ]

      // 地上生物质碳库
      aboveEchartsData.value = [
        {
          name: '乔木层碳储量',
          value: 3,
          color: '#37DD77'
        },
        {line: true},
        {
          name: '灌木层碳储量',
          value: 4,
          color: '#FF9000'
        },
        {line: true},
        {
          name: '草木层碳储量',
          value: 5,
          color: '#1FC6FF'
        },
        {line: true},
        {
          name: '林分地上生物质碳储量',
          value: 6,
          color: '#F5E74F'
        }
      ]

      // 地下生物质碳库
      underEchartsData.value = [
        {
          name: '乔木层碳储量',
          value: 6,
          color: '#37DD77'
        },
        {line: true},
        {
          name: '灌木层碳储量',
          value: 5,
          color: '#FF9000'
        },
        {line: true},
        {
          name: '草木层碳储量',
          value: 4,
          color: '#1FC6FF'
        },
        {line: true},
        {
          name: '林分地上生物质碳储量',
          value: 3,
          color: '#F5E74F'
        }
      ]

      await nextTick()

      // 各类碳储量分布
      if (toRaw(distributedEchartsSelf.value)) {
        distributedEchartsSelf.value.dispose()
      }
      distributedEchartsSelf.value = markRaw(window.echarts.init(distributedEchartsRef.value, null, {
        renderer: 'canvas'
      }))
      distributedEchartsSelf.value.setOption(getDistributedEchartsData(toRaw(distributedEchartsData.value)))

      // 地上生物质碳库
      if (toRaw(aboveEchartsSelf.value)) {
        aboveEchartsSelf.value.dispose()
      }
      aboveEchartsSelf.value = markRaw(window.echarts.init(aboveEchartsRef.value, null, {
        renderer: 'canvas'
      }))
      aboveEchartsSelf.value.setOption(getAboveAndUnderEchartsData(toRaw(aboveEchartsData.value)))

      // 地下生物质碳库
      if (toRaw(underEchartsSelf.value)) {
        underEchartsSelf.value.dispose()
      }
      underEchartsSelf.value = markRaw(window.echarts.init(underEchartsRef.value, null, {
        renderer: 'canvas'
      }))
      underEchartsSelf.value.setOption(getAboveAndUnderEchartsData(toRaw(underEchartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          distributedEchartsSelf.value.resize()
          aboveEchartsSelf.value.resize()
          underEchartsSelf.value.resize()
        }
      }, 200)
    }

    /**
     * 打开弹窗
     */
    const open = () => {
      dialogVisible.value = true
      setTimeout(initEcharts, 550)
    }

    defineExpose({
      open
    })

    return {
      GlobalData,

      dialogVisible,

      distributedEchartsRef,
      aboveEchartsRef,
      underEchartsRef,

      distributedEchartsData,
      aboveEchartsData,
      underEchartsData,

      open
    }
  }
}
</script>

<template>
  <div class="dialog__center forest-carbon-storage-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">各乡镇土地面积统计</span>
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>
    <div class="content">
      <div class="card-distributed">
        <div class="card-header">
          <i class="card-dot"></i>
          <span class="card-title">各类碳储量分布</span>
        </div>
        <div class="info-main">
          <div class="chart-wrap">
            <h2 class="name">总量</h2>
            <div class="value">
              <span class="label">142393.01</span>
              <span class="unit">tC</span>
            </div>
            <div class="chart" ref="distributedEchartsRef"></div>
          </div>

          <ul class="info-wrap">
            <li class="item" v-for="item in distributedEchartsData" :key="item.name">
              <div class="name">
                <i class="dot" :style="{backgroundColor: item.itemStyle.color}"></i>
                <span>{{ item.name }}</span>
              </div>
              <div class="value">
                <span class="label" :style="{color: item.itemStyle.color}">142393.01</span>
                <span class="unit">tC</span>
                <i class="line"></i>
                <span class="ratio" :style="{color: item.itemStyle.color}">3.36%</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="above-adn-under_ground">
        <div class="card-above">
          <div class="card-header">
            <i class="card-dot"></i>
            <span class="card-title">地上生物质碳库</span>
          </div>
          <div class="chart" ref="aboveEchartsRef"></div>
          <ul class="label-list">
            <li :class="['item', {line: item.line}]" v-for="item in aboveEchartsData" :key="'above'+item.name">
              <template v-if="!item.line">
                <i class="dot" :style="{backgroundColor: item.color}"></i>
                <span class="name">{{ item.name }}</span>
                <span class="unit" :style="{color: item.color}">43.71%</span>
              </template>
            </li>
          </ul>
        </div>
        <div class="card-under">
          <div class="card-header">
            <i class="card-dot"></i>
            <span class="card-title">地下生物质碳库</span>
          </div>
          <div class="chart" ref="underEchartsRef"></div>
          <ul class="label-list">
            <li :class="['item', {line: item.line}]" v-for="item in underEchartsData" :key="'under'+item.name">
              <template v-if="!item.line">
                <i class="dot" :style="{backgroundColor: item.color}"></i>
                <span class="name">{{ item.name }}</span>
                <span class="unit" :style="{color: item.color}">43.71%</span>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.forest-carbon-storage-dialog {
  &.open {
    width: 672px;
    height: 718px;
  }

  .content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .card-header {
      height: 32px;
      display: flex;
      padding: 0 5px;
      gap: 5px;
      align-items: center;
      background-image: url('@{img_url}forest-area-statistics-dialog-title_bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .card-dot {
        height: 16px;
        width: 2.281px;
        background: linear-gradient(90deg, #62B6FF -0.68%, #1C8AEA 38.3%, #36A1FF 91.03%);
        box-shadow: 0px 0px 6px 0px rgba(233, 245, 255, 0.43) inset, 1.634px 2.516px 0px 0px rgba(30, 61, 88, 0.33);
      }

      .card-title {
        text-shadow: 0px 0px 12px #1182E4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(0deg, #B1DBFF 20.83%, #EDF7FF 87.5%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .chart {
      flex: 1;
      width: 100%;
      height: 100%;
    }

    .card-distributed {
      width: 646px;
      height: 280px;
      display: flex;
      flex-direction: column;
      border: 1px solid #02B5FF;
      background: linear-gradient(180deg, rgba(39, 124, 255, 0.00) 0%, rgba(39, 124, 255, 0.15) 112.5%);

      .info-main {
        flex: 1;
        display: flex;
        margin-top: 6px;

        .chart-wrap {
          width: 230px;
          height: 250px;
          //height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .chart {
            background-image: url('@{img_url}forest-carbon-storage-dialog-chart_bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .name {
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .value {
            height: 22px;
            display: flex;
            align-items: baseline;
            margin: 8px 0 12px 0;
            gap: 2px;

            .label {
              color: #FFF;
              text-align: center;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px; /* 100% */
            }

            .unit {
              color: #D5E2E2;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }

        .info-wrap {
          flex: 1;
          display: grid;
          grid-template-columns: repeat(2, 1fr); /* 两列，每列宽度相等 */
          gap: 0 16px; /* 设置列与行的间距 */

          .item {
            height: 38px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 8px;

            .name {
              height: 18px;
              display: flex;
              align-items: center;
              gap: 4px;

              .dot {
                width: 6px;
                height: 6px;
              }

              span {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }
            }

            .value {
              height: 16px;
              display: flex;
              align-items: baseline;

              .label,
              .ratio {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }

              .line {
                width: 1px;
                height: 16px;
                opacity: 1;
                margin: 0 8px;
                background: linear-gradient(90deg, rgba(113, 184, 238, 0.00) 0%, #71B8EE 50%, rgba(113, 184, 238, 0.00) 100%);
              }

              .unit {
                font-size: 14px;
                line-height: 14px;
                color: #FFF;
                font-style: normal;
                font-weight: 400;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }

    .above-adn-under_ground {
      flex: 1;
      display: flex;
      gap: 16px;

      .card-above,
      .card-under {
        flex: 1;
        display: flex;
        padding: 12px;
        flex-direction: column;
        border: 1px solid #02B5FF;
        background: linear-gradient(180deg, rgba(39, 124, 255, 0.00) 0%, rgba(39, 124, 255, 0.15) 112.5%);

        .label-list {
          height: 150px;
          display: flex;
          flex-direction: column;

          .item {
            height: 24px;
            line-height: 24px;
            display: flex;
            align-items: center;

            &.line {
              height: 1px;
              margin: 8px 0;
              line-height: 1px;
              background-image: url('@{img_url}forest-carbon-storage-dialog_line.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .dot {
              width: 8px;
              height: 8px;
              margin-right: 8px;
            }

            .name {
              color: #FFF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin-right: auto;
            }

            .unit {
              text-align: right;
              text-shadow: 0px 9.715px 51.003px rgba(27, 126, 242, 0.64);
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>