/**
 * @name: forest.api.service
 * @author: yuxi
 * @date: 2024-11-26 23:36
 * @description：forest.api.service
 * @update: 2024-11-26 23:36
 */

import apiConfig from "./api.config"
import useIsProdEev from "./isProdEev.hooks"

/**
 * 获取林业总览数据
 */
export const getForestDashboardData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/overview'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 获取各类土地面积统计数据
 */
export const getForestLandAreaStatisticsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/land_area_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 各类森林面积、蓄积统计
 * @returns {Promise<any>|*}
 */
export const getForestAreaStatisticsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_area_accumulate_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 林种面积蓄积统计
 * @returns {Promise<any>|*}
 */
export const getForestTypeAreaStatisticsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/plant_area_accumulate_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 用材林龄级径级组统计
 * @returns {Promise<any>|*}
 */
export const getForestTimberAgeAndSizeStatsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/diameter_group_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 竹林资源统计
 * @returns {Promise<any>|*}
 */
export const getForestBambooResourceStatsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/bamboo_forest_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 森林生态指标
 * @returns {Promise<any>|*}
 */
export const getForestProtectionLevelAreaStatsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_ecology'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 林地保护等级面积统计
 * @returns {Promise<any>|*}
 */
export const getForestProtectionAreaStatsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/woodland_protect_area_statistics'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 林下经济
 * @returns {Promise<any>|*}
 */
export const getForestUnderForestEconomyData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_plant'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 森林景观
 * @returns {Promise<any>|*}
 */
export const getForestLandscapeData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/forestry_economy/forest_landscape'

    if (isProdEev) {
        return window?.OU?.postData(url, {}, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}
