<script>
import { ref, onMounted, onBeforeMount, getCurrentInstance } from "vue"
import pageType from "./pageType"

export default {
  name: 'LayoutHeader',
  setup(_, {emit}) {
    const instance = getCurrentInstance()

    const timeInterval = ref(null)
    const weekDays = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ]
    const timeInfo = ref(null)
    const timeInfo1 = ref(null)
    const timeInfo2 = ref(null)
    const weather = ref(null)
    const temperature = ref(38)
    const humidity = ref(9)
    const wind = ref(3)

    const currentPage = ref(pageType.SOASupervision)

    const init = () => {
      timeInterval.value = setInterval(() => {
        timeInfo.value = `${ window.dayjs().format('YYYY年MM月DD日') }`
        timeInfo1.value = `${ window.dayjs().format('HH:mm:ss') }`
        timeInfo2.value = `${ weekDays[+window.dayjs().format('d')] }`
      }, 1000)
      tqapidata()
    }

    const tqapidata = () => {
      let url = `https://restapi.amap.com/v3/weather/weatherInfo?key=39f6f3ad0ddb0b4fc4db74ba65df3f6f&city=510100&extensions=base`
      window?.OU?.getData(url, {}, {}).then(res => {
        if (res.infocode == '10000') {
          let info = res.lives[0]
          temperature.value = info.temperature
          humidity.value = info.humidity.slice(-1)
          wind.value = info.windpower
        }
        console.log('天气信息', res)
      })
    }

    onMounted(init)

    onBeforeMount(() => {
      if (timeInterval.value) {
        clearInterval(timeInterval.value)
        timeInterval.value = null
      }
    })

    const handleClick = (code) => {
      currentPage.value = code
      emit('pageChange', code)

      if (instance.proxy.$bus) {
        instance.proxy?.$bus.emit('pageChange', code)
      }
    }

    return {
      pageType,

      timeInterval,
      weekDays,
      timeInfo,
      timeInfo1,
      timeInfo2,
      weather,
      temperature,
      humidity,
      wind,

      currentPage,
      handleClick
    }
  }
}
</script>

<template>
  <div class="layout-header">
    <div class="info-wrap">
      <div class="left-info">
        {{ timeInfo }}
        <span class="dot"></span>
        <span class="time">{{ timeInfo1 }}</span>
        <span class="dot"></span>
        {{ timeInfo2 }}
      </div>
      <div class="right-info">
        <div class="weather weather1">降雨量(小时)<span class="num">0</span><span class="unit">mm</span></div>
        <span class="dot"></span>
        <div class="weather weather1">气压 <span class="num">0hpa</span></div>
        <span class="dot"></span>
        <div class="weather weather1">湿度 <span class="num">{{ humidity }}%</span></div>
        <span class="dot"></span>
        <div class="weather ">天气 <span class="num">{{ temperature }}℃</span></div>
      </div>
    </div>

    <div class="nva-wrap">
      <div class="left-wrap">
        <div :class="['left-item', (currentPage === pageType.economicOverview ? 'active' : '')]"
             @click="handleClick(pageType.economicOverview)">
          <span>经济概览</span>
        </div>
        <div :class="['left-item', (currentPage === pageType.SOASupervision ? 'active' : '')]"
             @click="handleClick(pageType.SOASupervision)">
          <span>国资监管</span>
        </div>
      </div>

      <div class="right-wrap">
        <div :class="['right-item', (currentPage === pageType.forestryEconomy ? 'active' : '')]"
             @click="handleClick(pageType.forestryEconomy)">
          <span>林业经济</span>
        </div>
        <div :class="['right-item', (currentPage === pageType.majorProjects ? 'active' : '')]"
             @click="handleClick(pageType.majorProjects)">
          <span>重大项目</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.layout-header {
  display: flex;
  width: 100%;
  height: 72px;
  z-index: 1;
  flex-direction: column;
  background: url('@{img_url}header_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .info-wrap {
    width: 100%;
    height: 33px;
    display: flex;
    line-height: 48px;
    justify-content: space-between;

    .left-info,
    .right-info {
      height: 24px;
      display: flex;
      align-items: center;
      color: #FFF;

      margin-top: 9px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 1px;
    }

    .left-info {
      width: 326px;
      padding-left: 12px;
      background: url('@{img_url}header-time_bg.png') no-repeat;
      background-size: 100% 100%;

      .time {
        font-family: 'Courier New', Courier, monospace;
      }
    }

    .right-info {
      padding-left: 14px;
      padding-right: 12px;
      background: url('@{img_url}header-weather_bg.png') no-repeat;
      background-size: 100% 100%;

      .weather {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;

        .num {
          font-size: 16px;
          font-weight: 700;
          color: #FFFFFF;
          margin: 0 10px;
        }

        .unit {
          font-size: 12px;
        }
      }

      .weather1 {
        margin-left: 0;

        .num {
          display: inline-block;
          font-size: 14px;
          font-weight: 700;
          font-style: italic;
          margin: 0 10px;
        }
      }
    }

    .dot {
      display: inline-block;
      height: 14px;
      margin: 0 10px;
      border-left: 1px solid #ffffff;
    }
  }

  .nva-wrap {
    display: flex;
    margin-top: 8px;
    gap: 656px;

    .left-wrap {
      margin-left: auto;
      display: flex;
    }

    .right-wrap {
      margin-right: auto;
      display: flex;
    }

    .left-item,
    .right-item {
      cursor: pointer;
      width: 120px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      span {
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(180deg, #D3DEE9 0%, #FFF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.active {
        span {
          text-shadow: 0px 0px 2px #00D2FF;
          background: linear-gradient(180deg, #D3DEE9 0%, #FFF 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .left-item {
      background-image: url('@{img_url}left-nav_bg.png');

      &.active {
        background-image: url('@{img_url}left-nav_bg__active.png');
      }
    }

    .right-item {
      background-image: url('@{img_url}right-nav_bg.png');

      &.active {
        background-image: url('@{img_url}right-nav_bg__active.png');
      }
    }
  }
}
</style>