<script>
import to from "await-to-js"
import { ref, onMounted } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import ForestCarbonStorageDialog from "./ForestCarbonStorageDialog.vue"
import ForestAreaStatisticsDialog from "./ForestAreaStatisticsDialog.vue"
import ForestLandAreaStatisticsDialog from "./ForestLandAreaStatisticsDialog.vue"
import { getForestDashboardData } from "./forest.api.service"

export default {
  name: 'ForestDashboard',
  components: {
    CardTitle,
    ForestCarbonStorageDialog,
    ForestAreaStatisticsDialog,
    ForestLandAreaStatisticsDialog
  },
  setup() {
    const area_total = ref()
    const accumulate_total = ref()
    const carbon_stock = ref()

    const forestCarbonStorageRef = ref()
    const forestAreaStatisticsDialogRef = ref()
    const forestLandAreaStatisticsDialogRef = ref()

    const openForestCarbonStorageDialog = () => {
      forestCarbonStorageRef.value.open()
    }

    const openForestAreaStatisticsDialog = () => {
      forestAreaStatisticsDialogRef.value.open()
    }

    const openForestLandAreaStatisticsDialogDialog = () => {
      forestLandAreaStatisticsDialogRef.value.open()
    }

    const init = async () => {
      const [ err, res ] = await to(getForestDashboardData())
      if (err) return

      area_total.value = res.data.area_total
      accumulate_total.value = res.data.accumulate_total
      carbon_stock.value = res.data.carbon_stock
      console.log('林业总览 -----> ', res)
    }

    onMounted(init)

    return {
      GlobalData,

      forestCarbonStorageRef,
      forestAreaStatisticsDialogRef,
      forestLandAreaStatisticsDialogRef,

      area_total,
      accumulate_total,
      carbon_stock,

      openForestCarbonStorageDialog,
      openForestAreaStatisticsDialog,
      openForestLandAreaStatisticsDialogDialog
    }
  }
}
</script>

<template>
  <div class="forest-dashboard">
    <CardTitle title="林业总览"/>
    <div class="content">
      <img :src="GlobalData.getImgUrl('forest-dashboard-icon.png')" class="icon"
           alt="icon">
      <ul class="info">
        <li class="item" @click="openForestLandAreaStatisticsDialogDialog">
          <span class="name">总面积（hm²）</span>
          <span class="unit">{{ area_total }}</span>
        </li>
        <li class="line"></li>
        <li class="item" @click="openForestAreaStatisticsDialog">
          <span class="name">总蓄积（m³）</span>
          <span class="unit">{{ accumulate_total }}</span>
        </li>
        <li class="line"></li>
        <li class="item" @click="openForestCarbonStorageDialog">
          <span class="name">碳储量(tC)</span>
          <span class="unit">{{ carbon_stock }}</span>
        </li>
      </ul>
    </div>

    <!--    弹窗 - 各乡镇土地统计-->
    <ForestLandAreaStatisticsDialog ref="forestLandAreaStatisticsDialogRef"/>

    <!--    弹窗 - 各乡镇各类森林面积、蓄积统计-->
    <ForestAreaStatisticsDialog ref="forestAreaStatisticsDialogRef"/>

    <!--    弹窗 - 碳储量统计-->
    <ForestCarbonStorageDialog ref="forestCarbonStorageRef"/>
  </div>
</template>

<style scoped lang="less">
.forest-dashboard {
  height: 100px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    padding: 8px 13px;
    display: flex;
    gap: 8px;
    align-items: center;
    background: url('@{img_url}forest-dashboard_bg.png') no-repeat;
    background-size: 100% 100%;

    .icon {
      width: 36px;
      height: 36px;
    }

    .info {
      flex: 1;
      display: flex;

      .line {
        width: 1px;
        height: 53px;
        opacity: 0.4;
        margin: 0 12px;
        background: linear-gradient(90deg, rgba(113, 184, 238, 0.00) 0%, #71B8EE 50%, rgba(113, 184, 238, 0.00) 100%);
      }

      .item {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .name {
          color: #B9E0FF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        .unit {
          color: #FFF;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

  }
}
</style>