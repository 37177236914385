/**
 * @name: dev.hooks
 * @author: yuxi
 * @date: 2024-11-23 16:45
 * @description：dev.hooks
 * @update: 2024-11-23 16:45
 */

const useIsProdEev = () => {
  return 'OU' in window
}

export default useIsProdEev