<script>
import to from "await-to-js"
import { computed, ref, onMounted } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import SOAWaitingForDiscussionDialog from "./SOAWaitingForDiscussionDialog.vue"
import { getSOAWaitingForDiscussionData } from "./soa.api.service"

export default {
  name: 'SOAWaitingForDiscussion',
  components: {
    CardTitle,
    CardLeftTopDot,
    SOAWaitingForDiscussionDialog
  },
  setup() {
    const SOAWaitingForDiscussionDialogRef = ref()
    const currentTab = ref('waiting')
    const tabTitle = computed(() => {
      return tabList.value.find(item => item.key === currentTab.value).label + '议题'
    })
    const tabList = ref([
      {
        label: '待上会',
        key: 'waiting'
      },
      {
        label: '待完善',
        key: 'complete'
      },
      {
        label: '待批复',
        key: 'reply'
      }
    ])

    // 待上会
    const wait_meeting = ref([])

    // 待完善
    const wait_improve = ref([])

    // 待审批
    const wait_examine = ref([])

    const handleTabChange = (key) => {
      currentTab.value = key
    }

    const openDialog = () => {
      SOAWaitingForDiscussionDialogRef.value.open()
    }

    const init = async () => {
      const [ err, res ] = await to(getSOAWaitingForDiscussionData())
      if (err) return
      console.log('待上会议题 -----> ', res)

      const data = res.data
      wait_examine.value = data?.wait_examine || []
      wait_improve.value = data?.wait_improve || []
      wait_meeting.value = data?.wait_meeting || []
    }

    onMounted(init)

    return {
      GlobalData,
      SOAWaitingForDiscussionDialogRef,

      tabTitle,
      tabList,
      currentTab,
      wait_examine,
      wait_improve,
      wait_meeting,

      handleTabChange,
      openDialog
    }
  }
}
</script>


<template>
  <div class="waiting-for-discussion">
    <CardTitle :title="tabTitle" :tab-list="tabList" @tab-change="handleTabChange"/>

    <div class="content">
      <CardLeftTopDot/>

      <!--      待上会-->
      <div v-if="currentTab === 'waiting'" class="list waiting">
        <ul class="head">
          <li class="th-item index">序号</li>
          <li class="th-item topic-name">议题名称</li>
          <li class="th-item group-name">部门名称</li>
          <li class="th-item submit-time">提交时间</li>
        </ul>
        <div class="table-wrap">
          <ul class="tables">
            <li class="tables-tr" @click="openDialog" v-for="(item, i) in wait_examine"
                :key="i+'-waiting-for-discussion'">
              <div class="td-item index">{{ i + 1 }}</div>
              <div class="td-item topic-name">{{ item.topics_title }}</div>
              <div class="td-item group-name">{{ item.department_name }}</div>
              <div class="td-item submit-time">{{ item.ctime }}</div>
            </li>
          </ul>
        </div>
      </div>

      <!--      待完善-->
      <div v-if="currentTab === 'complete'" class="list waiting">
        <ul class="head">
          <li class="th-item index">序号</li>
          <li class="th-item topic-name">议题名称</li>
          <li class="th-item group-name">部门名称</li>
          <li class="th-item submit-time">提交时间</li>
        </ul>
        <div class="table-wrap">
          <ul class="tables">
            <li class="tables-tr" @click="openDialog" v-for="(item, i) in wait_improve"
                :key="i+'-waiting-for-discussion'">
              <div class="td-item index">{{ i + 1 }}</div>
              <div class="td-item topic-name">{{ item.topics_title }}</div>
              <div class="td-item group-name">{{ item.department_name }}</div>
              <div class="td-item submit-time">{{ item.ctime }}</div>
            </li>
          </ul>
        </div>
      </div>

      <!--      待批复-->
      <div v-if="currentTab === 'reply'" class="list waiting">
        <ul class="head">
          <li class="th-item index">序号</li>
          <li class="th-item topic-name">议题名称</li>
          <li class="th-item group-name">部门名称</li>
          <li class="th-item submit-time">提交时间</li>
        </ul>
        <div class="table-wrap">
          <ul class="tables">
            <li class="tables-tr" @click="openDialog" v-for="(item, i) in wait_meeting"
                :key="i+'-waiting-for-discussion'">
              <div class="td-item index">{{ i + 1 }}</div>
              <div class="td-item topic-name">{{ item.topics_title }}</div>
              <div class="td-item group-name">{{ item.department_name }}</div>
              <div class="td-item submit-time">{{ item.ctime }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--    弹窗 -->
    <SOAWaitingForDiscussionDialog ref="SOAWaitingForDiscussionDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.waiting-for-discussion {
  height: 286px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    min-height: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 8px 16px;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;

      .head {
        height: 40px;
        padding: 0 12px;
        display: flex;
        gap: 16px;
        align-items: center;
        background-image: url('@{img_url}waiting-for-discussion-head_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .th-item,
      .td-item {
        color: #829FBA;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;

        &.index {
          width: 32px;
        }

        &.topic-name {
          flex: 1;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }

        &.group-name,
        &.submit-time {
          width: 96px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
      }

      .table-wrap {
        flex: 1;
        overflow-y: auto; /* 允许内容垂直滚动 */
        min-height: 0; /* 确保在弹性布局中可伸缩 */
      }

      .tables {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 8px;

        .tables-tr {
          cursor: pointer;
          height: 40px;
          display: flex;
          gap: 16px;
          padding: 0 16px;
          align-items: center;
          list-style: none;
          background-image: url('@{img_url}waiting-for-discussion-tr_bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;

          &:hover {
            background-image: url('@{img_url}waiting-for-discussion-tr_bg__active.png');
          }

        }
      }

    }
  }
}
</style>

