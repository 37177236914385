<script>
import DataDashboard from './SOADataDashboard.vue'
import RevenueCostProfit from './SOARevenueCostProfit.vue'
import RiskMonitoring from './SOARiskMonitoring.vue'

export default {
  name: 'SOASLayoutCenter',
  components: {
    // 营业收入
    DataDashboard,

    // 收入、成本、利润趋势
    RevenueCostProfit,

    // 风险监测
    RiskMonitoring
  }
}
</script>

<template>
  <div class="layout-center-wrap">
    <!--  营业收入-->
    <DataDashboard/>

    <!--  收入、成本、利润趋势-->
    <RevenueCostProfit/>

    <!--  风险监测-->
    <RiskMonitoring/>
  </div>
</template>

<style scoped lang="less">
.layout-center-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>