<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, ref, toRaw, watch } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import ForestTypeAreaStatisticsAreaDialog from "./ForestTypeAreaStatisticsAreaDialog.vue"
import ForestTypeAreaStatisticsAccumulationDialog from "./ForestTypeAreaStatisticsAccumulationDialog.vue"
import { getForestTypeAreaStatisticsData } from "./forest.api.service"

export default {
  name: 'ForestTypeAreaStatistics',
  components: {
    CardTitle,
    CardLeftTopDot,
    ForestTypeAreaStatisticsAreaDialog,
    ForestTypeAreaStatisticsAccumulationDialog
  },
  setup() {
    const forestTypeAreaStatisticsAreaDialogRef = ref()
    const forestTypeAreaStatisticsAccumulationDialogRef = ref()

    const currentTab = ref('area')
    const tabList = ref([
      {
        label: '面积',
        key: 'area'
      },
      {
        label: '蓄积',
        key: 'accumulation'
      }
    ])

    // 面积 ----------------------------------------------------------------
    const currentAreaTab = ref('areaType')
    const areaTabList = ref([
      {
        label: '按林种统计',
        key: 'areaType'
      },
      {
        label: '按树龄统计',
        key: 'areaAge'
      }
    ])

    // 面积 - 按林种统计
    const areaTypeData = ref([])
    const areaTypeEchartsRef = ref(null)
    const areaTypeEchartsSelf = ref(null)

    // 面积 - 按林龄统计
    const areaAgeData = ref([])
    const areaAgeEchartsRef = ref(null)
    const areaAgeEchartsSelf = ref(null)

    // 蓄积 ----------------------------------------------------------------
    const currentAccumulationTab = ref('accumulationType')
    const accumulationTabList = ref([
      {
        label: '按林种统计',
        key: 'accumulationType'
      },
      {
        label: '按树龄统计',
        key: 'accumulationAge'
      }
    ])

    // 蓄积 - 按林种统计
    const accumulationTypeData = ref([])
    const accumulationTypeEchartsRef = ref(null)
    const accumulationTypeEchartsSelf = ref(null)

    // 蓄积 - 按树龄统计
    const accumulationAgeData = ref([])
    const accumulationAgeEchartsRef = ref(null)
    const accumulationAgeEchartsSelf = ref(null)

    const getTypeEchartsData = (data) => {
      return {
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        legend: {
          show: true,
          right: 10,
          top: 12,
          textStyle: {
            color: "#fff"
          },
          itemWidth: 12,
          itemHeight: 10
          // itemGap: 35
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '10%',    // 顶部距离
          bottom: '0%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        xAxis: {
          axisLabel: {
            show: true,
            color: '#c0c3cd',
            fontSize: 14,
            interval: 0,
            rotate: 12
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          data: data.map(item => item.name),
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            color: '#c0c3cd',
            fontSize: 14
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3D647F'
            }
          },
          axisLine: {
            show: false
          },
          name: ''
        },
        series: [ {
          data: data.map(item => item.value),
          type: 'bar', // barMaxWidth: 'auto',
          barWidth: 15,
          itemStyle: {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                offset: 0,
                color: '#F5E74F'
              }, {
                offset: 1,
                color: '#FCE9AE'
              } ])
            }
          },
          label: {
            show: false
          }
        } ]
      }
    }

    const initAreaEcharts = () => {
      // 面积 - 按林种统计
      if (currentAreaTab.value === 'areaType') {
        if (toRaw(areaTypeEchartsSelf.value)) {
          areaTypeEchartsSelf.value.dispose()
        }

        areaTypeEchartsSelf.value = markRaw(window.echarts.init(areaTypeEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        areaTypeEchartsSelf.value.setOption(getTypeEchartsData(toRaw(areaTypeData.value)))
      }

      // 面积 - 按林龄统计
      if (currentAreaTab.value === 'areaAge') {
        if (toRaw(areaAgeEchartsSelf.value)) {
          areaAgeEchartsSelf.value.dispose()
        }

        areaAgeEchartsSelf.value = markRaw(window.echarts.init(areaAgeEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        areaAgeEchartsSelf.value.setOption(getTypeEchartsData(toRaw(areaAgeData.value)))
      }

      setTimeout(function () {
        window.onresize = function () {
          toRaw(areaTypeEchartsSelf.value) && areaTypeEchartsSelf.value.resize()
          toRaw(areaAgeEchartsSelf.value) && areaAgeEchartsSelf.value.resize()
        }
      }, 200)
    }

    const initAccumulationEcharts = () => {
      // 蓄积 - 按林种统计
      if (currentAccumulationTab.value === 'accumulationType') {
        if (toRaw(accumulationTypeEchartsSelf.value)) {
          accumulationTypeEchartsSelf.value.dispose()
        }

        accumulationTypeEchartsSelf.value = markRaw(window.echarts.init(accumulationTypeEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        accumulationTypeEchartsSelf.value.setOption(getTypeEchartsData(toRaw(accumulationTypeData.value)))
      }

      // 面积 - 按树龄统计
      if (currentAccumulationTab.value === 'accumulationAge') {
        if (toRaw(accumulationAgeEchartsSelf.value)) {
          accumulationAgeEchartsSelf.value.dispose()
        }

        accumulationAgeEchartsSelf.value = markRaw(window.echarts.init(accumulationAgeEchartsRef.value, null, {
          renderer: 'canvas'
        }))
        accumulationAgeEchartsSelf.value.setOption(getTypeEchartsData(toRaw(accumulationAgeData.value)))
      }

      setTimeout(function () {
        window.onresize = function () {
          toRaw(accumulationTypeEchartsSelf.value) && accumulationTypeEchartsSelf.value.resize()
          toRaw(accumulationAgeEchartsSelf.value) && accumulationAgeEchartsSelf.value.resize()
        }
      }, 200)
    }

    const handleTabChange = (key) => {
      currentTab.value = key
      nextTick(() => {
        key === 'area' ? initAreaEcharts() : initAccumulationEcharts()
      })
    }

    const init = async () => {
      const [ err, res ] = await to(getForestTypeAreaStatisticsData())
      if (err) return
      console.log('林种面积蓄积统计 -----> ', res)

      const {
        age,
        forest
      } = res.data

      // 面积 - 按林种统计
      areaTypeData.value = (Array.isArray(forest?.x_data) ? forest?.x_data : []).map((item, i) => {
        return {
          name: item,
          value: forest?.y_accumulate[i]
        }
      })

      // 面积 - 按林龄统计
      areaAgeData.value = (Array.isArray(age?.x_data) ? age?.x_data : []).map((item, i) => {
        return {
          name: item,
          value: age?.y_area[i]
        }
      })

      // 蓄积 - 按林种统计
      accumulationTypeData.value = (Array.isArray(forest?.x_data) ? forest?.x_data : []).map((item, i) => {
        return {
          name: item,
          value: forest?.y_accumulate[i]
        }
      })

      // 蓄积 - 按林龄统计
      accumulationAgeData.value = (Array.isArray(age?.x_data) ? age?.x_data : []).map((item, i) => {
        return {
          name: item,
          value: age?.y_accumulate[i]
        }
      })

      await nextTick()
      initAreaEcharts()
      // handleTabChange(currentTab.value)
    }

    const openAreaDialog = () => {
      forestTypeAreaStatisticsAreaDialogRef.value.open()
    }

    const openAccumulationDialog = () => {
      forestTypeAreaStatisticsAccumulationDialogRef.value.open()
    }

    watch(() => currentAreaTab.value, async () => {
      await nextTick()
      initAreaEcharts()
    })

    watch(() => currentAccumulationTab.value, async () => {
      await nextTick()
      initAccumulationEcharts()
    })

    onMounted(init)

    return {
      GlobalData,

      forestTypeAreaStatisticsAreaDialogRef,
      forestTypeAreaStatisticsAccumulationDialogRef,

      currentAreaTab,
      areaTabList,
      areaTypeEchartsRef,
      areaAgeEchartsRef,

      currentAccumulationTab,
      accumulationTabList,
      accumulationTypeEchartsRef,
      accumulationAgeEchartsRef,

      tabList,
      currentTab,

      handleTabChange,
      openAreaDialog,
      openAccumulationDialog
    }
  }
}
</script>

<template>
  <div class="forest-type-area-statistics">
    <CardTitle title="林种面积蓄积统计" :tab-list="tabList" @tab-change="handleTabChange"/>

    <div class="content">
      <CardLeftTopDot/>

      <div class="content">
        <!--        面积-->
        <template v-if="currentTab === 'area'">
          <nav class="head">
            <span
                v-for="item in areaTabList"
                :key="item.key"
                :class="['btn', currentAreaTab === item.key ? 'active' : '']"
                @click="currentAreaTab = item.key">{{ item.label }}</span>
          </nav>
          <!--          按林种统计-->
          <div v-if="currentAreaTab === 'areaType'" class="chart" ref="areaTypeEchartsRef"
               @click="openAreaDialog"></div>
          <!--          按林龄统计-->
          <div v-else class="chart" ref="areaAgeEchartsRef" @click="openAreaDialog"></div>
        </template>

        <!--        蓄积-->
        <template v-else>
          <nav class="head">
            <span
                v-for="item in accumulationTabList"
                :key="item.key"
                :class="['btn', currentAccumulationTab === item.key ? 'active' : '']"
                @click="currentAccumulationTab = item.key">{{ item.label }}</span>
          </nav>
          <!--          按林种统计-->
          <div v-if="currentAccumulationTab === 'accumulationType'" class="chart"
               ref="accumulationTypeEchartsRef" @click="openAccumulationDialog"></div>
          <!--          按林龄统计-->
          <div v-else class="chart" ref="accumulationAgeEchartsRef" @click="openAccumulationDialog"></div>
        </template>
      </div>
    </div>

    <!--    各乡镇林种面积统计 - 弹窗 -->
    <ForestTypeAreaStatisticsAreaDialog ref="forestTypeAreaStatisticsAreaDialogRef"/>

    <!--    各乡镇林种面积蓄积按龄统计 - 弹窗 -->
    <ForestTypeAreaStatisticsAccumulationDialog ref="forestTypeAreaStatisticsAccumulationDialogRef"/>
  </div>
</template>

<style scoped lang="less">
.forest-type-area-statistics {
  height: 220px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .head {
      height: 32px;
      padding: 10px 12px;
      display: flex;
      gap: 8px;
      align-items: center;

      .btn {
        flex: 1;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 3px;
        border: 1px solid rgba(68, 139, 169, 0.10);
        background: rgba(22, 60, 85, 0.10);
        box-shadow: 0px 0px 9px 0px rgba(64, 199, 252, 0.04) inset;
        color: #CCDDF0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;

        &.active {
          color: #87DBFF;
          border: 1px solid #448BA9;
          background: rgba(22, 60, 85, 0.90);
          box-shadow: 0px 0px 9px 0px rgba(64, 199, 252, 0.80) inset;
        }
      }
    }

    .chart {
      flex: 1;
      height: 100%;
    }
  }
}
</style>
