<script>
import LayoutRightWrap from "./SOASLayoutRight.vue"
import LayoutLeftWrap from "./SOASLayoutLeft.vue"
import LayoutCenterWrap from "./SOASLayoutCenter.vue"

export default {
  name: 'SOASupervisionLayout',
  components: {
    LayoutLeftWrap,
    LayoutRightWrap,
    LayoutCenterWrap
  }
}

</script>

<template>
  <div class="soa-supervision-main">
    <LayoutLeftWrap/>
    <LayoutCenterWrap/>
    <LayoutRightWrap/>
  </div>
</template>

<style scoped lang="less">
.soa-supervision-main {
  flex: 1;
  display: flex;
  gap: 28px;
  padding: 0 24px 24px 24px;


  .layout-left-wrap,
  .layout-right-wrap {
    width: 488px;
    height: 100%;
  }

  .layout-center-wrap {
    flex: 1;
    height: 100%;
  }
}
</style>