<script>
import EnterpriseDashboard from './SOAEnterpriseDashboard.vue'
import LargeAmountOfFunds from './SOALargeAmountOfFunds.vue'
import InvestmentSupervision from './SOAInvestmentSupervision.vue'
import AssetClassification from './SOAAssetClassification.vue'

export default {
  name: 'SOASLayoutLeftWrap',
  components: {
    // 企业全景
    EnterpriseDashboard,

    // 大额资金
    LargeAmountOfFunds,

    // 投资监管
    InvestmentSupervision,

    // 资产分类
    AssetClassification
  }
}
</script>

<template>
  <div class="layout-left-wrap">
    <!--    企业全景-->
    <EnterpriseDashboard/>

    <!--    大额资金-->
    <LargeAmountOfFunds/>

    <!--    投资监管-->
    <InvestmentSupervision/>

    <!--    资产分类-->
    <AssetClassification/>
  </div>
</template>

<style scoped lang="less">
.layout-left-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>