<script>
import ForestBambooResourceStats from "./ForestBambooResourceStats.vue"
import ForestProtectionLevelAreaStats from "./ForestProtectionLevelAreaStats.vue"
import ForestProtectionAreaStats from "./ForestProtectionAreaStats.vue"
import ForestUnderForestEconomy from "./ForestUnderForestEconomy.vue"
import ForestLandscape from "./ForestLandscape.vue"

export default {
  name: 'ForestLayoutRight',
  components: {
    // 竹林资源统计
    ForestBambooResourceStats,

    // 森林生态指标
    ForestProtectionLevelAreaStats,

    // 林地保护等级面积统计
    ForestProtectionAreaStats,

    // 林下经济
    ForestUnderForestEconomy,

    // 森林景观
    ForestLandscape
  }
}
</script>

<template>
  <div class="forest-layout-right">
    <!--    竹林资源统计-->
    <ForestBambooResourceStats/>

    <!--    森林生态指标-->
    <ForestProtectionLevelAreaStats/>

    <!--    林地保护等级面积统计-->
    <ForestProtectionAreaStats/>

    <!--    林下经济-->
    <ForestUnderForestEconomy/>

    <!--    森林景观-->
    <ForestLandscape/>
  </div>
</template>

<style scoped lang="less">
.forest-layout-right {
  width: 488px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 1;
}
</style>