<script>
import to from "await-to-js"
import { onMounted, reactive, ref, nextTick } from "vue"

import CardTitle from "./CardTitleComponent.vue"
import GlobalData from "./GlobalData"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getForestLandscapeData } from "./forest.api.service"

export default {
  name: 'ForestLandscape',
  components: {
    CardLeftTopDot,
    CardTitle
  },
  setup() {
    const currentTab = ref()
    const tabList = ref([])

    const dataInfo = reactive({
      // 总产值
      totalValue: 0,
      totalValueUnit: 0,

      // 利用林地面积
      useArea: 0,
      useAreaUnit: 0,

      // 从业人数
      people: 0,
      peopleUnit: 0,

      // 年接待人数
      yearPeople: 0,
      yearPeopleUnit: 0
    })


    const handleTabChange = (key) => {
      currentTab.value = key

      const data = tabList.value.find(item => item.key === key)
      for (const key in data) {
        dataInfo[key] = data[key]
      }
    }

    const init = async () => {
      const [ err, res ] = await to(getForestLandscapeData())
      if (err) return
      console.log('森林景观 -----> ', res)
      const data = res.data

      tabList.value = data.map(item => {
        return {
          ...item,
          label: item.name,
          key: item.name
        }
      })

      currentTab.value = data[0].name

      await nextTick()
      handleTabChange(currentTab.value)
    }

    onMounted(init)

    return {
      GlobalData,
      tabList,
      currentTab,

      dataInfo,

      handleTabChange
    }
  }
}
</script>

<template>
  <div class="forest-landscape">
    <CardTitle title="森林景观" :tab-list="tabList" @tab-change="handleTabChange"/>

    <ul class="content">
      <CardLeftTopDot/>

      <li class="item">
        <span class="name">总产值</span>
        <div class="count">
          <span class="text">{{ dataInfo.totalValue }}</span>
          <span class="unit">万元</span>
        </div>
      </li>

      <li class="item">
        <span class="name">利用林地面积</span>
        <div class="count">
          <span class="text">{{ dataInfo.useArea }}</span>
          <span class="unit">万亩</span>
        </div>
      </li>

      <li class="item">
        <span class="name">从业人数</span>
        <div class="count">
          <span class="text">{{ dataInfo.people }}</span>
          <span class="unit">万人</span>
        </div>
      </li>

      <li class="item">
        <span class="name">年接待人数</span>
        <div class="count">
          <span class="text">{{ dataInfo.yearPeople }}</span>
          <span class="unit">万人次</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="less">
.forest-landscape {
  height: 100px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    position: relative;
    display: flex;
    padding: 8px 16px;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-image: url('@{img_url}forest-landscape-card_bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;


      .name {
        height: 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: #FFFFFF;
      }

      .count {
        height: 35px;
        display: flex;
        align-items: baseline;

        .text {
          color: #FFF;
          text-shadow: 0px 0px 21px rgba(0, 129, 255, 0.40), 0px 0px 21px #0081FF;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
        }

        .unit {
          color: #E5F4FF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
</style>