<script>
import EconomyKeyPointsEnterpriseDevelop from "./EconomyKeyPointsEnterpriseDevelop.vue"
import EconomyIndustrialEnergyConsumption from "./EconomyIndustrialEnergyConsumption.vue"
import EconomyElectricityConsumptionTrends from "./EconomyElectricityConsumptionTrends.vue"
import EconomyAbnormalElectricityConsumption from "./EconomyAbnormalElectricityConsumption.vue"

export default {
  name: 'EconomyLayoutRight',
  components: {
    // 重点企业经济发展
    EconomyKeyPointsEnterpriseDevelop,

    // 规上工业能耗
    EconomyIndustrialEnergyConsumption,

    // 用电趋势
    EconomyElectricityConsumptionTrends,

    // 企业用电异动
    EconomyAbnormalElectricityConsumption
  }
}
</script>

<template>
  <div class="economy-layout-right">
    <!--  重点企业经济发展-->
    <EconomyKeyPointsEnterpriseDevelop/>

    <!--  规上工业能耗-->
    <EconomyIndustrialEnergyConsumption/>

    <!--  用电趋势-->
    <EconomyElectricityConsumptionTrends/>

    <!--  企业用电异动-->
    <EconomyAbnormalElectricityConsumption/>
  </div>
</template>

<style scoped lang="less">
.economy-layout-right {
  width: 488px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 1;
}
</style>