<script >
import GlobalData from "./GlobalData"
export default {
  name: 'cardLeftTopDot',
  props: {
    title: String,
    isClose: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      GlobalData
    }
  }
}
</script>

<template>
  <div class="card_left-top__dot">
    <img :src="GlobalData.getImgUrl('card_left-top__dot.png')" class="icon" alt="">
  </div>
</template>

<style scoped lang="less">
.card_left-top__dot {
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  position: absolute;
  background-image: url('@{img_url}card_left-top__dot_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .icon {
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    position: absolute;
  }
}
</style>