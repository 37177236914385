<template>
  <div id="app" :class="showPage">
    <!--    layout-->
    <LayoutHeader @pageChange="pageChange"/>

    <!--    经济概览-->
    <EconomyLayout v-if="showPage === pageType.economicOverview"/>

    <!--    国资监管-->
    <SOASLayout v-if="showPage === pageType.SOASupervision"/>

    <!--    林业经济-->
    <ForestLayout v-if="showPage === pageType.forestryEconomy"/>
  </div>
</template>

<script>
import { onMounted, onBeforeMount, onBeforeUnmount, getCurrentInstance, ref } from 'vue'
import LayoutHeader from "./LayoutHeader.vue"
import SOASLayout from "./SOASLayout.vue"
import EconomyLayout from "./EconomyLayout.vue"
import ForestLayout from "./ForestLayout.vue"

import pageType from './pageType'
import GlobalData from "./GlobalData"

export default {
  name: 'App',
  components: {
    // layout
    LayoutHeader,

    // 经济概览
    EconomyLayout,

    // 国资监管
    SOASLayout,

    // 林业经济
    ForestLayout
  },
  setup() {
    const instance = getCurrentInstance()
    const showPage = ref(pageType.forestryEconomy)

    const pageChange = (code) => {
      console.log('changePage', code)
      showPage.value = code
      GlobalData.pageType = code
    }

    onMounted(() => {
      if (instance.proxy.$bus) {
        instance.proxy?.$bus.on('pageChange', code => {
          showPage.value = code
          GlobalData.pageType = code
        })
      }
    })

    onBeforeMount(() => {
      if (instance.proxy.$bus) {
        GlobalData.$bus = instance.proxy.$bus
      }
    })

    onBeforeUnmount(() => {
      if (instance.proxy.$bus) {
        instance.proxy?.$bus.all.clear()
      }
    })

    return {
      pageType,

      showPage,
      pageChange

    }
  }
}
</script>

<style lang="less">

* {
  margin: 0;
  padding: 0;
}

#app {
  position: relative;
  width: 1920px;
  height: 1080px;
  background-color: #042A44;
  transform: scale(1);
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// 国资监管
.SOASupervision {
  background-image: url('@{img_url}soa-layout_bg.png');
}

// 林业经济
.forestryEconomy,
  //经济概览
.economicOverview {
  background-image: url('@{img_url}forestry-layout_bg.png');
}

// 重大项目
.majorProject {

}

li {
  list-style: none;
}

// 居中弹窗样式
.dialog__center {
  width: 0;
  height: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  overflow: hidden;
  transition: 0.5s;
  top: 50%;
  left: 50%;

  &.open {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子组件中心移动到父组件中心 */
    background: linear-gradient(180deg, #022140 0%, #033459 100%);
    box-shadow: 0px 0px 20px 0px rgba(0, 164, 245, 0.30) inset;
    z-index: 99;
  }

  & > .header {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('@{img_url}dialog-head_bg.png') no-repeat;
    background-size: 100% 100%;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 12px;
      background: #3DFFFF;
      box-shadow: 0px 0px 10px 0px #3DFFFF;
    }

    .title {
      color: #FFF;
      text-shadow: 0px 0px 9px #15B1FF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 100% */
      flex: 1;
    }

    .close-icon,
    .back-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-right: 20px;
    }

    .back-icon {
      margin-right: 16px;
    }
  }

  & > .content {
    flex: 1;
    padding: 20px 24px 38px 24px;

    & > .head {
      height: 32px;
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .op-area,
      .form-area {
        display: flex;
        gap: 16px;
      }

      .form-area {
        .common-input {
          width: 240px;
          padding: 8px;
          color: #FFFFFF;
          border-radius: 4px;
          background: #07294A;
          border: 1px solid #008CB8;
          box-shadow: 0px 0px 20px 0px rgba(0, 119, 216, 0.50) inset;

          &:hover {
            border: 1px solid #47C9FF;
            background: linear-gradient(0deg, rgba(30, 182, 249, 0.15) 0.31%, rgba(15, 136, 187, 0.50) 49.85%, rgba(0, 90, 124, 0.50) 99.69%);
          }
        }

        input {
          display: flex;
          justify-content: space-between;
          align-items: center;

        }


      }

      .op-area {
        .btn {
          outline: none;
          cursor: pointer;
          color: #CCDDF0;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          display: flex;
          width: 72px;
          height: 32px;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 2px;
          border: 1px solid #008CB8;
          background: radial-gradient(50% 50% at 100% 49.69%, rgba(30, 182, 249, 0.02) 0%, rgba(15, 136, 187, 0.05) 49.85%, rgba(0, 90, 124, 0.05) 100%);
          box-shadow: 0px 0px 9px 0px rgba(64, 199, 252, 0.83) inset;

          &:hover {
            border: 1px solid #47C9FF;
            background: linear-gradient(0deg, rgba(30, 182, 249, 0.15) 0.31%, rgba(15, 136, 187, 0.50) 49.85%, rgba(0, 90, 124, 0.50) 99.69%);
          }
        }

      }
    }

    & > .main {
      flex: 1;
    }
  }

}


// 日期选择器开始
.el-select.el-input,
.el-date-editor.el-input {
  width: 130px !important;
  height: 100% !important;

}

.el-select__wrapper,
.el-input__wrapper {
  color: #ffffff;
  background-color: #011921 !important;
  border-radius: 4px;
  border: 1px solid #008CB8;
  height: 26px !important;
  min-height: 26px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: 0 0 20px 0 #0099d880 inset !important;

  .el-input__inner {
    width: 88px !important;
    height: 26px !important;
    color: #ffffff !important;

    &:hover {
      width: 88px !important;
      height: 26px !important;
    }
  }
}

.el-popper.is-light {
  border: none !important;
  border-radius: 6px !important;
}

.el-select-dropdown__wrap,
.el-picker-panel {
  border-radius: 6px !important;
}

.el-input__prefix-inner > :first-child,
.el-input__prefix-inner > :first-child.el-input__icon {
  color: #33AED8;
  font-size: 17px;
}

.el-select-dropdown__wrap,
.el-picker-panel__body {
  border: 1px solid #02B5FF !important;
  border-radius: 6px;
}

.el-select-dropdown__wrap,
.el-date-table td.in-range .el-date-table-cell {
  background-color: transparent !important;

}

.el-date-range-picker__content.is-left {
  border-right: none !important;
}

.el-select-dropdown__wrap,
.el-picker-panel__body-wrapper {
  // background: #011921;
  background: rgba(5, 50, 80, 1) !important;
  color: #ffffff !important;
  border-radius: 6px !important;
}

.el-date-table td.current:not(.disabled) .el-date-table-cell__text {
  background: #33AED8;
}

.el-date-table th {
  color: #ffffff !important;
  border-bottom: none !important;
}

.el-date-picker__header-label {
  color: #ffffff !important;
}

.el-picker-panel__icon-btn .el-icon {
  color: #ffffff !important;

}

.el-date-picker {
  width: 220px !important;
}

.el-date-picker .el-picker-panel__content {
  width: 200px !important;
}

.el-picker-panel__content {
  margin: 10px !important;
}

.el-date-table td {
  padding: 0px !important;
}

.el-date-table th {
  padding: 0px !important;
}

// 日期选择器结束

// 日期范围选择器
.el-date-editor {
  width: 100% !important;

  .el-range-input {
    color: #ffffff !important;
  }

  .el-range-separator {
    color: #ffffff !important;
  }
}

// selectd
.el-select {
  height: 30px !important;
}

.el-select__placeholder {
  color: #ffffff !important;

  &.is-transparent {
    color: var(--el-text-color-placeholder) !important;
  }
}

.el-select-dropdown__item.is-hovering {
  background-color: rgba(5, 50, 80, 1) !important;
  color: var(--el-color-primary) !important;
}
</style>
