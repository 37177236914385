<script>
import { defineExpose, reactive, ref, toRaw, markRaw, nextTick, computed } from "vue"
import GlobalData from "./GlobalData"
import SwitchTabComponent from "./SwitchTabComponent.vue"

export default {
  name: 'SOADataDashboardDialog',
  components: {
    SwitchTabComponent
  },
  setup() {
    const getFullYear = new Date().getFullYear()
    const enterpriseDate = ref(getFullYear)
    const enterpriseDetailsDate = ref(getFullYear)
    const showStep = ref('list')
    const dialogVisible = ref(false)
    const dialogTitle =computed(() => {
      return showStep.value === 'list' ? '企业营收排名' : '企业经营财报'
    })
    const searchDetailsForm = reactive({
      date: ''
    })
    const searchListForm = reactive({
      keywords: '',
      date: ''
    })

    const enterpriseDetailsShowType = ref('financialReport')
    const enterpriseDetailsTabList = ref([
      {
        label: '财报',
        key: 'financialReport'
      },
      {
        label: '分析',
        key: 'analysis'
      }
    ])

    // 分析 --------------------------------------------------------
    const financialReportShowType = ref('sales')
    const financialReportTabTitle = computed(() => {
      return financialReportShowTypeTabList.value.find(item => item.key === financialReportShowType.value).label
    })
    const financialReportShowTypeTabList = ref([
      {
        label: '营收月度情况',
        key: 'sales'
      },
      {
        label: '利润月度情况',
        key: 'profit'
      },
      {
        label: '利润率月度情况',
        key: 'profitRate'
      }
    ])

    // 收入预借
    const incomeAdvanceEchartsRef = ref(null)
    const incomeAdvanceEchartsSelf = ref(null)

    // 支出预借
    const expenseAdvanceEchartsRef = ref(null)
    const expenseAdvanceEchartsSelf = ref(null)

    // 营收月度情况
    const salesEchartsRef = ref(null)
    const salesEchartsSelf = ref(null)

    // 利润月度情况
    const profitEchartsRef = ref(null)
    const profitEchartsSelf = ref(null)

    // 利润率月度情况
    const profitRateEchartsRef = ref(null)
    const profitRateEchartsSelf = ref(null)

    /**
     * 打开弹窗
     */
    const open = () => {
      resetForm()
      showStep.value = 'list'
      dialogVisible.value = true
    }

    /**
     * 返回企业列表
     */
    const backList = () => {
      showStep.value = 'list'
    }

    /**
     * 查看企业详情
     */
    const handleClickEnterprise = () => {
      showStep.value = 'enterprise'
      dialogVisible.value = true
    }

    /**
     * 切换分析/财报
     * @param key
     */
    const handleEnterpriseDetailsTabChange = (key) => {
      enterpriseDetailsShowType.value = key

      if (key === 'analysis') {
        renderAdvanceEcharts()
        renderFinancialReportEcharts()
      }
    }

    /**
     * 根据日期筛选企业
     * @param key
     */
    const handleDialogListDateChange = (key) => {
      console.log(key)
      searchListForm.date = key
    }

    /**
     * 重置企业列表搜索参数
     */
    const resetForm = () => {
      searchListForm.keywords = ''
      searchListForm.date = ''
    }

    /**
     * 渲染 收入预借，支出预借 图表
     */
    const renderAdvanceEcharts = () => {
      nextTick(() => {
        // 收入预借
        if (toRaw(incomeAdvanceEchartsSelf.value)) {
          incomeAdvanceEchartsSelf.value.dispose()
        }

        incomeAdvanceEchartsSelf.value = markRaw(window.echarts.init(incomeAdvanceEchartsRef.value, null, {
          renderer: 'canvas'
        }))

        incomeAdvanceEchartsSelf.value.setOption({
          ...getOption(3, [ '#F2C94C', '#D6F4FF' ]),
          ...getStreet('3%', '#F2C94C')
        })

        // 支出预借
        if (toRaw(expenseAdvanceEchartsSelf.value)) {
          expenseAdvanceEchartsSelf.value.dispose()
        }

        expenseAdvanceEchartsSelf.value = markRaw(window.echarts.init(expenseAdvanceEchartsRef.value, null, {
          renderer: 'canvas'
        }))

        expenseAdvanceEchartsSelf.value.setOption({
          ...getOption(4, [ '#63D4C0', '#D6F4FF' ]),
          ...getStreet('3%', '#63D3BE')
        })

      })
    }

    /**
     * 根据日期筛选企业财报
     * @param key
     */
    const handleDialogDetailsDateChange = (key) => {
      console.log(key)
      searchDetailsForm.date = key
      enterpriseDetailsShowType.value === 'analysis' ? renderFinancialReportEcharts() : getFinancialReportData()
    }

    /**
     * 切换财报类型
     * @param key
     */
    const handleFinancialReportShowTypeTabTabChange = (key) => {
      financialReportShowType.value = key
      // todo 接口 搜索
      renderFinancialReportEcharts()
    }

    /**
     * 渲染财报图表
     */
    const renderFinancialReportEcharts = () => {
      if (financialReportShowType.value === 'sales') {
        initSalesEcharts()
      }
      else if (financialReportShowType.value === 'profit') {
        initProfitEcharts()
      }
      else if (financialReportShowType.value === 'profitRate') {
        initProfitRateEcharts()
      }
    }

    /**
     * 渲染营收月度情况图表
     */
    const initSalesEcharts = () => {
      nextTick(() => {
        if (toRaw(salesEchartsSelf.value)) {
          salesEchartsSelf.value.dispose()
        }

        salesEchartsSelf.value = markRaw(window.echarts.init(salesEchartsRef.value, null, {
          renderer: 'canvas'
        }))

        salesEchartsSelf.value.setOption({
          grid: {
            top: '22%',       // 图表区域距顶部的距离
            bottom: '5%',     // 图表区域距底部的距离
            left: '2%',       // 图表区域距左侧的距离
            right: '2%',      // 图表区域距右侧的距离
            containLabel: true // 确保刻度标签（如轴标签）被包含在图表区域内
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'horizontal', // 水平方向排列
            right: '10px', // 距离右边的距离
            top: '10px',   // 距离顶部的距离
            textStyle: {
              color: '#FFFFFF'    // 字体颜色为白色
            },
            data: [ '营业收入', '同比' ]
          },
          xAxis: [
            {
              type: 'category',
              data: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              name: '单位：万元',
              type: 'value',
              min: 0,
              max: 250,
              interval: 50
            },
            {
              type: 'value',
              name: '',
              min: 0,
              max: 25,
              interval: 5,
              axisLabel: {
                formatter: '{value}%'
              }
            }
          ],
          series: [
            {
              name: '营业收入',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元'
                }
              },
              itemStyle: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#27E5A1'
                  }, // 顶部颜色
                  {
                    offset: 1,
                    color: '#36E3DE'
                  } // 底部颜色
                ])
              },
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
              ]
            },
            {
              name: '同比',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#2FB3FF', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2 ]
            }
          ]
        })
      })
    }

    /**
     * 利润月度情况图表
     */
    const initProfitEcharts = () => {
      nextTick(() => {
        if (toRaw(profitEchartsSelf.value)) {
          profitEchartsSelf.value.dispose()
        }

        profitEchartsSelf.value = markRaw(window.echarts.init(profitEchartsRef.value, null, {
          renderer: 'canvas'
        }))

        profitEchartsSelf.value.setOption({
          grid: {
            top: '22%',       // 图表区域距顶部的距离
            bottom: '5%',     // 图表区域距底部的距离
            left: '2%',       // 图表区域距左侧的距离
            right: '2%',      // 图表区域距右侧的距离
            containLabel: true // 确保刻度标签（如轴标签）被包含在图表区域内
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'horizontal', // 水平方向排列
            right: '10px', // 距离右边的距离
            top: '10px',   // 距离顶部的距离
            textStyle: {
              color: '#FFFFFF'    // 字体颜色为白色
            },
            data: [ '总利润', '净利润', '总利润同比', '净利润同比' ]
          },
          xAxis: [
            {
              type: 'category',
              data: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              name: '单位：万元',
              type: 'value',
              min: 0,
              max: 250,
              interval: 50
            },
            {
              type: 'value',
              name: '',
              min: 0,
              max: 25,
              interval: 5,
              axisLabel: {
                formatter: '{value}%'
              }
            }
          ],
          series: [
            {
              name: '总利润',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元'
                }
              },
              itemStyle: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#1890FF'
                  }, // 顶部颜色
                  {
                    offset: 1,
                    color: '#36C4E3'
                  } // 底部颜色
                ])
              },
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
              ]
            },
            {
              name: '净利润',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元'
                }
              },
              itemStyle: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#F5E74F'
                  }, // 顶部颜色
                  {
                    offset: 1,
                    color: '#FCE9AE'
                  } // 底部颜色
                ])
              },
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
              ]
            },
            {
              name: '总利润同比',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#59FFFF', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 23.0, 16.5, 12.0, 6.2, 2.0, 23.4, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3 ]
            },
            {
              name: '净利润同比',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#FF8E59', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2 ]
            }
          ]
        })
      })
    }

    /**
     * 利润率月度情况图表
     */
    const initProfitRateEcharts = () => {
      console.log(profitRateEchartsSelf.value)
      nextTick(() => {
        if (toRaw(profitRateEchartsSelf.value)) {
          profitRateEchartsSelf.value.dispose()
        }

        profitRateEchartsSelf.value = markRaw(window.echarts.init(profitRateEchartsRef.value, null, {
          renderer: 'canvas'
        }))

        profitRateEchartsSelf.value.setOption({
          grid: {
            top: '22%',       // 图表区域距顶部的距离
            bottom: '5%',     // 图表区域距底部的距离
            left: '2%',       // 图表区域距左侧的距离
            right: '2%',      // 图表区域距右侧的距离
            containLabel: true // 确保刻度标签（如轴标签）被包含在图表区域内
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'horizontal', // 水平方向排列
            right: '10px', // 距离右边的距离
            top: '10px',   // 距离顶部的距离
            textStyle: {
              color: '#FFFFFF'    // 字体颜色为白色
            },
            data: [ '利润率', '净利润率', '归母利润率' ]
          },
          xAxis: [
            {
              type: 'category',
              data: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              name: '单位：万元',
              type: 'value',
              min: 0,
              max: 250,
              interval: 50
            },
            {
              type: 'value',
              name: '',
              min: 0,
              max: 25,
              interval: 5,
              axisLabel: {
                formatter: '{value}%'
              }
            }
          ],
          series: [
            {
              name: '利润率',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#FFF759', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 23.4, 2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.0, 16.5, 12.0, 6.2 ]
            },
            {
              name: '净利润率',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#59FFFF', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 2.0, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2, 2.2, 3.3, 4.5, 6.3, 10.2 ]
            },
            {
              name: '归母利润率',
              type: 'line',
              yAxisIndex: 1,
              smooth: true, // 启用圆润过渡
              lineStyle: {
                color: '#FF8E59', // 设置折线的颜色，例如红色
                width: 2          // 可选，调整折线宽度
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              data: [ 4.5, 6.3, 2.0, 2.2, 3.3, 23.0, 16.5, 12.0, 6.2, 10.2, 20.3, 23.4 ]
            }
          ]
        })
      })
    }

    const getOption = (num, colorArr) => {
      return {
        tooltip: {
          formatter: function () {
            return '进度3%'
          }
        },
        angleAxis: {
          startAngle: 150,
          max: 9,
          clockwise: true,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        polar: {
          radius: '150%'
        },
        series: [
          {
            type: 'bar',
            data: [ num ],
            showBackground: true,
            backgroundStyle: {
              color: '#EFF2F5',
              borderColor: '#EFF2F5',
              borderWidth: 1
            },
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 3,
            silent: true,
            itemStyle: {
              normal: {
                opacity: 1,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: colorArr[0]
                    },

                    {
                      offset: 1,
                      color: colorArr[1]
                    }
                  ],
                  global: false
                },
                borderWidth: 3
              }
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            hoverAnimation: false,
            radius: '60%',
            center: [ '50%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]),
                shadowBlur: 10
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
    }

    const getStreet = (text, fill) => {
      return {
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              fontSize: 24,
              fontWeight: 900,
              text, // 显示的文字
              fill, // 文字颜色
              textAlign: 'center',
              textBaseline: 'middle'
            }
            // 偏移位置，确保文字居中
            // position: ['-50%', '-50%']
          }
        ]
      }
    }

    /**
     * 获取财报详细数据
     */
    const getFinancialReportData = () => {
      console.log('获取财报详细数据')
    }

    defineExpose({
      open
    })

    return {
      GlobalData,

      showStep,
      dialogTitle,
      dialogVisible,
      searchListForm,
      searchDetailsForm,
      enterpriseDetailsTabList,
      enterpriseDetailsShowType,
      financialReportShowType,
      financialReportTabTitle,
      financialReportShowTypeTabList,

      // 收入预借 Echarts
      incomeAdvanceEchartsRef,

      // 支出预借 Echarts,
      expenseAdvanceEchartsRef,

      // 营收月度情况
      profitEchartsRef,

      // 利润月度情况
      salesEchartsRef,

      // 利润率月度情况
      profitRateEchartsRef,

      enterpriseDate,
      enterpriseDetailsDate,

      open,
      backList,
      handleClickEnterprise,
      handleDialogListDateChange,
      handleDialogDetailsDateChange,
      handleEnterpriseDetailsTabChange,
      handleFinancialReportShowTypeTabTabChange
    }
  }
}
</script>

<template>
  <div class="dialog__center soa-data-dashboard-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">{{ dialogTitle }}</span>
      <img v-if="showStep !== 'list'" @click="backList" :src="GlobalData.getImgUrl('back-dialog_icon.png')"
           class="back-icon" alt="">
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>

    <div class="content">
      <!--        企业列表-->
      <template v-if="showStep === 'list'">
        <div class="head">
          <div class="form-area">
            <input class="common-input" type="text" v-model="searchListForm.keywords" placeholder="请输入企业名称进行查找"/>
            <el-date-picker
                type="date"
                class="calendar"
                v-model="enterpriseDate"
                placeholder="请选择"
                value-format="YYYY-MM-DD"
                :clearable="false"
                @change="handleDialogListDateChange"
            />
          </div>
        </div>
        <div class="main list">
          <ul class="head">
            <li class="th-item index">营收排名</li>
            <li class="th-item enterprise-name">企业名称</li>
            <li class="th-item operating-income">营业收入</li>
            <li class="th-item total-profit">利润总额</li>
            <li class="th-item net-profit">净利润</li>
          </ul>
          <div class="table-wrap">
            <ul class="tables">
              <li class="tables-tr" @click="handleClickEnterprise(i)" v-for="i in 20"
                  :key="i+'-data-dashboard-enterprise'">
                <div class="td-item index">{{ i }}</div>
                <div class="td-item enterprise-name">峨边彝族自治县国有资产经营管理有限公司</div>
                <div class="td-item operating-income">
                  <span>10.00亿</span>
                  <div class="proportion decline">
                    <span class="text">10.5%</span>
                    <img :src="GlobalData.getImgUrl('decline_icon.png')" class="icon" alt="">
                  </div>
                </div>
                <div class="td-item total-profit">
                  <span>6.25亿</span>
                  <div class="proportion up">
                    <span class="text">10.5%</span>
                    <img :src="GlobalData.getImgUrl('up_icon.png')" class="icon" alt="">
                  </div>
                </div>
                <div class="td-item net-profit">
                  <span>0.98亿</span>
                  <div class="proportion decline">
                    <span class="text">10.5%</span>
                    <img :src="GlobalData.getImgUrl('decline_icon.png')" class="icon" alt="">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <!--        企业详情-->
      <template v-if="showStep === 'enterprise'">
        <div class="enterprise-details">
          <div class="dash-info">
            <div class="enterprise-name">
              <span>峨边彝族自治县国有资产经营管理有限公司</span>
            </div>

            <ul class="info-list">
              <li class="info-item">
                <p class="label">企业类别：</p>
                <p class="value">国有全资企业</p>
              </li>
              <li class="info-item">
                <p class="label">主要行业：</p>
                <p class="value">投资与资产管理</p>
              </li>
              <li class="info-item">
                <p class="label">统一社会信用代码：</p>
                <p class="value">91511132662791219A</p>
              </li>
              <li class="info-item">
                <p class="label">注册日期：</p>
                <p class="value">2007-07-12</p>
              </li>
              <li class="info-item">
                <p class="label">注册资本：</p>
                <p class="value">100000万元</p>
              </li>
              <li class="info-item">
                <p class="label">注册地址：</p>
                <p class="value">四川省-乐山市-峨边彝族自治县</p>
              </li>
            </ul>
          </div>

          <div class="details">
            <div class="head">
              <el-date-picker
                  type="year"
                  class="calendar"
                  v-model="enterpriseDetailsDate"
                  placeholder="请选择"
                  value-format="YYYY"
                  :clearable="false"
                  @change="handleDialogDetailsDateChange"
              />
              <SwitchTabComponent
                  v-if="enterpriseDetailsTabList.length > 0"
                  :list="enterpriseDetailsTabList"
                  @tab-change="handleEnterpriseDetailsTabChange"
              />
            </div>

            <!--              财报-->
            <template v-if="enterpriseDetailsShowType === 'financialReport'">
              <div class="financial-report">
                <ul class="dash">
                  <li class="item">
                    <img :src="GlobalData.getImgUrl('data-dashboard-dialog-sales_icon.png')" class="icon" alt="">
                    <div class="info">
                      <p class="name">营业收入</p>
                      <div class="value">
                        <div class="price">
                          <span class="num">4267</span>
                          <span class="unit">万元</span>
                        </div>
                        <div class="proportion up">
                          <span class="text">10.5%</span>
                          <img :src="GlobalData.getImgUrl('up_icon.png')" class="icon" alt="">
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="item">
                    <img :src="GlobalData.getImgUrl('data-dashboard-dialog-all-tet-profit_icon.png')" class="icon"
                         alt="">
                    <div class="info">
                      <p class="name">利润总额</p>
                      <div class="value">
                        <div class="price">
                          <span class="num">4267</span>
                          <span class="unit">万元</span>
                        </div>
                        <div class="proportion decline">
                          <span class="text">10.5%</span>
                          <img :src="GlobalData.getImgUrl('decline_icon.png')" class="icon" alt="">
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="item">
                    <img :src="GlobalData.getImgUrl('data-dashboard-dialog-tet-profit_icon.png')" class="icon" alt="">
                    <div class="info">
                      <p class="name">净利润</p>
                      <div class="value">
                        <div class="price">
                          <span class="num">4267</span>
                          <span class="unit">万元</span>
                        </div>
                        <div class="proportion decline">
                          <span class="text">10.5%</span>
                          <img :src="GlobalData.getImgUrl('decline_icon.png')" class="icon" alt="">
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul class="dash-list">
                  <li class="dash-item">
                    <p class="name">营业成本</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">税金及附加</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">销售费用</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">研发费用</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">财务费用</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">劳动生产总值</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">职工薪酬</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">应缴税费总额</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">投资收益</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">计提折旧额</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">工业总产值</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">工业销售产值</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">资产减值损失</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">信用减值损失</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                  <li class="dash-item">
                    <p class="name">资产处置收益</p>
                    <div class="price">
                      <span class="num">4267</span>
                      <span class="unit">万元</span>
                    </div>
                  </li>
                </ul>
              </div>
            </template>

            <!--              分析-->
            <template v-if="enterpriseDetailsShowType === 'analysis'">
              <div class="analysis">
                <div class="advance-echarts">
                  <div class="item">
                    <div class="head"><span>收入预支百分比</span></div>
                    <div class="data-wrap">
                      <div class="echarts-wrap" ref="incomeAdvanceEchartsRef"></div>
                      <div class="data-info">
                        <div class="data-item">
                          <span class="label">收入预支(万元)</span>
                          <span class="val">5,000</span>
                        </div>
                        <div class="data-item">
                          <span class="label">收入差值(万元)</span>
                          <span class="val">5,000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="head"><span>支出预支百分比</span></div>
                    <div class="data-wrap">
                      <div class="echarts-wrap" ref="expenseAdvanceEchartsRef"></div>
                      <div class="data-info">
                        <div class="data-item">
                          <span class="label">支出预支(万元)</span>
                          <span class="val">5,000</span>
                        </div>
                        <div class="data-item">
                          <span class="label">支出差值(万元)</span>
                          <span class="val">5,000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="financial-report-echarts">
                  <div class="head">
                    <span>{{ financialReportTabTitle }}</span>
                    <SwitchTabComponent
                        v-if="financialReportShowTypeTabList.length > 0"
                        :list="financialReportShowTypeTabList"
                        @tab-change="handleFinancialReportShowTypeTabTabChange"/>
                  </div>

                  <!--                营收月度情况 Echarts-->
                  <div
                      class="echarts-wrap"
                      ref="salesEchartsRef"
                      v-if="financialReportShowType === 'sales'"
                  ></div>

                  <!--                利润月度情况 Echarts-->
                  <div
                      class="echarts-wrap"
                      ref="profitEchartsRef"
                      v-if="financialReportShowType === 'profit'"
                  ></div>

                  <!--                利润率月度情况 Echarts-->
                  <div
                      class="echarts-wrap"
                      ref="profitRateEchartsRef"
                      v-if="financialReportShowType === 'profitRate'"
                  ></div>
                </div>

              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.soa-data-dashboard-dialog {
  &.open {
    width: 1172px;
    height: auto;
    max-height: 665px;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }

  // 企业列表
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    .head {
      height: 48px;
      padding: 0 12px;
      display: flex;
      gap: 12px;
      align-items: center;
      background: rgba(50, 193, 255, 0.12);
    }

    .th-item,
    .td-item {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      list-style: none;

      &.index {
        width: 96px;
        text-align: center;
      }

      &.enterprise-name {
        flex: 1;
        text-align: left;
        padding: 0 40px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
      }

      &.operating-income,
      &.total-profit,
      &.net-profit {
        width: 188px;
        text-align: left;
        display: flex;
        gap: 4px;
        align-items: center;

        .proportion {
          width: 60px;
          height: 20px;
          display: flex;
          padding: 2px;
          border-radius: 2px;
          align-items: center;
          justify-content: space-between;

          &.up {
            background: #682639;
          }

          &.decline {
            background: #1C594A;
          }

          .text {
            color: #F2F6FA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .th-item {
      font-size: 18px;

      &.enterprise-name {
        text-align: center;
      }
    }

    .table-wrap {
      flex: 1;
      overflow-y: auto; /* 允许内容垂直滚动 */
      min-height: 0; /* 确保在弹性布局中可伸缩 */
    }

    .tables {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .tables-tr {
        cursor: pointer;
        height: 48px;
        display: flex;
        gap: 12px;
        padding: 0 16px;
        align-items: center;
        list-style: none;
        border: 1px solid transparent;

        /* 偶数元素 */

        &:nth-child(even) {
          background: url('@{img_url}data-dashboard-dialog-tr_bg.png') no-repeat;
          background-size: 100% 100%;
        }

        /* 奇数元素 */

        &:nth-child(odd) {
          background: transparent;
        }

        &:hover {
          border-radius: 3px;
          border: 1px solid rgba(68, 139, 169, 0.50);
          background: rgba(10, 74, 118, 0.40);
          box-shadow: 0px 0px 21.8px 0px rgba(64, 199, 252, 0.48) inset;
        }

      }
    }
  }

  // 企业详情
  .enterprise-details {
    height: 484px;
    display: flex;
    gap: 22px;

    .dash-info {
      width: 342px;
      display: flex;
      flex-direction: column;
      border-radius: 2px;
      border: 1px solid rgba(21, 177, 255, 0.50);
      background: url('@{img_url}data-dashboard-dialog-enterprise-info_bg.png') no-repeat;
      background-size: 100% 100%;

      .enterprise-name {
        height: 32px;
        padding: 0 14px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        background: url('@{img_url}data-dashboard-dialog-enterprise-name_bg.png') no-repeat;
        background-size: 100% 100%;

        span {
          flex: 1;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
          text-shadow: 0px 0px 12px #1182E4;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          background: linear-gradient(0deg, #B1DBFF 20.83%, #EDF7FF 87.5%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .info-list {
        flex: 1;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .info-item {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .label,
          .value {
            height: 16px;
            color: #9DD4FF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }

          .value {
            color: #FFF;
          }
        }
      }
    }

    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 2px;
      border: 1px solid rgba(21, 177, 255, 0.50);
      background: url('@{img_url}data-dashboard-dialog-enterprise-details_bg.png') no-repeat;
      background-size: 100% 100%;

      .head {
        display: flex;
        justify-content: space-between;

        .calendar {
          width: 240px;
          padding: 8px;
          border-radius: 4px;
        }
      }
    }
  }

  // 企业详情 -> 财报
  .financial-report {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .dash {
      height: 72px;
      display: flex;
      background: url('@{img_url}data-dashboard-dialog-enterprise-details_bg1.png') no-repeat;
      background-size: 100% 100%;

      .item {
        flex: 1;
        padding: 0 16px;
        display: flex;
        gap: 12px;
        align-items: center;

        & > .icon {
          width: 32px;
          height: 48px;
        }

        & > .info {
          flex: 1;
          height: 42px;
          padding-top: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .name {
            color: #B9E0FF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }

          .value {
            display: flex;
            gap: 6px;
            align-items: baseline;
            height: 20px;

            .price {
              height: 20px;
              display: flex;
              align-items: baseline;

              .num {
                height: 20px;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              .unit {
                color: #E5F4FF;
                leading-trim: both;
                text-edge: cap;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 157.143% */
              }
            }

            .proportion {
              width: 60px;
              height: 20px;
              display: flex;
              padding: 2px;
              border-radius: 2px;
              align-items: center;
              justify-content: space-between;

              &.up {
                background: #682639;
              }

              &.decline {
                background: #1C594A;
              }

              .text {
                color: #F2F6FA;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }

              .icon {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    .dash-list {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(5, 1fr); /* 每行 3 列，每列宽度相等 */
      grid-template-rows: repeat(3, 1fr); /* 总共 2 行，每行高度相等 */
      gap: 12px 12px; /* 列和行之间的间隔 */

      .dash-item {
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        background: rgba(50, 193, 255, 0.12);

        &:hover {
          border: 1px solid rgba(68, 139, 169, 0.50);
          background: rgba(10, 74, 118, 0.40);
          box-shadow: 0px 0px 21.8px 0px rgba(64, 199, 252, 0.48) inset;
        }

        .name {
          height: 22px;
          color: #F2F6FA;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        .price {
          height: 17px;
          display: flex;
          align-items: baseline;

          .num {
            height: 18px;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            background: linear-gradient(180deg, #FFF 6.42%, #3ECBF8 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .unit {
            color: #E5F4FF;
            leading-trim: both;
            text-edge: cap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 157.143% */
          }
        }

      }
    }
  }

  // 企业详情 -> 分析
  .analysis {
    flex: 1;
    display: flex;
    gap: 16px;
    flex-direction: column;

    // 收入预支百分比 支出预支百分比
    .advance-echarts {
      height: 172px;
      display: flex;
      gap: 16px;

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(38, 64, 95, 0.86);
        background: rgba(13, 50, 124, 0.01);

        .head {
          height: 32px;
          padding-left: 30px;
          line-height: 32px;
          background: url('@{img_url}data-dashboard-dialog-analysis-title_bg1.png') no-repeat;
          background-size: 100% 100%;

          span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            background: linear-gradient(180deg, #85E9FF 6.42%, #2993FB 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .data-wrap {
          flex: 1;
          width: 100%;
          display: flex;
          gap: 12px;
          align-items: center;

          .echarts-wrap {
            width: 140px;
            height: 100%;
          }

          .data-info {
            flex: 1;
            height: 108px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 12px;

            .data-item {
              height: 46px;
              display: flex;
              padding: 0 12px;
              align-items: center;
              justify-content: space-between;
              background: url('@{img_url}data-dashboard-dialog-analysis-title_bg3.png') no-repeat;
              background-size: 100% 100%;

              .label {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
              }

              .val {
                text-align: right;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                color: #63D4C0;
              }
            }
          }
        }
      }
    }

    // 营收月度情况
    .financial-report-echarts {
      flex: 1;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(38, 64, 95, 0.86);
      background: rgba(13, 50, 124, 0.01);

      .head {
        height: 32px;
        display: flex;
        line-height: 32px;
        padding-left: 30px;
        align-items: center;
        justify-content: space-between;
        background: url('@{img_url}data-dashboard-dialog-analysis-title_bg2.png') no-repeat;
        background-size: 100% 100%;

        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          background: linear-gradient(180deg, #85E9FF 6.42%, #2993FB 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .echarts-wrap {
        flex: 1;
        width: 100%;
      }
    }
  }
}
</style>