<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import CardLeftTopDot from "./CardLeftTopDotComponent.vue"
import { getForestLandAreaStatisticsData } from "./forest.api.service"

export default {
  name: 'ForestLandAreaStatistics',
  components: {
    CardTitle,
    CardLeftTopDot
  },
  setup() {
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const echartsData = reactive({
      list: [],
      total: ''
    })

    const getTypeEchartsData = (config) => {
      const processedData = config.list.map(item => ({
        ...item,
        value: item.val.toString(),  // 将 val 映射为 value
        name: item.name // 将 label 映射为 name
      }))

      return {
        // backgroundColor: '#364686',
        tooltip: {
          trigger: 'item',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,.5)',
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            lineHeight: 30
          },
          padding: [ 10, 10 ],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .5)'
        },
        color: [ '#1FC6FF', '#59FFFF', '#F5E74F', '#FF386B', '#1FC6FF', '#DEA700', '#01BB6D', '#FF75CE' ],
        title: [
          {
            text: '总面积',
            x: '14%',
            top: '32%',
            textAlign: 'center',
            textStyle: {
              fontSize: 13,
              color: '#ffffff',
              align: 'center',
              width: '150px',
              height: '150px'
            }
          },
          {
            text: config.total, // x: '25%',
            x: '14%',
            top: '47%',
            textAlign: 'center',
            textStyle: {
              color: '#fff',
              fontSize: 15,
              fontWeight: '600',
              align: 'center',
              width: '150px',
              height: '150px'
            }
          }
        ],
        legend: {
          orient: 'vertical',
          top: 'middle',
          left: '33%',
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 5,
          icon: 'circle',
          textStyle: {
            fontSize: 14,
            color: '#B4C0CC',
            lineHeight: 25,
            rich: {
              name: {
                width: 150,
                align: 'left',
                fontSize: 14
              },
              value: {
                align: 'left',             // 文本右对齐
                padding: [ 0, 8, 0, 0 ],      // 移除内边距
                color: '#B4C0CC',              // 文本颜色
                fontSize: 14,               // 字体大小
                borderWidth: 0,             // 移除边框
                borderColor: 'transparent'  // 透明边框，避免影响布局
              }
            },
            wrapper: {
              display: 'flex' // 模拟 display:flex 布局
            }
          },
          pageTextStyle: {
            color: '#FFFFFF',
            fontSize: 12
          },
          formatter: (name) => {
            const data_ = processedData.find(i => i.name === name)
            const space_ = '\u0020\u0020'
            // return `{name|${ data_.name }} {value|${ data_.value }}`+space_
            return `{name|${ data_.name }}` + space_
          }
        },
        polar: {
          radius: [ '40%', '50%' ],
          center: [ '50%', '50%' ]
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'pie',
            radius: [ '65%', '75%' ],
            center: [ '15%', '50%' ],
            data: processedData,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderColor: '#364684',
                borderWidth: 0,
                borderRadius: 100
                // shadowColor: 'rgba(0,47,66, 0.5)',
                // shadowBlur: 10
              }
            },
            tooltip: {
              position: [ '50%', '50%' ]
            },
            label: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: '58%',
            hoverAnimation: false,
            center: [ '15%', '50%' ],
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: new window.echarts.graphic.RadialGradient(0.5, 0.5, 1, [ {
                  offset: 1,
                  color: 'rgba(50,171,241, 1)'
                }, {
                  offset: 0,
                  color: 'rgba(55,70,130, 0)'
                } ]), // borderWidth: 1,
                // borderColor: '',
                shadowBlur: 10
                // shadowColor: 'rgba(55,70,130, 1)'
              }
            },
            data: [ {
              value: 100
            } ]
          }
        ]
      }
    }

    const initEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))

      echartsSelf.value.setOption(getTypeEchartsData(toRaw(echartsData), '总面积'))
      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async () => {
      const [ err, res ] = await to(getForestLandAreaStatisticsData())
      if (err) return
      console.log('各类土地面积统计 -----> ', res)

      const data = res.data
      echartsData.list = data?.data || []
      echartsData.total = data?.area_total || 0

      await nextTick()
      initEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef
    }
  }
}
</script>

<template>
  <div class="forest-land-area-statistics">
    <CardTitle title="各类土地面积统计"/>

    <div class="content">
      <CardLeftTopDot/>

      <div class="chart" ref="echartsRef"></div>
    </div>
  </div>
</template>

<style scoped lang="less">
.forest-land-area-statistics {
  height: 180px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  .content {
    flex: 1;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>