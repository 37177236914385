<script>
import to from "await-to-js"
import { markRaw, nextTick, onMounted, reactive, ref, toRaw } from "vue"

import GlobalData from "./GlobalData"
import CardTitle from "./CardTitleComponent.vue"
import { getEconomyDashboardData } from "./economy.api.service"

export default {
  name: 'EconomyDashboard',
  components: {
    CardTitle
  },
  setup() {
    const navList = ref([ '地区生产总值', '固定资产投资', '社会消费品零售状况', '税收', '公共财政收入', '公共财政支出' ])
    const dataObj = reactive([])

    const echartsData = ref([])
    const echartsRef = ref(null)
    const echartsSelf = ref(null)

    const getEchartsOption = () => {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          show: false // 隐藏工具栏
        },
        grid: {
          left: '3%',   // 左侧距离
          right: '3%',  // 右侧距离
          top: '20%',    // 顶部距离
          bottom: '5%', // 底部距离
          containLabel: true // 确保标签不被裁剪
        },
        legend: {
          right: 10,
          top: -5,
          textStyle: {
            color: "#fff"
          },
          data: [ '生产总值', '累计±%' ]
        },
        xAxis: [
          {
            type: 'category',
            data: [ '2月', '4月', '6月', '8月', '10月', '12月' ],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位：万元',
            min: 0,
            top: 5,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '生产总值',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 万元'
              }
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4
            ],
            barWidth: 12,
            itemStyle: {
              normal: {
                color: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [ {
                  offset: 0,
                  color: '#27E5A1'
                }, {
                  offset: 1,
                  color: '#36E3DE'
                } ])
              }
            }
          },
          {
            name: '累计±%',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%'
              }
            },
            data: [ 2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0],
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: '#2FB3FF'
              },
              borderColor: '#2FB3FF'
            },
          }
        ]
      }
    }

    const renderEcharts = () => {
      if (toRaw(echartsSelf.value)) {
        echartsSelf.value.dispose()
      }

      echartsSelf.value = markRaw(window.echarts.init(echartsRef.value, null, {
        renderer: 'canvas'
      }))
      echartsSelf.value.setOption(getEchartsOption(toRaw(echartsData.value)))

      setTimeout(function () {
        window.onresize = function () {
          echartsSelf.value.resize()
        }
      }, 200)
    }

    const init = async () => {
      const [ err, res ] = await to(getEconomyDashboardData())
      if (err) return
      console.log('宏观概览 -----> ', res)

      await nextTick()
      renderEcharts()
    }

    onMounted(init)

    return {
      GlobalData,

      echartsRef,
      navList,
      dataObj
    }
  }
}
</script>

<template>
  <div class="economy-dashboard">
    <CardTitle title="宏观概览"/>
    <div class="content">
      <ul class="nav-list">
        <li class="nav-item" v-for="nav in navList" :key="nav">
          <span class="name">{{ nav }}</span>
          <img :src="GlobalData.getImgUrl('economy-dashboard-nav_icon.png')" class="icon" alt="icon">
        </li>
      </ul>
      <div class="details">
        <div class="dashboard-info">
          <img :src="GlobalData.getImgUrl('economy-dashboard-info_icon.png')" class="icon" alt="icon">
          <div class="item-wrap">
            <p class="name">本月总额</p>
            <div class="value">
              <span class="num">321.5</span>
              <span class="unit">万元</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="item-wrap">
            <p class="name">全年累计</p>
            <div class="value">
              <span class="num">0.457</span>
              <span class="unit">亿元</span>
            </div>
          </div>
        </div>
        <h1 class="subtitle">
          <i class="dot"></i>
          <span>累计增长趋势</span>
        </h1>
        <div class="chats" ref="echartsRef"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.economy-dashboard {
  height: 266px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    flex: 1;
    display: flex;
    gap: 12px;
    position: relative;
    border: 1px solid rgba(38, 64, 95, 0.86);
    background: rgba(159, 191, 255, 0.03);
    box-shadow: 0px 30px 73px 0px rgba(6, 9, 17, 0.15);

    .nav-list {
      width: 144px;
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      //border: 1px solid rgba(2, 181, 255, 0.40);
      background: linear-gradient(90deg, rgba(39, 124, 255, 0.00) 3.12%, rgba(39, 124, 255, 0.08) 99.7%);

      .nav-item {
        flex: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        &:active,
        &:hover {
          background: linear-gradient(270deg, rgba(39, 75, 145, 0.00) -1.95%, rgba(0, 148, 243, 0.40) 100.22%);
          box-shadow: 2px 0px 0px 0px #0094F3 inset;

          .name {
            background: linear-gradient(10deg, #1D98FC 3.22%, #FFF 76.57%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .name {
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        .icon {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    .details {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .dashboard-info {
        padding-top: 12px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 12px;
        height: 56px;
        display: flex;
        align-items: center;
        background: url('@{img_url}economy-dashboard-info_bg.png') no-repeat;
        background-size: 100% 100%;

        .icon {
          width: 36px;
          height: 36px;
          margin-right: 8px;
        }

        .line {
          width: 1px;
          height: 48px;
          margin: 0 12px;
          opacity: 0.4;
          background: linear-gradient(90deg, rgba(113, 184, 238, 0.00) 0%, #71B8EE 50%, rgba(113, 184, 238, 0.00) 100%);
        }

        .item-wrap {
          flex: 1;
          height: 48px;
          display: flex;
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;

          .name {
            color: #B9E0FF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }

          .value {
            //height: 22px;
            line-height: 22px;
            display: flex;
            gap: 2px;
            align-items: baseline;

            .num {
              color: #FFF;
              text-align: center;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
            }

            .unit {
              color: #E5F4FF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }

      .subtitle {
        display: flex;
        align-items: center;

        .dot {
          width: 4px;
          height: 4px;
          margin: 0 4px;
          background: #3DFFFF;
        }

        span {
          flex: 1;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          background: linear-gradient(0deg, #B1DDFF 20.83%, #EDF7FF 87.5%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .chats {
        width: 100%;
        flex: 1;
      }
    }
  }
}
</style>