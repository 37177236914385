<script>
import { computed, defineExpose, reactive, ref } from "vue"
import GlobalData from "./GlobalData"

export default {
  name: 'SOAMeetingOverviewDialog',
  setup() {
    const getFullYear = new Date().getFullYear()
    const time = ref(getFullYear)
    const showStep = ref('list')
    const dialogTitle = computed(() => {
      return showStep.value === 'list' ? '会议列表' : '会议详情'
    })
    const dialogVisible = ref(false)
    const searchListForm = reactive({
      keywords: '',
      date: ''
    })

    /**
     * 重置会议列表搜索参数
     */
    const resetForm = () => {
      searchListForm.keywords = ''
      searchListForm.date = ''
    }

    /**
     * 根据日期筛选会议
     * @param key
     */
    const handleDialogListDateChange = (key) => {
      console.log(key)
      searchListForm.date = key
    }

    const open = () => {
      resetForm()
      showStep.value = 'list'
      dialogVisible.value = true
    }

    const handleClickMeeting = () => {
      showStep.value = 'details'
      // todo 打开企业详情弹窗
    }

    /**
     * 返回会议列表
     */
    const backList = () => {
      showStep.value = 'list'
    }

    defineExpose({
      open
    })

    return {
      GlobalData,

      time,
      showStep,
      dialogTitle,
      dialogVisible,
      searchListForm,

      open,
      backList,
      handleDialogListDateChange,
      handleClickMeeting
    }
  }
}
</script>

<template>
  <div class="dialog__center soa-meeting-overview-dialog" :class="{open: dialogVisible}">
    <div class="header">
      <i class="dot"></i>
      <span class="title">{{ dialogTitle }}</span>
      <img v-if="showStep !== 'list'" @click="backList" :src="GlobalData.getImgUrl('back-dialog_icon.png')"
           class="back-icon" alt="">
      <img :src="GlobalData.getImgUrl('close-dialog_icon.png')" class="close-icon" @click="dialogVisible = false"
           alt="close">
    </div>

    <div class="content">
      <!--        会议列表-->
      <template v-if="showStep === 'list'">
        <div class="head">
          <div class="form-area">
            <input class="common-input" type="text" v-model="searchListForm.keywords" placeholder="请输入会议名称进行查找"/>
            <el-date-picker
                type="month"
                placeholder="请选择"
                v-model="time"
                value-format="YYYY-MM"
                :clearable="false"
                @change="handleDialogListDateChange"
            />
          </div>
        </div>
        <div class="main list">
          <ul class="head">
            <li class="th-item index">序号</li>
            <li class="th-item meeting-name">议题名称</li>
            <li class="th-item group-income">部门名称</li>
            <li class="th-item organizer">组织人</li>
            <li class="th-item number-of-participants">参会人数</li>
            <li class="th-item meeting-time">会议时间</li>
            <li class="th-item meeting-address">会议地点</li>
          </ul>
          <div class="table-wrap">
            <ul class="tables">
              <li class="tables-tr" @click="handleClickMeeting(i)" v-for="i in 20"
                  :key="i+'-data-dashboard-enterprise'">
                <div class="td-item index">{{ i }}</div>
                <div class="td-item meeting-name">关于“新青年·向未来2024</div>
                <div class="td-item group-income">这是部门名称</div>
                <div class="td-item organizer">阿卡曼</div>
                <div class="td-item number-of-participants">5人</div>
                <div class="th-item meeting-time">2024-11-06 08:33</div>
                <div class="th-item meeting-address">党委办公室</div>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <!-- 会议详情 -->
      <template v-if="showStep === 'details'">
        <div class="meeting-details">
          <h2 class="title">关于“新青年·向未来2024主题会议</h2>
          <ul class="tables">
            <li class="tr">
              <div class="td title">会议时间</div>
              <div class="td value">2022-10-10</div>
              <div class="td title">会议地点</div>
              <div class="td value">党委办公室</div>
            </li>
            <li class="tr">
              <div class="td title">组织人</div>
              <div class="td value">阿克曼</div>
              <div class="td title">参会人</div>
              <div class="td value">何桢 阿尔敏</div>
            </li>
            <li class="tr">
              <div class="td title">会议事项</div>
              <div class="td value">这是事项这是事项这是事项这是事项这是事项这是事项这是事项这是事项这是事项</div>
            </li>
            <li class="tr">
              <div class="td title">前置议题</div>
              <div class="td value">这是前置议题</div>
              <div class="td title">上会制度依据</div>
              <div class="td value">上会制度依据上会制度依据上会制度依据</div>
            </li>
            <li class="tr">
              <div class="td title">会议决议</div>
              <div class="td value">会议决议会议决议会议决议会议决议</div>
            </li>
            <li class="tr">
              <div class="td title">描述</div>
              <div class="td value">
                描述描述描述描述描述描述描述描述描
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.soa-meeting-overview-dialog {
  &.open {
    width: 1172px;
    height: auto;
    max-height: 665px;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }

  // 会议列表
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    .head {
      height: 48px;
      padding: 0 12px;
      display: flex;
      gap: 12px;
      align-items: center;
      background: rgba(50, 193, 255, 0.12);
    }

    .th-item,
    .td-item {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      list-style: none;

      &.index {
        width: 48px;
      }

      &.meeting-name {
        flex: 1;
        padding: 0 40px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
      }

      &.group-income,
      &.organizer,
      &.number-of-participants,
      &.meeting-address {
        width: 120px;
      }

      &.meeting-time {
        width: 160px;
      }
    }

    .th-item {
      font-size: 18px;

      &.enterprise-name {
        text-align: center;
      }
    }

    .table-wrap {
      flex: 1;
      overflow-y: auto; /* 允许内容垂直滚动 */
      min-height: 0; /* 确保在弹性布局中可伸缩 */
    }

    .tables {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .tables-tr {
        cursor: pointer;
        height: 48px;
        display: flex;
        gap: 12px;
        padding: 0 16px;
        align-items: center;
        list-style: none;
        border: 1px solid transparent;

        /* 偶数元素 */

        &:nth-child(even) {
          background: url('@{img_url}data-dashboard-dialog-tr_bg.png') no-repeat;
          background-size: 100% 100%;
        }

        /* 奇数元素 */

        &:nth-child(odd) {
          background: transparent;
        }

        &:hover {
          border-radius: 3px;
          border: 1px solid rgba(68, 139, 169, 0.50);
          background: rgba(10, 74, 118, 0.40);
          box-shadow: 0px 0px 21.8px 0px rgba(64, 199, 252, 0.48) inset;
        }

      }
    }
  }

  // 会议详情
  .meeting-details {
    height: auto;
    min-height: 312px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      color: #E5F4FF;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 100% */
    }

    .tables {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border: 1px solid #21588A;

      .tr {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 48px;
        border-bottom: 1px solid #21588A;

        &:last-child {
          border-bottom: 0;
        }

        .td {
          display: flex;
          padding: 12px 8px;
          align-items: center;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        .title {
          width: 148px;
          text-align: center;
          background: rgba(37, 166, 255, 0.12);
          border-right: 1px solid #21588A;

        }

        .value {
          flex: 1;
          text-align: left;
        }
      }
    }
  }
}
</style>