/**
 * @name: soa.api.service
 * @author: yuxi
 * @date: 2024-11-23 16:58
 * @description：国资监管API列表
 * @update: 2024-11-23 16:58
 */

import apiConfig from "./api.config"
import useIsProdEev from "./isProdEev.hooks"

/**
 * 获取企业全景数据
 */
export const getSOAEnterpriseDashboardData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/enterprise_panorama'

    if (isProdEev) {
        return window?.OU?.postData(url, {
                token: apiConfig.token
            }
        )
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 大额资金
 * @param year {string}
 * @returns {Promise<any>|*}
 */
export const getSOALargeAmountOfFundsData = (year) => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/finance_line'

    if (isProdEev) {
        return window?.OU?.postData(url, {
                token: apiConfig.token
            },
            {
                year
            }
        )
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            },
            body: JSON.stringify({year})
        }).then(res => res.json())
    }
}

/**
 * 投资监管
 * @param year {string}
 * @returns {Promise<any>|*}
 */
export const getSOAInvestmentSupervisionData = (year) => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/invest_line'

    if (isProdEev) {
        return window?.OU?.postData(url, {
                token: apiConfig.token
            },
            {
                year
            }
        )
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            },
            body: JSON.stringify({year})
        }).then(res => res.json())
    }
}

/**
 * 资产分类
 * @returns {Promise<any>|*}
 */
export const getSOAAssetClassificationData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/property_categorize'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 营业数据
 * @param year {string}
 * @returns {Promise<any>|*}
 */
export const getSOADataDashboardData = (year) => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/business_data'

    if (isProdEev) {
        return window?.OU?.postData(url, {
                token: apiConfig.token
            },
            {
                year
            }
        )
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            },
            body: JSON.stringify({year})
        }).then(res => res.json())
    }
}

/**
 * 收入、成本、利润趋势
 * @param year {string}
 * @returns {Promise<any>|*}
 */
export const getSOARevenueCostProfitData = (year) => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/income_cost_profit_line'

    if (isProdEev) {
        return window?.OU?.postData(url, {
                token: apiConfig.token
            },
            {
                year
            }
        )
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            },
            body: JSON.stringify({year})
        }).then(res => res.json())
    }
}

/**
 * 风险监测
 * @returns {Promise<any>|*}
 */
export const getSOARiskMonitoringData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/risk_monitor'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 三重一大会议总览
 * @returns {Promise<any>|*}
 */
export const getSOAMeetingOverviewData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/meeting_info'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 会议新增趋势情况
 * @returns {Promise<any>|*}
 */
export const getSOAMeetingTrendsData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/meeting_newly_line'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}

/**
 * 待上会议题
 * @returns {Promise<any>|*}
 */
export const getSOAWaitingForDiscussionData = () => {
    const isProdEev = useIsProdEev()
    const url = apiConfig.baseURL + '/api/smart_economy/wait_topics'

    if (isProdEev) {
        return window?.OU?.postData(url, {
            token: apiConfig.token
        })
    }
    else {
        return fetch(url, {
            method: 'POST',
            headers: {
                'token': apiConfig.token
            }
        }).then(res => res.json())
    }
}
